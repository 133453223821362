<template>
  <div class="d-flex flex-column align-center">
    <img src="../../assets/logo.png" :height="size" />
    <small v-if="showName">
      {{ $t("application.appname") }}
    </small>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: () => "",
    },
    showName: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>