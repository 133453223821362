/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var user_pb = require('./user_pb.js')
const proto = require('./organization_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.OrganizationsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.OrganizationsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.OrganizationInsertRequest,
 *   !proto.GenericOrganizationResponse>}
 */
const methodDescriptor_Organizations_CreateOrganization = new grpc.web.MethodDescriptor(
  '/Organizations/CreateOrganization',
  grpc.web.MethodType.UNARY,
  proto.OrganizationInsertRequest,
  proto.GenericOrganizationResponse,
  /**
   * @param {!proto.OrganizationInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.OrganizationInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.createOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/CreateOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_CreateOrganization,
      callback);
};


/**
 * @param {!proto.OrganizationInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.createOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/CreateOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_CreateOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.OrganizationUpdateRequest,
 *   !proto.GenericOrganizationResponse>}
 */
const methodDescriptor_Organizations_UpdateOrganization = new grpc.web.MethodDescriptor(
  '/Organizations/UpdateOrganization',
  grpc.web.MethodType.UNARY,
  proto.OrganizationUpdateRequest,
  proto.GenericOrganizationResponse,
  /**
   * @param {!proto.OrganizationUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.OrganizationUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.updateOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/UpdateOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_UpdateOrganization,
      callback);
};


/**
 * @param {!proto.OrganizationUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.updateOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/UpdateOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_UpdateOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Empty,
 *   !proto.ListOrganizationsResponse>}
 */
const methodDescriptor_Organizations_ListOrganizations = new grpc.web.MethodDescriptor(
  '/Organizations/ListOrganizations',
  grpc.web.MethodType.UNARY,
  proto.Empty,
  proto.ListOrganizationsResponse,
  /**
   * @param {!proto.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListOrganizationsResponse.deserializeBinary
);


/**
 * @param {!proto.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListOrganizationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListOrganizationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.listOrganizations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/ListOrganizations',
      request,
      metadata || {},
      methodDescriptor_Organizations_ListOrganizations,
      callback);
};


/**
 * @param {!proto.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListOrganizationsResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.listOrganizations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/ListOrganizations',
      request,
      metadata || {},
      methodDescriptor_Organizations_ListOrganizations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.OrganizationId,
 *   !proto.GenericOrganizationResponse>}
 */
const methodDescriptor_Organizations_GetOrganization = new grpc.web.MethodDescriptor(
  '/Organizations/GetOrganization',
  grpc.web.MethodType.UNARY,
  proto.OrganizationId,
  proto.GenericOrganizationResponse,
  /**
   * @param {!proto.OrganizationId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.OrganizationId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.getOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/GetOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_GetOrganization,
      callback);
};


/**
 * @param {!proto.OrganizationId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.getOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/GetOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_GetOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.OrganizationId,
 *   !proto.GenericOrganizationResponse>}
 */
const methodDescriptor_Organizations_DeleteOrganization = new grpc.web.MethodDescriptor(
  '/Organizations/DeleteOrganization',
  grpc.web.MethodType.UNARY,
  proto.OrganizationId,
  proto.GenericOrganizationResponse,
  /**
   * @param {!proto.OrganizationId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.OrganizationId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.deleteOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/DeleteOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_DeleteOrganization,
      callback);
};


/**
 * @param {!proto.OrganizationId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.deleteOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/DeleteOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_DeleteOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.InviteToOrganizationRequest,
 *   !proto.GenericOrganizationRoleResponse>}
 */
const methodDescriptor_Organizations_InviteToOrganization = new grpc.web.MethodDescriptor(
  '/Organizations/InviteToOrganization',
  grpc.web.MethodType.UNARY,
  proto.InviteToOrganizationRequest,
  proto.GenericOrganizationRoleResponse,
  /**
   * @param {!proto.InviteToOrganizationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationRoleResponse.deserializeBinary
);


/**
 * @param {!proto.InviteToOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.inviteToOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/InviteToOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_InviteToOrganization,
      callback);
};


/**
 * @param {!proto.InviteToOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationRoleResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.inviteToOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/InviteToOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_InviteToOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.OrganizationRoleId,
 *   !proto.GenericOrganizationResponse>}
 */
const methodDescriptor_Organizations_AcceptInvitationToOrganization = new grpc.web.MethodDescriptor(
  '/Organizations/AcceptInvitationToOrganization',
  grpc.web.MethodType.UNARY,
  proto.OrganizationRoleId,
  proto.GenericOrganizationResponse,
  /**
   * @param {!proto.OrganizationRoleId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.OrganizationRoleId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.acceptInvitationToOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/AcceptInvitationToOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_AcceptInvitationToOrganization,
      callback);
};


/**
 * @param {!proto.OrganizationRoleId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.acceptInvitationToOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/AcceptInvitationToOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_AcceptInvitationToOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RejectInvitationToOrganizationRequest,
 *   !proto.GenericOrganizationResponse>}
 */
const methodDescriptor_Organizations_RejectInvitationToOrganization = new grpc.web.MethodDescriptor(
  '/Organizations/RejectInvitationToOrganization',
  grpc.web.MethodType.UNARY,
  proto.RejectInvitationToOrganizationRequest,
  proto.GenericOrganizationResponse,
  /**
   * @param {!proto.RejectInvitationToOrganizationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.RejectInvitationToOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.rejectInvitationToOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/RejectInvitationToOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_RejectInvitationToOrganization,
      callback);
};


/**
 * @param {!proto.RejectInvitationToOrganizationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.rejectInvitationToOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/RejectInvitationToOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_RejectInvitationToOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.UpdateOrganizationRoleRequest,
 *   !proto.GenericOrganizationRoleResponse>}
 */
const methodDescriptor_Organizations_UpdateOrganizationRole = new grpc.web.MethodDescriptor(
  '/Organizations/UpdateOrganizationRole',
  grpc.web.MethodType.UNARY,
  proto.UpdateOrganizationRoleRequest,
  proto.GenericOrganizationRoleResponse,
  /**
   * @param {!proto.UpdateOrganizationRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationRoleResponse.deserializeBinary
);


/**
 * @param {!proto.UpdateOrganizationRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.updateOrganizationRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/UpdateOrganizationRole',
      request,
      metadata || {},
      methodDescriptor_Organizations_UpdateOrganizationRole,
      callback);
};


/**
 * @param {!proto.UpdateOrganizationRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationRoleResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.updateOrganizationRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/UpdateOrganizationRole',
      request,
      metadata || {},
      methodDescriptor_Organizations_UpdateOrganizationRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListOrganizationRoleRequest,
 *   !proto.ListOrganizationRolesResponse>}
 */
const methodDescriptor_Organizations_ListActiveOrganizationRoles = new grpc.web.MethodDescriptor(
  '/Organizations/ListActiveOrganizationRoles',
  grpc.web.MethodType.UNARY,
  proto.ListOrganizationRoleRequest,
  proto.ListOrganizationRolesResponse,
  /**
   * @param {!proto.ListOrganizationRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListOrganizationRolesResponse.deserializeBinary
);


/**
 * @param {!proto.ListOrganizationRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListOrganizationRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListOrganizationRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.listActiveOrganizationRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/ListActiveOrganizationRoles',
      request,
      metadata || {},
      methodDescriptor_Organizations_ListActiveOrganizationRoles,
      callback);
};


/**
 * @param {!proto.ListOrganizationRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListOrganizationRolesResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.listActiveOrganizationRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/ListActiveOrganizationRoles',
      request,
      metadata || {},
      methodDescriptor_Organizations_ListActiveOrganizationRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.OrganizationRoleId,
 *   !proto.GenericOrganizationRoleResponse>}
 */
const methodDescriptor_Organizations_GetOrganizationRole = new grpc.web.MethodDescriptor(
  '/Organizations/GetOrganizationRole',
  grpc.web.MethodType.UNARY,
  proto.OrganizationRoleId,
  proto.GenericOrganizationRoleResponse,
  /**
   * @param {!proto.OrganizationRoleId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationRoleResponse.deserializeBinary
);


/**
 * @param {!proto.OrganizationRoleId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.getOrganizationRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/GetOrganizationRole',
      request,
      metadata || {},
      methodDescriptor_Organizations_GetOrganizationRole,
      callback);
};


/**
 * @param {!proto.OrganizationRoleId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationRoleResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.getOrganizationRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/GetOrganizationRole',
      request,
      metadata || {},
      methodDescriptor_Organizations_GetOrganizationRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.OrganizationRoleId,
 *   !proto.GenericOrganizationRoleResponse>}
 */
const methodDescriptor_Organizations_RemoveFromOrganization = new grpc.web.MethodDescriptor(
  '/Organizations/RemoveFromOrganization',
  grpc.web.MethodType.UNARY,
  proto.OrganizationRoleId,
  proto.GenericOrganizationRoleResponse,
  /**
   * @param {!proto.OrganizationRoleId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationRoleResponse.deserializeBinary
);


/**
 * @param {!proto.OrganizationRoleId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.removeFromOrganization =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/RemoveFromOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_RemoveFromOrganization,
      callback);
};


/**
 * @param {!proto.OrganizationRoleId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationRoleResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.removeFromOrganization =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/RemoveFromOrganization',
      request,
      metadata || {},
      methodDescriptor_Organizations_RemoveFromOrganization);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ReplyToInvitationFromEmailRequest,
 *   !proto.GenericOrganizationRoleResponse>}
 */
const methodDescriptor_Organizations_AcceptInvitationFromEmail = new grpc.web.MethodDescriptor(
  '/Organizations/AcceptInvitationFromEmail',
  grpc.web.MethodType.UNARY,
  proto.ReplyToInvitationFromEmailRequest,
  proto.GenericOrganizationRoleResponse,
  /**
   * @param {!proto.ReplyToInvitationFromEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationRoleResponse.deserializeBinary
);


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.acceptInvitationFromEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/AcceptInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Organizations_AcceptInvitationFromEmail,
      callback);
};


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationRoleResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.acceptInvitationFromEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/AcceptInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Organizations_AcceptInvitationFromEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ReplyToInvitationFromEmailRequest,
 *   !proto.GenericOrganizationRoleResponse>}
 */
const methodDescriptor_Organizations_RejectInvitationFromEmail = new grpc.web.MethodDescriptor(
  '/Organizations/RejectInvitationFromEmail',
  grpc.web.MethodType.UNARY,
  proto.ReplyToInvitationFromEmailRequest,
  proto.GenericOrganizationRoleResponse,
  /**
   * @param {!proto.ReplyToInvitationFromEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericOrganizationRoleResponse.deserializeBinary
);


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericOrganizationRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericOrganizationRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OrganizationsClient.prototype.rejectInvitationFromEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Organizations/RejectInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Organizations_RejectInvitationFromEmail,
      callback);
};


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericOrganizationRoleResponse>}
 *     Promise that resolves to the response
 */
proto.OrganizationsPromiseClient.prototype.rejectInvitationFromEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Organizations/RejectInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Organizations_RejectInvitationFromEmail);
};


module.exports = proto;

