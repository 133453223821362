import isEmail from "validator/lib/isEmail";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isLoggedIn", "userInformation"]),

    isEmail() {
      return (email) => {
        if (email) return isEmail(email);
      };
    },

    checkStrength() {
      return (val) => {
        console.log(isStrongPassword(val));
        if (val) return isStrongPassword(val);
      };
    },
  },

  methods: {
    isEmailValid(email) {
      return isEmail(email);
    },

    isPasswordLengthValid(password) {
      return password.length >= 8 && password.length <= 100;
    },

    hasSpecialChar(str) {
      return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(str);
    },

    hasUpperLower(str) {
      return /(?=.*[a-z])(?=.*[A-Z])/.test(str);
    },

    hasOneDigit(str) {
      return /\d/.test(str);
    },

    isPasswordComplete(password) {
      if (!password) return false;

      if (this.hasSpecialChar(password) && this.hasUpperLower(password) && this.hasOneDigit(password) && this.isPasswordLengthValid(password)) return true;
      return false;
    },
  },
};
