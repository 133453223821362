<template>
  <div>
    <input
      type="checkbox"
      @change="$emit('change')"
      class="checkbox"
      id="checkbox"
    />
    <label
      for="checkbox"
      :class="[
        $vuetify.theme.isDark ? 'checkbox-label-dark' : 'checkbox-label-light',
        'checkbox-label',
      ]"
    >
      <v-icon class="moon" x-small>mdi-moon-waxing-crescent</v-icon>
      <v-icon class="sun" x-small>mdi-white-balance-sunny</v-icon>
      <span
        :class="[$vuetify.theme.isDark ? 'ball-dark' : 'ball-light', 'ball']"
      />
    </label>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  width: 45px;
  height: 17px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-label-dark {
  background: black;
}

.checkbox-label-light {
  border: 1px solid lightgray;
}

.moon {
  color: gray;
  padding-top: 1px;
}

.sun {
  color: #f39c12;
  padding-top: 1px;
}

.checkbox-label .ball {
  width: 18px;
  height: 12px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 10px;
  transition: transform 0.2s linear;
}

.ball-dark {
  background-color: #8f8f8f;
}

.ball-light {
  background-color: #8155ff;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(21px);
}
</style>