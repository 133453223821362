<template>
  <v-container fill-height>
    <v-layout>
      <v-row class="d-flex align-md-center justify-md-center">
        <v-col cols="12" md="8">
          <h2 class="secondaryText--text">{{ $t("login.welcomeBack") }}</h2>

          <small class="secondaryText--text">
            {{ $t("login.submessage") }}
          </small>

          <!-- <social-login /> -->

          <!-- <v-divider class="my-5" /> -->

          <login-form class="mt-4" />
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import SocialCard from "../../components/Login/SocialCard.vue";
import LoginForm from "../../components/Login/LoginForm.vue";

import LoginHeader from "@/components/Shared/LoginHeader.vue";
import TermsMessage from "@/components/Shared/TermsMessage.vue";
import MobileHeader from "@/components/Global/MobileHeader.vue";
import SocialLogin from '../../components/Shared/SocialLogin.vue';

export default {
  components: {
    SocialCard,
    LoginForm,
    LoginHeader,
    TermsMessage,
    MobileHeader,
    SocialLogin,
  },
  data: () => ({}),
  computed: {
    socialConfig() {
      return [
        {
          id: "facebook",
          name: this.$t("login.facebook"),
          icon: "mdi-facebook",
          color: "#3B5998",
          textColor: "#3B5998",
          borderColor: "#3B5998",
        },
        {
          id: "google",
          name: this.$t("login.google"),
          icon: "mdi-google",
          color: "#DB3236",
          textColor: "#DB3236",
          borderColor: "#DB3236",
        },
      ];
    },
  },

  methods: {
    signInWithGoogle() {
      this.$gAuth.signIn().then((resp) => {
        const userGoogleData = {
          ...resp.Cc,
          email: resp.gv.Tv,
          avatar: resp.gv.gO,
          firstName: resp.gv.gZ,
          lastName: resp.gv.tX,
          fullName: resp.gv.zf,
        };

        this.$store.dispatch("login/authenticateUsingGoogle", userGoogleData);
      });
    },
  },
};
</script>

<style scoped>
.login-main {
  height: 100%;
  background: url("../../assets/undraw_real_time_analytics_re_yliv 1.svg");
  background-size: 650px;
  background-repeat: no-repeat;
  background-origin: content-box;
}

.login-ltr {
  background-position: right center;
}

.login-rtl {
  background-position: left center;
}
</style>