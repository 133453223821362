import { getField, updateField } from "vuex-map-fields";
import organizationService from "@/services/organizationService";
import { vm } from "@/main";
import { getData } from "@/services/localService";

export default {
 namespaced: true,
 state: () => ({
  selectedOrganizationForDelete: null,
  selectedOrganizationForEdit: null,
  createdSuccesfully: false,
  createDialog: false,
  isOrganizationEditing: false,
  allOrganisations: [],
  organisationName: "",
  currentOrganizationDetails: [],

  /* Invite user dialog state */
  addMemberDialog: false,
  inviteUserEmail: "",
  selectedRole: "member",

  /* Members Table */
  allMembers: [],

  /* Organization Dropdown */
  selectedOrganization: null,

  /* Onboarding */
  onboardingOrganizationDialog: false,
 }),
 mutations: {
  updateField,
  setState(state, payload) {
   state[payload.key] = payload.value;
  },
 },
 actions: {
  /* An action which will fetch all the organisations from the server */
  async getOrganizations({ state, commit, dispatch }) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.getOrganizations();

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    /* This will set the allOrganizations coming from the backend into the store array */
    commit("setState", { key: "allOrganisations", value: response.organizationsList });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  /* An action which will create an organisation*/
  async createOrganization({ state, commit, dispatch }) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.createOrganization({ name: state.organisationName });

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    /* This will insert the saved oraganisation into the store array because it is returned in the response of the request */
    commit("setState", { key: "allOrganisations", value: [...state.allOrganisations, response.organization] });

    /* This will reset the entered organisation name */
    commit("setState", { key: "organisationName", value: "" });

    /* This will close the organisation create dialog */
    commit("setState", { key: "createDialog", value: false });

    const isNewUser = getData("new-user");

    /* 
          This is will open organisation created dialog when the 
          logged in user is no longer considered as a new user 
        */

    if (!isNewUser) {
     commit("setState", { key: "createdSuccesfully", value: true });
    }

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async updateOrganization({ state, commit, dispatch }) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.updateOrganization({
     name: state.organisationName,
     entity_id: state.selectedOrganizationForEdit.entityId,
    });

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    let updatedOrganisations = state.allOrganisations;

    updatedOrganisations[state.selectedOrganizationForEdit.index] = response.organization;

    commit("setState", { key: "organisationName", value: "" });
    commit("setState", { key: "allOrganisations", value: [...updatedOrganisations] });
    commit("setState", { key: "createDialog", value: false });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async deleteOrganization({ state, commit, dispatch }) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.deleteOrganization({
     entity_id: state.selectedOrganizationForDelete.entityId,
    });

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    const updatedOrganisations = state.allOrganisations;

    updatedOrganisations.splice(state.selectedOrganizationForDelete.index, 1);

    commit("setState", { key: "allOrganisations", value: [...updatedOrganisations] });
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async getOrganizationDetails({ state, commit, dispatch }, organization_id) {
   try {
    console.log(organization_id);

    commit("startLoading", null, { root: true });

    const response = await organizationService.getOrganizationDetails({
     entity_id: organization_id,
    });
    console.log(response);
    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("setState", { key: "currentOrganizationDetails", value: response.organization });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  /* INVITE A MEMBER TO THE ORGANIZATION */

  async inviteMember({ state, commit, dispatch }, payload) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.inviteMember(payload);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    commit("setState", { key: "addMemberDialog", value: false });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  /* ACCEPT THE INVITATION THAT CAME FROM EMAIL */

  async acceptEmailInvitation({ state, commit, dispatch }, payload) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.acceptInvitationFromEmail(payload);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });
   } catch (e) {
    console.log(e);
   } finally {
    vm.$router.push({ path: `/login` });
    commit("stopLoading", null, { root: true });
   }
  },

  /* REJECT THE INVITATION THAT CAME FROM EMAIL */

  async rejectEmailInvitation({ state, commit, dispatch }, payload) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.rejectInvitationFromEmail(payload);

    if (!response.success) {
     dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
     return response;
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    return response;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async listOrganizationRoles({ state, commit, dispatch }, org_id) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.listOrganizationRoles({
     organization_id: org_id,
    });

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("setState", { key: "allMembers", value: response.organizationRolesList });
    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  /* THIS ACTION IS USED TO REMOVE A MEMBER FROM THE ORGANIZATION */
  async removeMember({ state, commit, dispatch }, payload) {
   try {
    console.log(payload);

    commit("startLoading", null, { root: true });
    const response = await organizationService.removeMember(payload);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    const updatedMembers = state.allMembers.filter((member) => member.entityId !== payload.entityId);

    commit("setState", { key: "allMembers", value: updatedMembers });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async updateUserRole({ state, commit, dispatch }, payload) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.updateUserRole(payload);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async acceptInternalInvite({ state, commit, dispatch }, payload) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.acceptInternalInvitation(payload);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async rejectInternalInvite({ state, commit, dispatch }, payload) {
   try {
    commit("startLoading", null, { root: true });

    const response = await organizationService.rejectInternalInvitation(payload);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },
 },
 getters: {
  getField,
  allOrganisations: (state) => state.allOrganisations,
 },
};
