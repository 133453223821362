/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = require('./attachment_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.AttachmentsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.AttachmentsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectAttachmentsCreateRequest,
 *   !proto.ListAttachmentsResponse>}
 */
const methodDescriptor_Attachments_AddAttachmentsToProject = new grpc.web.MethodDescriptor(
  '/Attachments/AddAttachmentsToProject',
  grpc.web.MethodType.UNARY,
  proto.ProjectAttachmentsCreateRequest,
  proto.ListAttachmentsResponse,
  /**
   * @param {!proto.ProjectAttachmentsCreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListAttachmentsResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectAttachmentsCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListAttachmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListAttachmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AttachmentsClient.prototype.addAttachmentsToProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Attachments/AddAttachmentsToProject',
      request,
      metadata || {},
      methodDescriptor_Attachments_AddAttachmentsToProject,
      callback);
};


/**
 * @param {!proto.ProjectAttachmentsCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListAttachmentsResponse>}
 *     Promise that resolves to the response
 */
proto.AttachmentsPromiseClient.prototype.addAttachmentsToProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Attachments/AddAttachmentsToProject',
      request,
      metadata || {},
      methodDescriptor_Attachments_AddAttachmentsToProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListProjectAttachmentsRequest,
 *   !proto.ListAttachmentsResponse>}
 */
const methodDescriptor_Attachments_ListProjectAttachments = new grpc.web.MethodDescriptor(
  '/Attachments/ListProjectAttachments',
  grpc.web.MethodType.UNARY,
  proto.ListProjectAttachmentsRequest,
  proto.ListAttachmentsResponse,
  /**
   * @param {!proto.ListProjectAttachmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListAttachmentsResponse.deserializeBinary
);


/**
 * @param {!proto.ListProjectAttachmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListAttachmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListAttachmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AttachmentsClient.prototype.listProjectAttachments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Attachments/ListProjectAttachments',
      request,
      metadata || {},
      methodDescriptor_Attachments_ListProjectAttachments,
      callback);
};


/**
 * @param {!proto.ListProjectAttachmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListAttachmentsResponse>}
 *     Promise that resolves to the response
 */
proto.AttachmentsPromiseClient.prototype.listProjectAttachments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Attachments/ListProjectAttachments',
      request,
      metadata || {},
      methodDescriptor_Attachments_ListProjectAttachments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectAttachmentId,
 *   !proto.GenericAttachmentResponse>}
 */
const methodDescriptor_Attachments_GetProjectAttachment = new grpc.web.MethodDescriptor(
  '/Attachments/GetProjectAttachment',
  grpc.web.MethodType.UNARY,
  proto.ProjectAttachmentId,
  proto.GenericAttachmentResponse,
  /**
   * @param {!proto.ProjectAttachmentId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericAttachmentResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectAttachmentId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericAttachmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericAttachmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AttachmentsClient.prototype.getProjectAttachment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Attachments/GetProjectAttachment',
      request,
      metadata || {},
      methodDescriptor_Attachments_GetProjectAttachment,
      callback);
};


/**
 * @param {!proto.ProjectAttachmentId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericAttachmentResponse>}
 *     Promise that resolves to the response
 */
proto.AttachmentsPromiseClient.prototype.getProjectAttachment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Attachments/GetProjectAttachment',
      request,
      metadata || {},
      methodDescriptor_Attachments_GetProjectAttachment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectAttachmentId,
 *   !proto.GenericAttachmentResponse>}
 */
const methodDescriptor_Attachments_DeleteProjectAttachment = new grpc.web.MethodDescriptor(
  '/Attachments/DeleteProjectAttachment',
  grpc.web.MethodType.UNARY,
  proto.ProjectAttachmentId,
  proto.GenericAttachmentResponse,
  /**
   * @param {!proto.ProjectAttachmentId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericAttachmentResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectAttachmentId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericAttachmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericAttachmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AttachmentsClient.prototype.deleteProjectAttachment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Attachments/DeleteProjectAttachment',
      request,
      metadata || {},
      methodDescriptor_Attachments_DeleteProjectAttachment,
      callback);
};


/**
 * @param {!proto.ProjectAttachmentId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericAttachmentResponse>}
 *     Promise that resolves to the response
 */
proto.AttachmentsPromiseClient.prototype.deleteProjectAttachment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Attachments/DeleteProjectAttachment',
      request,
      metadata || {},
      methodDescriptor_Attachments_DeleteProjectAttachment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskAttachmentsCreateRequest,
 *   !proto.ListAttachmentsResponse>}
 */
const methodDescriptor_Attachments_AddAttachmentsToTask = new grpc.web.MethodDescriptor(
  '/Attachments/AddAttachmentsToTask',
  grpc.web.MethodType.UNARY,
  proto.TaskAttachmentsCreateRequest,
  proto.ListAttachmentsResponse,
  /**
   * @param {!proto.TaskAttachmentsCreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListAttachmentsResponse.deserializeBinary
);


/**
 * @param {!proto.TaskAttachmentsCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListAttachmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListAttachmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AttachmentsClient.prototype.addAttachmentsToTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Attachments/AddAttachmentsToTask',
      request,
      metadata || {},
      methodDescriptor_Attachments_AddAttachmentsToTask,
      callback);
};


/**
 * @param {!proto.TaskAttachmentsCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListAttachmentsResponse>}
 *     Promise that resolves to the response
 */
proto.AttachmentsPromiseClient.prototype.addAttachmentsToTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Attachments/AddAttachmentsToTask',
      request,
      metadata || {},
      methodDescriptor_Attachments_AddAttachmentsToTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListTaskAttachmentsRequest,
 *   !proto.ListAttachmentsResponse>}
 */
const methodDescriptor_Attachments_ListTaskAttachments = new grpc.web.MethodDescriptor(
  '/Attachments/ListTaskAttachments',
  grpc.web.MethodType.UNARY,
  proto.ListTaskAttachmentsRequest,
  proto.ListAttachmentsResponse,
  /**
   * @param {!proto.ListTaskAttachmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListAttachmentsResponse.deserializeBinary
);


/**
 * @param {!proto.ListTaskAttachmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListAttachmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListAttachmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AttachmentsClient.prototype.listTaskAttachments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Attachments/ListTaskAttachments',
      request,
      metadata || {},
      methodDescriptor_Attachments_ListTaskAttachments,
      callback);
};


/**
 * @param {!proto.ListTaskAttachmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListAttachmentsResponse>}
 *     Promise that resolves to the response
 */
proto.AttachmentsPromiseClient.prototype.listTaskAttachments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Attachments/ListTaskAttachments',
      request,
      metadata || {},
      methodDescriptor_Attachments_ListTaskAttachments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskAttachmentId,
 *   !proto.GenericAttachmentResponse>}
 */
const methodDescriptor_Attachments_GetTaskAttachment = new grpc.web.MethodDescriptor(
  '/Attachments/GetTaskAttachment',
  grpc.web.MethodType.UNARY,
  proto.TaskAttachmentId,
  proto.GenericAttachmentResponse,
  /**
   * @param {!proto.TaskAttachmentId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericAttachmentResponse.deserializeBinary
);


/**
 * @param {!proto.TaskAttachmentId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericAttachmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericAttachmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AttachmentsClient.prototype.getTaskAttachment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Attachments/GetTaskAttachment',
      request,
      metadata || {},
      methodDescriptor_Attachments_GetTaskAttachment,
      callback);
};


/**
 * @param {!proto.TaskAttachmentId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericAttachmentResponse>}
 *     Promise that resolves to the response
 */
proto.AttachmentsPromiseClient.prototype.getTaskAttachment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Attachments/GetTaskAttachment',
      request,
      metadata || {},
      methodDescriptor_Attachments_GetTaskAttachment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskAttachmentId,
 *   !proto.GenericAttachmentResponse>}
 */
const methodDescriptor_Attachments_DeleteTaskAttachment = new grpc.web.MethodDescriptor(
  '/Attachments/DeleteTaskAttachment',
  grpc.web.MethodType.UNARY,
  proto.TaskAttachmentId,
  proto.GenericAttachmentResponse,
  /**
   * @param {!proto.TaskAttachmentId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericAttachmentResponse.deserializeBinary
);


/**
 * @param {!proto.TaskAttachmentId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericAttachmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericAttachmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.AttachmentsClient.prototype.deleteTaskAttachment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Attachments/DeleteTaskAttachment',
      request,
      metadata || {},
      methodDescriptor_Attachments_DeleteTaskAttachment,
      callback);
};


/**
 * @param {!proto.TaskAttachmentId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericAttachmentResponse>}
 *     Promise that resolves to the response
 */
proto.AttachmentsPromiseClient.prototype.deleteTaskAttachment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Attachments/DeleteTaskAttachment',
      request,
      metadata || {},
      methodDescriptor_Attachments_DeleteTaskAttachment);
};


module.exports = proto;

