<template>
  <v-tooltip right color="primary">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" @click="$emit('show-password')" color="primary" class="cursor-pointer">
        {{ passwordType === "text" ? "mdi-eye-off-outline" : "mdi-eye-outline" }}
      </v-icon>
    </template>
    <span class="font-color-primary">
      {{ passwordType === "text" ? $t("common.hidePassword") : $t("common.showPassword") }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
 props: {
  passwordType: {
   type: String,
  }
 }
}
</script>
