/* 
  This function will be used to save 
  the data into local storage. 
*/
export const saveData = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
}

/* 
  This function will be used to get 
  the data from local storage. 
*/
export const getData = (key) => {
  return JSON.parse(localStorage.getItem(key));
}

/* 
  This function will be used to remove 
  the data from local storage. 
*/
export const removeData = (key) => {
  localStorage.removeItem(key)
}

/* 
  This function will be used to 
  clear all the data from local storage 
*/
export const clearAll = () => {
  localStorage.clear()
}
