import TasksService from "@/services/taskService";
import { getField, updateField } from "vuex-map-fields";
import { vm } from "@/main";

export default {
 namespaced: true,
 state: () => ({
  /* General task state */
  projectTasks: [],

  /* Create task form dialog state */
  createTaskDialog: false,
  taskTitle: "",
  taskDescription: "",
  assignTo: { userEmail: "Unassigned" },
  estimatedHours: "",
  taskDueDate: new Date().toISOString().slice(0, 10),
  taskNumber: Math.floor(1000 + Math.random() * 9000).toString(),
  chosenMonth: "",
  recurringChoice: {
   type: null,
   every_n_days: 2,
   every_week_on: "",
   every_month_on: null,
   every_year_on: null,
  },
  uploadedTaskFiles: null,

  /* Kanban Board */
  kanbanLoading: false,
  backlog: [],
  inProgress: [],
  inReview: [],
  done: [],

  /* TASK DETAILS PAGE */
  taskDetailsLoading: false,
  chosenTaskDetails: {},
  estimateEditDialog: false,
  estimateType: null,
  taskEstimates: [],
  selectedEstimate: null,
  taskRecurrenceDetails: null,

  /* Delete task */
  selectedTaskForDelete: null,
  deleteTaskDialog: false,

  /* My tasks page */
  myTasksLoading: false,
  myTasks: [],
  workScheduleDialog: false,

  /* Archived tasks page */
  archiveConfirmationDialog: false,
  archivedTasksLoading: false,
  archivedTasks: [],
 }),

 mutations: {
  updateField,
  setState(state, payload) {
   state[payload.key] = payload.value;
  },
  resetForm(state) {
   state.taskTitle = "";
   state.taskDescription = "";
   state.assignTo = { userEmail: "Unassigned" };
   state.estimatedHours = "";
   state.taskDueDate = new Date().toISOString().slice(0, 10);
   state.taskNumber = Math.floor(1000 + Math.random() * 9000).toString();
   state.recurringChoice = {
    type: null,
    every_n_days: 2,
    every_week_on: "",
    every_month_on: null,
    every_year_on: null,
   };
   state.uploadedTaskFiles = null;
  },
  resetKanban(state) {
   state.backlog = [];
   state.inProgress = [];
   state.inReview = [];
   state.done = [];
  },
 },

 actions: {
  async getTasks({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.getTasks(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("setState", { key: "projectTasks", value: response.tasksList });

    return response.tasksList;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async createTask({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    let task = {
     title: state.taskTitle,
     description: state.taskDescription,
     dueDate: state.taskDueDate,
     assignedTo: data.assignedTo,
     projectId: data.projectId,
     taskNumber: state.taskNumber,
     estimate: state.estimatedHours,
     recurrence: data.recurrence,
     attachments: state.uploadedTaskFiles,
     predecessors: data.predecessors,
     successors: data.successors,
    };

    const response = await TasksService.createTask(task);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("setState", { key: "projectTasks", value: [...state.projectTasks, response.task] });

    return response.task;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async updateTask({ state, commit, dispatch }, data) {
   try {
    const response = await TasksService.updateTask(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    return true;
   } catch (e) {
    console.log(e);
   }
  },

  async updateTaskStatus({ state, commit, dispatch }, data) {
   try {
    const response = await TasksService.updateTaskStatus(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    return true;
   } catch (e) {
    console.log(e);
   }
  },

  async getTaskDetails({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.getTaskDetails(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("setState", { key: "chosenTaskDetails", value: response.task });
    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async deleteTask({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.deleteTask(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async getAllTasksForUser({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.getAllTasksForUser(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("setState", { key: "myTasks", value: response.tasksList });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async getTaskEstimates({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.getTaskEstimates(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("setState", { key: "taskEstimates", value: response.estimatesList });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async addEstimateToTask({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.addEstimateToTask(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    let updatedTaskEstimates = state.taskEstimates;

    if (state.taskEstimates.length === 0) {
     updatedTaskEstimates.push(response.estimate);
    } else {
     const indexOfEstimate = state.taskEstimates.findIndex((el) => el.estimateType === response.estimate.estimateType);

     if (indexOfEstimate !== -1) {
      updatedTaskEstimates[indexOfEstimate] = response.estimate;
     } else {
      updatedTaskEstimates.push(response.estimate);
     }
    }

    commit("setState", { key: "taskEstimates", value: [...updatedTaskEstimates] });
    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  /* INVITATION ENDPOINTS */

  async rejectTaskInvitationFromEmail({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.rejectTaskInvitationFromEmail(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    vm.$router.push({ path: `/login` });
    commit("stopLoading", null, { root: true });
   }
  },

  async acceptTaskInvitationFromEmail({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.acceptTaskInvitationFromEmail(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });
   } catch (e) {
    console.log(e);
   } finally {
    vm.$router.push({ path: `/login` });
    commit("stopLoading", null, { root: true });
   }
  },

  async acceptTaskInvitationFromNotification({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.acceptTaskInvitationFromNotification(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    vm.$router.push({ path: `/home/project/details/${data.projectId}` });
   } catch (e) {
    console.log(e);
   } finally {
    vm.$router.push({ path: `/login` });
    commit("stopLoading", null, { root: true });
   }
  },

  async rejectTaskInvitationFromNotification({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.rejectTaskInvitationFromNotification(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });
   } catch (e) {
    console.log(e);
   } finally {
    vm.$router.push({ path: `/login` });
    commit("stopLoading", null, { root: true });
   }
  },

  /* INVITATION ENDPOINTS */

  async getRecurrenceOfTask({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.getRecurrenceInTask(data);

    if (!response.success) {
     return commit("setState", { key: "taskRecurrenceDetails", value: null });
    }

    commit("setState", { key: "taskRecurrenceDetails", value: response.recurrence });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async getPriorityList({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.getPriorityList(data);

    if (!response.success) return;

    commit("allTasks/SET_PRIORITY_DATA", response.taskPriorityList, { root: true });

    return response.taskPriorityList;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async createPriorityList({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.createPriorityList(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    return response;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async updatePriorityList({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.updatePriorityList(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    return response;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async deletePriorityList({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.deletePriorityForManager(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async deleteTaskFromPriority({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.deleteTaskFromPriority(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    return response;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async archiveTask({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.archiveTask(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });
    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async unArchiveTask({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.unArchiveTask(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });
    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async listArchiveTasks({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await TasksService.listArchiveTasks(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("setState", { key: "archivedTasks", value: response.tasksList });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },
 },
 getters: {
  getField,
  projectTasks: (state) => state.projectTasks,
 },
};
