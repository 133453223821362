<template>
  <v-row class="mt-2">
    <v-col>
      <div class="font-small secondaryText--text text-center text-md-left">
        {{ $t("login.registermssg") }}
        <router-link to="/terms" class="no-underline">
          {{ $t("login.terms") }}
        </router-link>
        {{ $t("common.and") }}

        <router-link to="/privacypolicy" class="no-underline">
          {{ $t("login.privacy") }}
        </router-link>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>