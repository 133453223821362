import AuthService from '@/services/authService';
import { getField, updateField } from 'vuex-map-fields';
import { vm } from '@/main';

export default {
  namespaced: true,
  state: () => ({
    email: '',
    resendEmail: false
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    }
  },
  actions: { 
    async reset({ state, commit, dispatch }) {
      try {
        commit('startLoading', null, { root: true });
        let response;
        if (state.resendEmail) {
          /* RESEND VERIFICATION EMAIL */
          response = await AuthService.resendEmailVerfication({ email: state.email });
        } else {
          /* RESET PASSWORD YET TO BE DONE */
          response = await AuthService.forgotUserPassword({ email: state.email })
        }

        if (response) {
          if (!response.success) {
            return dispatch('showMessage', { message: response.message, messageType: 'error', messageTitle: vm.$t('errors.error') }, { root: true });
          }

          dispatch('showMessage', { message: response.message, messageType: 'success', messageTitle: vm.$t('success.success') }, { root: true });

          commit('setState', { key: 'email', value: '' });
          commit('setState', { key: 'resendEmail', value: false });
          
          return true;
        }

      } catch (e) {
        console.log(e)
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },
    async changePassword({ state, commit, dispatch }, payload) {
      try {
        commit('startLoading', null, { root: true });
        const response = await AuthService.resetUserPassword(payload);
        if (!response.success) {
          return dispatch('showMessage', { message: response.message, messageType: 'error', messageTitle: vm.$t('errors.error') }, { root: true });
        }
        dispatch('showMessage', { message: response.message, messageType: 'success', messageTitle: vm.$t('success.success') }, { root: true });
        commit('setState', { key: 'email', value: '' });
        vm.$router.push({ path: '/login' });  
      } catch (e) {
        console.log(e)
      } finally {
        commit('stopLoading', null, { root: true });
      }
    }
  },
  getters: {
    getField,
  }
}