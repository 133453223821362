<template>
  <v-app-bar elevate-on-scroll app color="backgroundPrimary">
    <v-app-bar-nav-icon @click="toggleMenu" v-if="isUserloggedIn" />

    <v-navigation-drawer color="backgroundSecondary" v-model="mobileMenu" app absolute width="190" class="px-4">
      <template #prepend>
        <user-avatar class="py-4" :compact="false" :text="userInitials" :fullName="userFullName" />
      </template>

      <router-links />

      <template #append>
        <div class="pa-2 text-center">
          <language-toggler />
        </div>
      </template>
    </v-navigation-drawer>

    <v-btn v-if="createButtonConfig.show && isUserloggedIn" color="primary" x-small depressed class="py-4 text-capitalize" @click="handleClick">
      {{ createButtonConfig.text }}
    </v-btn>

    <v-spacer />

    <div class="font-weight-bold">
      <span>{{ $t("application.fractal") }}</span>
      <span class="primary--text">{{ $t("application.pm") }}</span>
    </div>
  </v-app-bar>
</template>

<script>
import { mapFields } from "vuex-map-fields";

import AppLogo from "../Shared/AppLogo.vue";
import HeaderMixin from "@/Mixins/HeaderMixin";
import Sidebar from "./Sidebar.vue";
import RouterLinks from "./RouterLinks.vue";
import UserAvatar from "./UserAvatar.vue";
import LanguageToggler from "../Shared/LanguageToggler.vue";

export default {
  mixins: [HeaderMixin],

  components: { AppLogo, Sidebar, RouterLinks, UserAvatar, LanguageToggler },

  data: () => ({}),

  computed: {
    ...mapFields({
      mobileMenu: "mobileMenu",
      isUserloggedIn: "isUserloggedIn",
    }),

    ...mapFields("organisations", {
      createDialog: "createDialog",
      allOrganisations: "allOrganisations",
    }),

    ...mapFields("tasks", {
      createTaskDialog: "createTaskDialog",
      chosenTaskDetails: "chosenTaskDetails",
      myTasks: "myTasks",
    }),

    userInformation() {
      return this.$store.getters.userInformation;
    },

    userInitials() {
      const initials = this.userInformation.first_name[0] + this.userInformation.last_name[0];
      if (initials) {
        return initials.toUpperCase();
      }
      return "XX";
    },

    userFullName() {
      const fullName = this.userInformation.first_name + " " + this.userInformation.last_name;
      return fullName;
    },
  },

  methods: {
    toggleMenu() {
      this.$store.commit("setState", { key: "mobileMenu", value: !this.mobileMenu });
    },
  },
};
</script>
