<template>
  <v-form v-model="loginForm" lazy-validation ref="loginform">
    <small class="font-small secondaryText--text">
      {{ $t("placeholders.email") }}
    </small>

    <v-text-field
      v-model="username"
      :placeholder="$t('placeholders.email')"
      required
      outlined
      validate-on-blur
      type="text"
      :rules="[(val) => !!val || `${$t('placeholders.email')} ${$t('common.required')}`, (val) => isEmail(val) || $t('common.invalidEmail')]"
      @keydown.prevent.enter="login"
      background-color="primaryInput"
    />

    <small class="font-small secondaryText--text">
      {{ $t("placeholders.password") }}
    </small>

    <v-text-field
      v-model="password"
      :placeholder="$t('placeholders.password')"
      outlined
      required
      validate-on-blur
      :type="passwordType"
      :rules="[(val) => !!val || `${$t('placeholders.password')} ${$t('common.required')}`]"
      @keydown.prevent.enter="login"
      background-color="primaryInput"
    >
      <template #append>
        <eye-icon @show-password="showPassword" :passwordType="passwordType" />
      </template>
    </v-text-field>

    <div :class="['d-flex', $vuetify.breakpoint.mdAndUp ? 'justify-space-between' : 'justify-end']">
      <small class="primary-color cursor-pointer" v-if="$vuetify.breakpoint.mdAndUp">
        <router-link to="/signup" class="no-underline">
          {{ $t("login.signup") }}
        </router-link>
      </small>

      <small class="primary-color cursor-pointer">
        <router-link to="/forgotpassword" class="no-underline">
          {{ $t("login.forgot") }}
        </router-link>
      </small>
    </div>

    <v-btn block depressed large class="mt-5 text-capitalize primaryText--text rounded-lg" color="primary" :loading="loading" @click="login">
      {{ $t("login.login") }}
    </v-btn>

    <router-link to="/signup" class="no-underline" v-if="$vuetify.breakpoint.smAndDown">
      <v-btn block depressed large outlined class="mt-5 white--text text-capitalize rounded-lg" color="primary">
        {{ $t("login.signup") }}
      </v-btn>
    </router-link>
  </v-form>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import ValidatorMixin from "@/Mixins/Validators.js";
import EyeIcon from '../Shared/Login/EyeIcon.vue';

export default {
  components: { EyeIcon },
  mixins: [ValidatorMixin],

  data: () => ({
    loginForm: false,
    passwordType: "password",
  }),

  computed: {
    ...mapFields("login", {
      username: "username",
      password: "password",
    }),

    loading() {
      return this.$store.getters.loading;
    },
  },

  methods: {
    login() {
      if (this.$refs.loginform.validate()) {
        this.$store.dispatch("login/login");
      }
    },

    showPassword() {
      this.passwordType = this.passwordType === "password" ? "text" : "password";
    },
  },
};
</script>