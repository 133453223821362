<template>
  <v-row class="mt-3">
    <v-col cols="12" md="6">
      <v-btn color="#fff" block class="px-1 text-capitalize rounded-lg">
        <v-icon small color="#DB3236">mdi-google</v-icon>
        <span class="black--text font-small pl-1">
          {{ $t("login.loginWithGoogle") }}
        </span>
      </v-btn>
    </v-col>

    <v-col cols="12" md="6">
      <v-btn color="#1877F2" block class="px-1 text-capitalize rounded-lg">
        <v-icon small>mdi-facebook</v-icon>
        <span class="font-small pl-1">
          {{ $t("login.loginWithFacebook") }}
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {

}
</script>