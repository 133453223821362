import TaskService from "@/services/taskService";
import { getField, updateField } from "vuex-map-fields";
import { vm } from "@/main";

export default {
  namespaced: true,

  state: () => ({
    fetchingTasks: false,
    allTasks: [],
    allProjects: {},
    activeProjects: [],
    projectNameMap: {},
    priorityData: null,
    priorityList: null,
    projectPriorityList: [],
    currentNodeInfo: null,
  }),

  mutations: {
    updateField,
    SET_ALL_TASKS(state, payload) {
      state.allTasks = payload;
    },

    SET_PRIORITY_DATA(state, payload) {
      state.priorityData = payload;
    },

    SET_PROJECT_PRIORITY_LIST(state, payload) {
      state.projectPriorityList = payload;
    },
  },

  actions: {
    async getAllTasksInBacklog({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });

        const response = await TaskService.getAllTasksForManager(payload);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        commit("SET_ALL_TASKS", response.tasksList);

        return response.tasksList;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async addDependencies({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });

        const response = await TaskService.updateTask(payload);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        return response.task;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async changeProject({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });

        const response = await TaskService.changeProject(payload);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        return response.task;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async createProjectPriorityList({ commit, dispatch }, payload) {
      try {
        commit("startLoading", null, { root: true });

        const response = await TaskService.createProjectPriorityList(payload);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        return response;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getProjectPriorityList({ commit, dispatch }, data) {
      try {
        commit("startLoading", null, { root: true });

        const response = await TaskService.getProjectPriorityList(data);

        if (!response.success) {
          commit("SET_PROJECT_PRIORITY_LIST", []);
          return;
        }

        commit("SET_PROJECT_PRIORITY_LIST", response.projectTaskSlotList.slotsList);
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
  },

  getters: {
    getField,
    allTasks: (state) => state.allTasks,
  },
};
