import TasksService from "@/services/taskService";
import { vm } from "@/main";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,
  state: () => ({
    currentTaskComments: [],
  }),
  getters: {
    getField
  },
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
  },
  actions: {
    async getTaskComments({ commit, dispatch }, data) {
      return TasksService
        .getTaskComments({taskId: data.taskId})
        .then(response => {
          if (!response.success) {
            return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
          }
    
          commit("setState", { key: "currentTaskComments", value: response.taskCommentsList });
        })
    },
    createTaskComment ({ state }, data) {
      if (!data.text) {
        return
      }

      return TasksService
        .createTaskComment({
          text: data.text,
          taskId: data.taskId,
          usersMentioned: data.usersMentioned,
        })
        .then((response) => {
          state.currentTaskComments.unshift(response.taskComment)
        })
    },
    updateComment({ dispatch }, data) {
      return TasksService.updateTaskComment({
        commentId: data.commentId,
        text: data.text,
      })
        .then((response) => {
          if (!response.success) {
            return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
          }

          dispatch('showMessage', { message: response.message, messageType: 'success', messageTitle: vm.$t('success.success') }, { root: true });
        })
    },
    deleteComment({ dispatch }, data) {
      return TasksService.deleteTaskComment({commentId: data.commentId})
        .then((response) => {
          if (!response.success) {
            return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
          }

          dispatch('showMessage', { message: response.message, messageType: 'success', messageTitle: vm.$t('success.success') }, { root: true });
        })
    },
    createCommentReply({ state, dispatch }, data) {
      return TasksService.createCommentReply({
        taskId: data.taskId,
        parentId: data.parentId,
        text: data.text,
      })
        .then((response) => {
          if (!response.success) {
            return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
          }
        })
    },
    clearAllComments ({ commit }) {
      commit("setState", { key: "currentTaskComments", value: [] });
    }
  }
}
