<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        @mouseover="isCardHovered = true"
        @mouseout="isCardHovered = false"
        outlined
        :class="['cursor-pointer py-md-1']"
        :style="{
          backgroundColor: getCardColors(cardData)['bgColor'],
          borderColor: getCardColors(cardData)['borderColor'],
        }"
        @click="handleClick"
      >
        <v-card-text class="d-flex flex-column align-center justify-center">
          <v-icon
            :size="$vuetify.breakpoint.smAndDown ? 35 : 55"
            :color="getCardColors(cardData)['iconColor']"
          >
            {{ cardData.icon }}
          </v-icon>

          <div
            :class="[
              'mt-2',
              $vuetify.breakpoint.mdAndUp ? 'font-large' : 'font-small',
            ]"
            :style="{ color: getCardColors(cardData)['textColor'] }"
          >
            {{ cardData.name }}
          </div>
        </v-card-text>

        <v-fade-transition>
          <v-overlay
            v-if="hover && cardData.id === 'facebook'"
            absolute
            color="black"
            class="tetx-h6"
          >
            {{ $t("common.comingSoon") }}
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data: () => ({
    isCardHovered: false,
  }),
  computed: {
    getCardColors() {
      return (cardProps) => {
        if (this.isCardHovered) {
          return {
            bgColor: this.cardData.color,
            borderColor: this.cardData.borderColor,
            iconColor: "white",
            textColor: "white",
          };
        }

        return {
          bgColor: "white",
          borderColor: this.cardData.borderColor,
          iconColor: this.cardData.textColor,
          textColor: this.cardData.textColor,
        };
      };
    },
  },
  methods: {
    handleClick() {
      switch (this.cardData.id) {
        case "google":
          return this.$emit("sign-in-with-google");
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>