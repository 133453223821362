/* Service Imports */
import { AuthServiceClient } from "../protobufs/auth_grpc_web_pb";
import { OrganizationsClient } from "../protobufs/organization_grpc_web_pb";
import { ProjectsClient } from "../protobufs/project_grpc_web_pb";
import { TasksClient } from "../protobufs/task_grpc_web_pb";
import { NotificationsClient } from "../protobufs/notification_grpc_web_pb";
import { AttachmentsClient } from "../protobufs/attachment_grpc_web_pb";
import { UserServiceClient } from "../protobufs/user_grpc_web_pb";
/* Local Imports */
import store from "../store/index";
import { getData } from "./localService";
import { BASE_URL } from "./constants";

/* In case of unauthorized access we get a message and code */
const UNAUTHORIZED = "Unauthorized";
const UNAUTHORIZED_CODE = 2;

/* Shared */
export const getUserToken = () => {
  const user = getData("user");
  if (user && user.token) return user.token;
  return null;
};

export const responseInterceptor = (data) => {
  console.log(data);
  if (data && data.message === UNAUTHORIZED && data.code === UNAUTHORIZED_CODE) {
    return store.dispatch("login/logout");
  }

  if (data && data.array) {
    console.log(data.toObject());
    return data.toObject();
  }
};

export const requestInterceptor = (data) => {
  console.log(data.toObject());
  return data;
};

export const getService = (serviceName) => {
  switch (serviceName) {
    case "AUTH":
      return new AuthServiceClient(BASE_URL, null, null);
    case "ORGANIZATION":
      return new OrganizationsClient(BASE_URL, null, null);
    case "PROJECT":
      return new ProjectsClient(BASE_URL, null, null);
    case "TASK":
      return new TasksClient(BASE_URL, null, null);
    case "NOTIFICATION":
      return new NotificationsClient(BASE_URL, null, null);
    case "ATTACHMENT":
      return new AttachmentsClient(BASE_URL, null, null);
    case "USER":
      return new UserServiceClient(BASE_URL, null, null);
  }
};
