/**
 * @fileoverview gRPC-Web generated client stub for auth
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.auth = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.Empty,
 *   !proto.auth.GetApiVersionResponse>}
 */
const methodDescriptor_AuthService_GetApiVersion = new grpc.web.MethodDescriptor(
  '/auth.AuthService/GetApiVersion',
  grpc.web.MethodType.UNARY,
  proto.auth.Empty,
  proto.auth.GetApiVersionResponse,
  /**
   * @param {!proto.auth.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GetApiVersionResponse.deserializeBinary
);


/**
 * @param {!proto.auth.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GetApiVersionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GetApiVersionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.getApiVersion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/GetApiVersion',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetApiVersion,
      callback);
};


/**
 * @param {!proto.auth.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GetApiVersionResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.getApiVersion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/GetApiVersion',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetApiVersion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.RegisterRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthService_Register = new grpc.web.MethodDescriptor(
  '/auth.AuthService/Register',
  grpc.web.MethodType.UNARY,
  proto.auth.RegisterRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/Register',
      request,
      metadata || {},
      methodDescriptor_AuthService_Register,
      callback);
};


/**
 * @param {!proto.auth.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/Register',
      request,
      metadata || {},
      methodDescriptor_AuthService_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.LoginRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthService_Login = new grpc.web.MethodDescriptor(
  '/auth.AuthService/Login',
  grpc.web.MethodType.UNARY,
  proto.auth.LoginRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login,
      callback);
};


/**
 * @param {!proto.auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.Empty,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthService_RefreshToken = new grpc.web.MethodDescriptor(
  '/auth.AuthService/RefreshToken',
  grpc.web.MethodType.UNARY,
  proto.auth.Empty,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.refreshToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_RefreshToken,
      callback);
};


/**
 * @param {!proto.auth.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.refreshToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_RefreshToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.Empty,
 *   !proto.auth.GenericResponse>}
 */
const methodDescriptor_AuthService_Logout = new grpc.web.MethodDescriptor(
  '/auth.AuthService/Logout',
  grpc.web.MethodType.UNARY,
  proto.auth.Empty,
  proto.auth.GenericResponse,
  /**
   * @param {!proto.auth.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GenericResponse.deserializeBinary
);


/**
 * @param {!proto.auth.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GenericResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GenericResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthService_Logout,
      callback);
};


/**
 * @param {!proto.auth.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GenericResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthService_Logout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.ValidateTokenRequest,
 *   !proto.auth.GenericResponse>}
 */
const methodDescriptor_AuthService_ValidateToken = new grpc.web.MethodDescriptor(
  '/auth.AuthService/ValidateToken',
  grpc.web.MethodType.UNARY,
  proto.auth.ValidateTokenRequest,
  proto.auth.GenericResponse,
  /**
   * @param {!proto.auth.ValidateTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GenericResponse.deserializeBinary
);


/**
 * @param {!proto.auth.ValidateTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GenericResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GenericResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.validateToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/ValidateToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateToken,
      callback);
};


/**
 * @param {!proto.auth.ValidateTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GenericResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.validateToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/ValidateToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.VerifyEmailRequest,
 *   !proto.auth.VerifyEmailResponse>}
 */
const methodDescriptor_AuthService_VerifyEmail = new grpc.web.MethodDescriptor(
  '/auth.AuthService/VerifyEmail',
  grpc.web.MethodType.UNARY,
  proto.auth.VerifyEmailRequest,
  proto.auth.VerifyEmailResponse,
  /**
   * @param {!proto.auth.VerifyEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.VerifyEmailResponse.deserializeBinary
);


/**
 * @param {!proto.auth.VerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.VerifyEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.VerifyEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.verifyEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_AuthService_VerifyEmail,
      callback);
};


/**
 * @param {!proto.auth.VerifyEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.VerifyEmailResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.verifyEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/VerifyEmail',
      request,
      metadata || {},
      methodDescriptor_AuthService_VerifyEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.ResendEmailVerificationRequest,
 *   !proto.auth.GenericResponse>}
 */
const methodDescriptor_AuthService_ResendEmailVerification = new grpc.web.MethodDescriptor(
  '/auth.AuthService/ResendEmailVerification',
  grpc.web.MethodType.UNARY,
  proto.auth.ResendEmailVerificationRequest,
  proto.auth.GenericResponse,
  /**
   * @param {!proto.auth.ResendEmailVerificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GenericResponse.deserializeBinary
);


/**
 * @param {!proto.auth.ResendEmailVerificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GenericResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GenericResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.resendEmailVerification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/ResendEmailVerification',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResendEmailVerification,
      callback);
};


/**
 * @param {!proto.auth.ResendEmailVerificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GenericResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.resendEmailVerification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/ResendEmailVerification',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResendEmailVerification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.UpdateUserProfileRequest,
 *   !proto.auth.GenericResponse>}
 */
const methodDescriptor_AuthService_UpdateUserProfile = new grpc.web.MethodDescriptor(
  '/auth.AuthService/UpdateUserProfile',
  grpc.web.MethodType.UNARY,
  proto.auth.UpdateUserProfileRequest,
  proto.auth.GenericResponse,
  /**
   * @param {!proto.auth.UpdateUserProfileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GenericResponse.deserializeBinary
);


/**
 * @param {!proto.auth.UpdateUserProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GenericResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GenericResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.updateUserProfile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/UpdateUserProfile',
      request,
      metadata || {},
      methodDescriptor_AuthService_UpdateUserProfile,
      callback);
};


/**
 * @param {!proto.auth.UpdateUserProfileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GenericResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.updateUserProfile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/UpdateUserProfile',
      request,
      metadata || {},
      methodDescriptor_AuthService_UpdateUserProfile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.ForgotUserPasswordRequest,
 *   !proto.auth.GenericResponse>}
 */
const methodDescriptor_AuthService_ForgotUserPassword = new grpc.web.MethodDescriptor(
  '/auth.AuthService/ForgotUserPassword',
  grpc.web.MethodType.UNARY,
  proto.auth.ForgotUserPasswordRequest,
  proto.auth.GenericResponse,
  /**
   * @param {!proto.auth.ForgotUserPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GenericResponse.deserializeBinary
);


/**
 * @param {!proto.auth.ForgotUserPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GenericResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GenericResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.forgotUserPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/ForgotUserPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ForgotUserPassword,
      callback);
};


/**
 * @param {!proto.auth.ForgotUserPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GenericResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.forgotUserPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/ForgotUserPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ForgotUserPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.ResetUserPasswordRequest,
 *   !proto.auth.GenericResponse>}
 */
const methodDescriptor_AuthService_ResetUserPassword = new grpc.web.MethodDescriptor(
  '/auth.AuthService/ResetUserPassword',
  grpc.web.MethodType.UNARY,
  proto.auth.ResetUserPasswordRequest,
  proto.auth.GenericResponse,
  /**
   * @param {!proto.auth.ResetUserPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GenericResponse.deserializeBinary
);


/**
 * @param {!proto.auth.ResetUserPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GenericResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GenericResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.resetUserPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/ResetUserPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResetUserPassword,
      callback);
};


/**
 * @param {!proto.auth.ResetUserPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GenericResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.resetUserPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/ResetUserPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ResetUserPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.ChangeUserPasswordRequest,
 *   !proto.auth.GenericResponse>}
 */
const methodDescriptor_AuthService_ChangeUserPassword = new grpc.web.MethodDescriptor(
  '/auth.AuthService/ChangeUserPassword',
  grpc.web.MethodType.UNARY,
  proto.auth.ChangeUserPasswordRequest,
  proto.auth.GenericResponse,
  /**
   * @param {!proto.auth.ChangeUserPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GenericResponse.deserializeBinary
);


/**
 * @param {!proto.auth.ChangeUserPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GenericResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GenericResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.changeUserPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/ChangeUserPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangeUserPassword,
      callback);
};


/**
 * @param {!proto.auth.ChangeUserPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GenericResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.changeUserPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/ChangeUserPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangeUserPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.SigninViaGoogleRequest,
 *   !proto.auth.AddSocialSigninMethodResponse>}
 */
const methodDescriptor_AuthService_AddGoogleSigninMethod = new grpc.web.MethodDescriptor(
  '/auth.AuthService/AddGoogleSigninMethod',
  grpc.web.MethodType.UNARY,
  proto.auth.SigninViaGoogleRequest,
  proto.auth.AddSocialSigninMethodResponse,
  /**
   * @param {!proto.auth.SigninViaGoogleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.AddSocialSigninMethodResponse.deserializeBinary
);


/**
 * @param {!proto.auth.SigninViaGoogleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.AddSocialSigninMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.AddSocialSigninMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.addGoogleSigninMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/AddGoogleSigninMethod',
      request,
      metadata || {},
      methodDescriptor_AuthService_AddGoogleSigninMethod,
      callback);
};


/**
 * @param {!proto.auth.SigninViaGoogleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.AddSocialSigninMethodResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.addGoogleSigninMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/AddGoogleSigninMethod',
      request,
      metadata || {},
      methodDescriptor_AuthService_AddGoogleSigninMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.Empty,
 *   !proto.auth.GetSocialSigninMethodsResponse>}
 */
const methodDescriptor_AuthService_GetSocialSigninMethods = new grpc.web.MethodDescriptor(
  '/auth.AuthService/GetSocialSigninMethods',
  grpc.web.MethodType.UNARY,
  proto.auth.Empty,
  proto.auth.GetSocialSigninMethodsResponse,
  /**
   * @param {!proto.auth.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GetSocialSigninMethodsResponse.deserializeBinary
);


/**
 * @param {!proto.auth.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GetSocialSigninMethodsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GetSocialSigninMethodsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.getSocialSigninMethods =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/GetSocialSigninMethods',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetSocialSigninMethods,
      callback);
};


/**
 * @param {!proto.auth.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GetSocialSigninMethodsResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.getSocialSigninMethods =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/GetSocialSigninMethods',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetSocialSigninMethods);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.DeleteSocialSigninMethodRequest,
 *   !proto.auth.GenericResponse>}
 */
const methodDescriptor_AuthService_DeleteSocialSigninMethod = new grpc.web.MethodDescriptor(
  '/auth.AuthService/DeleteSocialSigninMethod',
  grpc.web.MethodType.UNARY,
  proto.auth.DeleteSocialSigninMethodRequest,
  proto.auth.GenericResponse,
  /**
   * @param {!proto.auth.DeleteSocialSigninMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.GenericResponse.deserializeBinary
);


/**
 * @param {!proto.auth.DeleteSocialSigninMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.GenericResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.GenericResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.deleteSocialSigninMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/DeleteSocialSigninMethod',
      request,
      metadata || {},
      methodDescriptor_AuthService_DeleteSocialSigninMethod,
      callback);
};


/**
 * @param {!proto.auth.DeleteSocialSigninMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.GenericResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.deleteSocialSigninMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/DeleteSocialSigninMethod',
      request,
      metadata || {},
      methodDescriptor_AuthService_DeleteSocialSigninMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.SigninViaGoogleRequest,
 *   !proto.auth.SigninViaSocialAccountResponse>}
 */
const methodDescriptor_AuthService_SigninViaGoogle = new grpc.web.MethodDescriptor(
  '/auth.AuthService/SigninViaGoogle',
  grpc.web.MethodType.UNARY,
  proto.auth.SigninViaGoogleRequest,
  proto.auth.SigninViaSocialAccountResponse,
  /**
   * @param {!proto.auth.SigninViaGoogleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.SigninViaSocialAccountResponse.deserializeBinary
);


/**
 * @param {!proto.auth.SigninViaGoogleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.SigninViaSocialAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.SigninViaSocialAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.signinViaGoogle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/SigninViaGoogle',
      request,
      metadata || {},
      methodDescriptor_AuthService_SigninViaGoogle,
      callback);
};


/**
 * @param {!proto.auth.SigninViaGoogleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.SigninViaSocialAccountResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.signinViaGoogle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/SigninViaGoogle',
      request,
      metadata || {},
      methodDescriptor_AuthService_SigninViaGoogle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.SigninViaFacebookRequest,
 *   !proto.auth.SigninViaSocialAccountResponse>}
 */
const methodDescriptor_AuthService_SigninViaFacebook = new grpc.web.MethodDescriptor(
  '/auth.AuthService/SigninViaFacebook',
  grpc.web.MethodType.UNARY,
  proto.auth.SigninViaFacebookRequest,
  proto.auth.SigninViaSocialAccountResponse,
  /**
   * @param {!proto.auth.SigninViaFacebookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.SigninViaSocialAccountResponse.deserializeBinary
);


/**
 * @param {!proto.auth.SigninViaFacebookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.SigninViaSocialAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.SigninViaSocialAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.signinViaFacebook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/SigninViaFacebook',
      request,
      metadata || {},
      methodDescriptor_AuthService_SigninViaFacebook,
      callback);
};


/**
 * @param {!proto.auth.SigninViaFacebookRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.SigninViaSocialAccountResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.signinViaFacebook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/SigninViaFacebook',
      request,
      metadata || {},
      methodDescriptor_AuthService_SigninViaFacebook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.SigninViaFacebookRequest,
 *   !proto.auth.AddSocialSigninMethodResponse>}
 */
const methodDescriptor_AuthService_AddFacebookSigninMethod = new grpc.web.MethodDescriptor(
  '/auth.AuthService/AddFacebookSigninMethod',
  grpc.web.MethodType.UNARY,
  proto.auth.SigninViaFacebookRequest,
  proto.auth.AddSocialSigninMethodResponse,
  /**
   * @param {!proto.auth.SigninViaFacebookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.AddSocialSigninMethodResponse.deserializeBinary
);


/**
 * @param {!proto.auth.SigninViaFacebookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.AddSocialSigninMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.AddSocialSigninMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.addFacebookSigninMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/AddFacebookSigninMethod',
      request,
      metadata || {},
      methodDescriptor_AuthService_AddFacebookSigninMethod,
      callback);
};


/**
 * @param {!proto.auth.SigninViaFacebookRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.AddSocialSigninMethodResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.addFacebookSigninMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/AddFacebookSigninMethod',
      request,
      metadata || {},
      methodDescriptor_AuthService_AddFacebookSigninMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.SignupFromInvitationRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthService_SignupFromOrganizationInvitation = new grpc.web.MethodDescriptor(
  '/auth.AuthService/SignupFromOrganizationInvitation',
  grpc.web.MethodType.UNARY,
  proto.auth.SignupFromInvitationRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.SignupFromInvitationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.SignupFromInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.signupFromOrganizationInvitation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/SignupFromOrganizationInvitation',
      request,
      metadata || {},
      methodDescriptor_AuthService_SignupFromOrganizationInvitation,
      callback);
};


/**
 * @param {!proto.auth.SignupFromInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.signupFromOrganizationInvitation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/SignupFromOrganizationInvitation',
      request,
      metadata || {},
      methodDescriptor_AuthService_SignupFromOrganizationInvitation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.SignupFromInvitationRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthService_SignupFromProjectInvitation = new grpc.web.MethodDescriptor(
  '/auth.AuthService/SignupFromProjectInvitation',
  grpc.web.MethodType.UNARY,
  proto.auth.SignupFromInvitationRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.SignupFromInvitationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.SignupFromInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.signupFromProjectInvitation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/SignupFromProjectInvitation',
      request,
      metadata || {},
      methodDescriptor_AuthService_SignupFromProjectInvitation,
      callback);
};


/**
 * @param {!proto.auth.SignupFromInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.signupFromProjectInvitation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/SignupFromProjectInvitation',
      request,
      metadata || {},
      methodDescriptor_AuthService_SignupFromProjectInvitation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.SignupFromInvitationRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_AuthService_SignupFromTaskInvitation = new grpc.web.MethodDescriptor(
  '/auth.AuthService/SignupFromTaskInvitation',
  grpc.web.MethodType.UNARY,
  proto.auth.SignupFromInvitationRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.SignupFromInvitationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.SignupFromInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.AuthServiceClient.prototype.signupFromTaskInvitation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.AuthService/SignupFromTaskInvitation',
      request,
      metadata || {},
      methodDescriptor_AuthService_SignupFromTaskInvitation,
      callback);
};


/**
 * @param {!proto.auth.SignupFromInvitationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.AuthServicePromiseClient.prototype.signupFromTaskInvitation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.AuthService/SignupFromTaskInvitation',
      request,
      metadata || {},
      methodDescriptor_AuthService_SignupFromTaskInvitation);
};


module.exports = proto.auth;

