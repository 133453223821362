/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.CreateWorkScheduleRequest,
 *   !proto.WorkScheduleResponse>}
 */
const methodDescriptor_UserService_CreateWorkSchedule = new grpc.web.MethodDescriptor(
  '/UserService/CreateWorkSchedule',
  grpc.web.MethodType.UNARY,
  proto.CreateWorkScheduleRequest,
  proto.WorkScheduleResponse,
  /**
   * @param {!proto.CreateWorkScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.WorkScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.CreateWorkScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.WorkScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.WorkScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.UserServiceClient.prototype.createWorkSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/UserService/CreateWorkSchedule',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateWorkSchedule,
      callback);
};


/**
 * @param {!proto.CreateWorkScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.WorkScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.UserServicePromiseClient.prototype.createWorkSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/UserService/CreateWorkSchedule',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateWorkSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.UpdateWorkScheduleRequest,
 *   !proto.WorkScheduleResponse>}
 */
const methodDescriptor_UserService_UpdateWorkSchedule = new grpc.web.MethodDescriptor(
  '/UserService/UpdateWorkSchedule',
  grpc.web.MethodType.UNARY,
  proto.UpdateWorkScheduleRequest,
  proto.WorkScheduleResponse,
  /**
   * @param {!proto.UpdateWorkScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.WorkScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.UpdateWorkScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.WorkScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.WorkScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.UserServiceClient.prototype.updateWorkSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/UserService/UpdateWorkSchedule',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateWorkSchedule,
      callback);
};


/**
 * @param {!proto.UpdateWorkScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.WorkScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.UserServicePromiseClient.prototype.updateWorkSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/UserService/UpdateWorkSchedule',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateWorkSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.EmptyWorkScheduleRequest,
 *   !proto.WorkScheduleResponse>}
 */
const methodDescriptor_UserService_GetWorkSchedule = new grpc.web.MethodDescriptor(
  '/UserService/GetWorkSchedule',
  grpc.web.MethodType.UNARY,
  proto.EmptyWorkScheduleRequest,
  proto.WorkScheduleResponse,
  /**
   * @param {!proto.EmptyWorkScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.WorkScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.EmptyWorkScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.WorkScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.WorkScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.UserServiceClient.prototype.getWorkSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/UserService/GetWorkSchedule',
      request,
      metadata || {},
      methodDescriptor_UserService_GetWorkSchedule,
      callback);
};


/**
 * @param {!proto.EmptyWorkScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.WorkScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.UserServicePromiseClient.prototype.getWorkSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/UserService/GetWorkSchedule',
      request,
      metadata || {},
      methodDescriptor_UserService_GetWorkSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.EmptyWorkScheduleRequest,
 *   !proto.WorkScheduleResponse>}
 */
const methodDescriptor_UserService_DeleteWorkSchedule = new grpc.web.MethodDescriptor(
  '/UserService/DeleteWorkSchedule',
  grpc.web.MethodType.UNARY,
  proto.EmptyWorkScheduleRequest,
  proto.WorkScheduleResponse,
  /**
   * @param {!proto.EmptyWorkScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.WorkScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.EmptyWorkScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.WorkScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.WorkScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.UserServiceClient.prototype.deleteWorkSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/UserService/DeleteWorkSchedule',
      request,
      metadata || {},
      methodDescriptor_UserService_DeleteWorkSchedule,
      callback);
};


/**
 * @param {!proto.EmptyWorkScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.WorkScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.UserServicePromiseClient.prototype.deleteWorkSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/UserService/DeleteWorkSchedule',
      request,
      metadata || {},
      methodDescriptor_UserService_DeleteWorkSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.EmptyWorkScheduleRequest,
 *   !proto.ListAllUsersResponse>}
 */
const methodDescriptor_UserService_ListAllUsers = new grpc.web.MethodDescriptor(
  '/UserService/ListAllUsers',
  grpc.web.MethodType.UNARY,
  proto.EmptyWorkScheduleRequest,
  proto.ListAllUsersResponse,
  /**
   * @param {!proto.EmptyWorkScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListAllUsersResponse.deserializeBinary
);


/**
 * @param {!proto.EmptyWorkScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListAllUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListAllUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.UserServiceClient.prototype.listAllUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/UserService/ListAllUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_ListAllUsers,
      callback);
};


/**
 * @param {!proto.EmptyWorkScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListAllUsersResponse>}
 *     Promise that resolves to the response
 */
proto.UserServicePromiseClient.prototype.listAllUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/UserService/ListAllUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_ListAllUsers);
};


module.exports = proto;

