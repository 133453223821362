import { getField, updateField } from "vuex-map-fields";
import AuthService from "../services/authService";
import { vm } from "@/main";

export default {
 namespaced: true,
 state: () => ({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
 }),
 mutations: {
  updateField,
  setState(state, payload) {
   state[payload.key] = payload.value;
  },
 },
 actions: {
  async signup({ state, commit, dispatch }) {
   commit("startLoading", null, { root: true });

   const response = await AuthService.register(state);

   commit("stopLoading", null, { root: true });

   if (!response.success) {
    return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
   }

   dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

   return true;
  },

  async registerNonFpmUser({ state, commit, dispatch }, payload) {
   commit("startLoading", null, { root: true });

   const data = {
    ...payload,
    ...state,
   };

   const response = await AuthService.registerUsingInvitationEmail(data);

   commit("stopLoading", null, { root: true });

   if (!response.success) {
    return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
   }

   const { user } = response;

   const userObj = {
    entity_id: user.entityId,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    token: user.accessToken,
    verified: user.verified,
   };

   /* 
        Dispatch action of main store which will 
        save the user in state as well as local storage 
      */
   dispatch("setUserState", userObj, { root: true });

   dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

   return true;
  },

  async registerFromProjectInvitation({ state, commit, dispatch }, payload) {
   commit("startLoading", null, { root: true });

   const data = {
    ...payload,
    ...state,
   };

   const response = await AuthService.signupFromProjectInvitation(data);

   commit("stopLoading", null, { root: true });

   if (!response.success) {
    return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
   }

   const { user } = response;

   const userObj = {
    entity_id: user.entityId,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    token: user.accessToken,
    verified: user.verified,
   };

   /* 
        Dispatch action of main store which will 
        save the user in state as well as local storage 
      */
   dispatch("setUserState", userObj, { root: true });

   dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

   return true;
  },

  async registerFromTaskInvitation({ state, commit, dispatch }, payload) {
   commit("startLoading", null, { root: true });

   const data = {
    ...payload,
    ...state,
   };

   const response = await AuthService.signupFromTaskInvitation(data);

   commit("stopLoading", null, { root: true });

   if (!response.success) {
    return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
   }

   const { user } = response;

   const userObj = {
    entity_id: user.entityId,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    token: user.accessToken,
    verified: user.verified,
   };

   /* 
      Dispatch action of main store which will 
      save the user in state as well as local storage 
    */
   dispatch("setUserState", userObj, { root: true });

   dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

   return true;
  },
 },
 getters: {
  getField,
 },
};
