var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$vuetify.breakpoint.smAndDown)?_c('mobile-header'):_vm._e(),_c('v-main',{staticClass:"backgroundPrimary mt-0 pt-0"},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',[_c('v-row',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"\n              backgroundSecondary\n              pa-0\n              d-flex\n              justify-space-between\n              flex-column\n            ",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"ps-5 pt-5"},[_c('div',{staticClass:"d-flex"},[_c('app-logo',{attrs:{"showName":""}})],1),_c('div',{staticClass:"mt-6 secondaryText--text"},[_c('h2',[_vm._v(_vm._s(_vm.$t("login.organize")))]),_c('h2',[_vm._v(_vm._s(_vm.$t("login.easyWay")))]),_c('small',{staticClass:"font-small"},[_vm._v(_vm._s(_vm.getMessage.sub))])])]),_c('div',{class:[
                'd-flex',
                'ms-0',
                'image-container',
                'pa-0',
                _vm.imagePosition ]},[_c('img',{class:[
                  'organization-card',
                  _vm.$vuetify.theme.isDark
                    ? 'reserva-margin-dark'
                    : 'reserva-margin-light',
                  'reserva' ],attrs:{"src":require(("../assets/" + (_vm.$vuetify.theme.isDark ? 'Reserva' : 'Reserva-light') + ".svg"))}}),_c('img',{class:[
                  _vm.$vuetify.theme.isDark
                    ? 'app-screen-dark'
                    : 'app-screen-light',
                  'app-screen' ],attrs:{"src":require(("../assets/" + (_vm.$vuetify.theme.isDark ? 'login' : 'login-light') + ".svg"))}})])]):_vm._e(),_c('v-col',{class:[
              'right-section backgroundPrimary',
              'pt-0',
              'pl-0',
              'pb-0' ],attrs:{"cols":"12","md":"6"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1),_c('div',{staticClass:"settings-pane d-flex align-center"},[_c('language-toggler'),_c('theme-toggler',{staticClass:"ms-3"})],1)],1)],1)],1)],1)],1),_c('app-version',{staticClass:"me-2"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }