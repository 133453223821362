import Vue from "vue";
import Vuex from "vuex";
import { i18n } from "@/main";
import Vuetify from "@/plugins/vuetify";
import { getField, updateField } from "vuex-map-fields";

/* MODULES */
import LoginModule from "./login";
import SignUpModule from "./signup";
import ForgotPasswordModule from "./forgot";
import OrganisationModule from "./organisations";
import ProjectsModule from "./projects";
import TasksModule from "./tasks";
import NotificationModule from "./notifications";
import AttachmentModule from "./attachments";
import AllTasksModule from "./allTasks";
import UserModule from "./user";
import CommentModule from "./comments";

/* IMPORTS */
import { saveData, getData } from "@/services/localService";
import { vm } from "../main";
import AuthService from "@/services/authService";

Vue.use(Vuex);

export default new Vuex.Store({
 state: () => ({
  environment: null,
  currentLanguage: "en",
  deleteDialog: false,
  rtl: false,
  currentUser: null,
  isUserloggedIn: false,
  dark: false,
  drawer: true,
  loading: false,
  profileModal: false,
  mobileMenu: false,
 }),

 mutations: {
  updateField,
  changeLanguage(state, languageCode) {
   i18n.locale = languageCode;
   state.currentLanguage = languageCode;
   Vuetify.framework.rtl = languageCode === "ur" ? true : false;
   state.rtl = languageCode === "ur" ? true : false;
   saveData("locale", {
    locale: state.currentLanguage,
    rtl: state.rtl,
   });
  },
  setTheme(state, value) {
   state.dark = value;
   saveData("dark", value);
  },
  setState(state, payload) {
   state[payload.key] = payload.value;
  },
  startLoading(state) {
   state.loading = true;
  },
  stopLoading(state) {
   state.loading = false;
  },
  setEnvironment(state) {
   state.environment = process.env.VUE_APP_ENVIRONMENT;
  },
 },

 actions: {
  showMessage({ commit }, payload) {
   return vm.$notify({
    type: payload.messageType,
    title: payload.messageTitle,
    text: payload.message,
   });
  },

  setUserState({ commit }, payload) {
   commit("setState", { key: "currentUser", value: payload });
   commit("setState", { key: "isUserloggedIn", value: true });
   saveData("user", payload);
   saveData("loggedIn", true);
  },

  async getApiVersion() {
   try {
    const response = await AuthService.getApiVersion();
    if (response.success) {
     console.log(` %cAPI VERSION : ${JSON.parse(response.version)}`, "color: green; font-size: 15px;");
    }
   } catch (e) {
    console.log(e);
   } finally {
   }
  },

  async updateUser({ commit, state }, payload) {
   try {
    const response = await AuthService.updateuserProfile(payload);
    if (response.success) {
     const updatedPayload = {
      ...getData("user"),
      ...payload,
     };
     saveData("user", updatedPayload);
     commit("setState", { key: "currentUser", value: updatedPayload });
     commit("setState", { key: "allowEdit", value: false });
    }
   } catch (e) {
    console.log(e);
   } finally {
   }
  },

  async changeUserPassword({ commit, state, dispatch }, payload) {
   try {
    const response = await AuthService.changeUserPassword(payload);

    if (response.success) {
     state.profileModal = false;
     return dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });
    }

    return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
   } catch (e) {
    console.log(e);
   } finally {
   }
  },
 },

 getters: {
  getField,
  environment: (state) => state.environment,
  localeInformation: (state) => ({
   currentLanguage: state.currentLanguage,
   rtl: state.rtl,
  }),
  userInformation: (state) => state.currentUser,
  isLoggedIn: (state) => state.isUserloggedIn,
  drawer: (state) => state.drawer,
  loading: (state) => state.loading,
 },

 modules: {
  login: LoginModule,
  signup: SignUpModule,
  forgot: ForgotPasswordModule,
  organisations: OrganisationModule,
  projects: ProjectsModule,
  tasks: TasksModule,
  notifications: NotificationModule,
  attachments: AttachmentModule,
  allTasks: AllTasksModule,
  user: UserModule,
  comments: CommentModule,
 },
});
