import {
 CreateWorkScheduleRequest,
 CreateWorkScheduleRequestData,
 UpdateWorkScheduleRequest,
 UpdateWorkScheduleRequestData,
 EmptyWorkScheduleRequest,
} from "../protobufs/user_pb.js";

import { responseInterceptor, requestInterceptor, getUserToken, getService } from "./Interceptor";

export default {
 client: getService("USER"),

 getAllUsers: function () {
  return new Promise((resolve, reject) => {
    let request = new EmptyWorkScheduleRequest()

    this.client.listAllUsers(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
    });
  });
 }, 

 getWorkSchedule: function () {
  return new Promise((resolve, reject) => {
   let request = new EmptyWorkScheduleRequest();

   this.client.getWorkSchedule(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 createWorkSchedule: function (data) {
  return new Promise((resolve, reject) => {
   let request = new CreateWorkScheduleRequest();

   let schedules = [];

   data.forEach((schedule) => {
    let scheduleData = new CreateWorkScheduleRequestData();
    scheduleData.setDay(schedule.day);
    scheduleData.setStartTime(schedule.startTime);
    scheduleData.setStopTime(schedule.stopTime);
    schedules.push(scheduleData);
   });

   request.setSchedulesList(schedules);

   this.client.createWorkSchedule(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 updateWorkSchedule: function (data) {
  return new Promise((resolve, reject) => {
   let request = new UpdateWorkScheduleRequest();

   let schedules = [];

   data.forEach((schedule) => {
    let scheduleData = new UpdateWorkScheduleRequestData();
    scheduleData.setDay(schedule.day);
    scheduleData.setStartTime(schedule.startTime);
    scheduleData.setStopTime(schedule.stopTime);
    scheduleData.setEntityId(schedule.entityId);
    schedules.push(scheduleData);
   });

   request.setSchedulesList(schedules);

   this.client.updateWorkSchedule(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 deleteWorkSchedule: function () {
  return new Promise((resolve, reject) => {
   let request = new EmptyWorkScheduleRequest();

   this.client.deleteWorkSchedule(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },
};
