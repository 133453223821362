let clientOptions = null;
let client = null;

const logError = (message) => {
  let consoleMessageConfig = "color: red; font-size: 10px;";
  console.log(`%c${message}`, consoleMessageConfig);
};

if (!process.env.VUE_APP_FILESTACK_API_KEY) {
  logError("FILESTACK API KEY NOT PROVIDED");
}

if (!process.env.VUE_APP_FILESTACK_POLICY) {
  logError("FILESTACK POLICY NOT PROVIDED");
}

if (!process.env.VUE_APP_FILESTACK_SIGNATURE) {
  logError("FILESTACK SIGNATURE NOT PROVIDED");
}

if (process.env.VUE_APP_FILESTACK_API_KEY) {
  clientOptions = {
    security: {
      policy: process.env.VUE_APP_FILESTACK_POLICY,
      signature: process.env.VUE_APP_FILESTACK_SIGNATURE,
    },
  };

  client = window && window.filestack && window.filestack.init(process.env.VUE_APP_FILESTACK_API_KEY, clientOptions);
}

export default {
  filestackClient: client,

  uuid() {
    return crypto.randomUUID().replaceAll("-", "");
  },

  lowercase(str) {
    if (!str) throw new Error("String is not passed to the function");
    return str.toLowerCase();
  },

  getDayName(shortName) {
    return {
      Mon: "Monday",
      Tue: "Tuesday",
      Wed: "Wednesday",
      Thu: "Thursday",
      Fri: "Friday",
      Sat: "Saturday",
      Sun: "Sunday",
    }[shortName];
  },

  downloadFilestackFile(response) {
    const data = window.URL.createObjectURL(response.data);
    const filename = response?.headers["x-file-name"];
    const link = document.createElement("a");
    link.href = data;
    link.download = filename;
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    window.URL.revokeObjectURL(data);
    link.remove();
  },

  formatTime(time) {
    const t = time.replace("AM", " AM").replace("PM", " PM").trim();
    let ta = t.trim().split(" ");
    let slots = ta[0].split(":");
    while (slots.length < 3) slots.push("");
    let dt = slots.map((n) => n.padStart(2, "0")).join(":") + " " + (ta.length > 1 ? ta[1].trim().toUpperCase() : "");
    return dt;
  },

  convert12to24(time12h) {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  },

  convertToAmPm(val) {
    let [h, m] = val.split(":");
    const time = (h % 12 ? h % 12 : 12) + ":" + m;
    const zone = `${h >= 12 ? "PM" : "AM"}`;
    const finalTime = `${time}${zone}`;
    return finalTime;
  },

  getInitials(name) {
    return name
      .match(/(^\S\S?|\b\S)?/g)
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("")
      .toUpperCase();
  },

  generateProjectCode(projectName) {
    const isEmpty = !projectName || projectName.trim() === "";
    const invalidLength = projectName && projectName.trim().length < 3;

    if (isEmpty || invalidLength) return "";

    const splited = projectName.split(" ").filter(Boolean);

    if (splited.length === 1) return sliceBy(splited[0], 3);
    if (splited.length === 2) return sliceBy(splited[0], 2) + sliceBy(splited[1], 1);
    else return sliceBy(splited[0], 1) + sliceBy(splited[1], 1) + sliceBy(splited[2], 1);

    // SLICE BY FUNCTION
    function sliceBy(str, on) {
      return str.slice(0, on).toUpperCase();
    }
  },
};

export const inputFieldProps = {
  outlined: true,
  class: "rounded-lg",
  "background-color": "backgroundSecondary",
};
