<template>
  <v-progress-linear
    indeterminate
    class="loader"
    color="primary"
    v-if="loading"
    height="2"
  />
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  computed: {
    ...mapFields({
      loading: "loading",
    }),
  },
};
</script>

<style scoped>
.loader {
  position: absolute;
  top: 0;
  height: 4px;
  z-index: 10;
}
</style>