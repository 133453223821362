import {
  ListProjectRequest,
  ProjectInsertRequest,
  ProjectId,
  ProjectUpdateRequest,
  ListProjectRoleRequest,
  AddToProjectRequest,
  ReplyToInvitationFromEmailRequest,
  ProjectRoleId,
  RejectInvitationToProjectRequest,
  UpdateProjectRoleRequest,
  TransferProjectOwnershipRequest,
} from "../protobufs/project_pb";

import { AttachmentCreateRequest } from "../protobufs/attachment_pb";

import { responseInterceptor, requestInterceptor, getUserToken, getService } from "./Interceptor";

export default {
  client: getService("PROJECT"),

  getProjects: function (organisation_id) {
    return new Promise((resolve, reject) => {
      let request = new ListProjectRequest();

      request.setOrganizationId(organisation_id);

      this.client.listProjects(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  createProject: function (data) {
    return new Promise((resolve, reject) => {
      let request = new ProjectInsertRequest();

      request.setName(data.name);
      request.setOrganizationId(data.organisationId);
      request.setDescription(data.description);
      request.setDeadline(data.deadline);
      request.setCode(data.code);

      let allAttachments = [];

      data.attachments.forEach((el) => {
        let attachmentsRequest = new AttachmentCreateRequest();
        attachmentsRequest.setFilename(el.filename);
        attachmentsRequest.setUrl(el.url);
        attachmentsRequest.setUploadId(el.upload_id);
        attachmentsRequest.setMimeType(el.mime_type);
        allAttachments.push(attachmentsRequest);
      });

      request.setAttachmentsList(allAttachments);

      this.client.createProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  deleteProject: function (data) {
    return new Promise((resolve, reject) => {
      let request = new ProjectId();

      request.setEntityId(data.entity_id);

      this.client.deleteProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  updateProject: function (data) {
    return new Promise((resolve, reject) => {
      let request = new ProjectUpdateRequest();

      request.setName(data.name);
      request.setEntityId(data.entity_id);
      request.setDescription(data.description);
      request.setDeadline(data.deadline);

      this.client.updateProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  getProjectDetails: function (data) {
    return new Promise((resolve, reject) => {
      let request = new ProjectId();

      request.setEntityId(data.entity_id);

      this.client.getProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  getProjectMembers: function (data) {
    return new Promise((resolve, reject) => {
      let request = new ListProjectRoleRequest();

      request.setProjectId(data.project_id);

      this.client.listAllProjectRoles(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  addMemberToProject: function (data) {
    return new Promise((resolve, reject) => {
      let request = new AddToProjectRequest();

      request.setRole(data.role);
      request.setProjectId(data.project_id);
      request.setUserEmail(data.userEmail);

      this.client.addToProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  removeMemberFromProject: function (data) {
    return new Promise((resolve, reject) => {
      let request = new ProjectRoleId();

      request.setUserId(data.user_id);
      request.setProjectId(data.project_id);

      this.client.removeFromProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  acceptInvitationToProjectFromEmail: function (data) {
    return new Promise((resolve, reject) => {
      let request = new ReplyToInvitationFromEmailRequest();

      request.setToken(data.token);
      request.setUidb64(data.uidb);
      request.setMessage(data.message);

      this.client.acceptProjectInvitationFromEmail(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  rejectInvitationToProjectFromEmail: function (data) {
    return new Promise((resolve, reject) => {
      let request = new ReplyToInvitationFromEmailRequest();

      request.setToken(data.token);
      request.setUidb64(data.uidb);
      request.setMessage(data.message);

      this.client.rejectProjectInvitationFromEmail(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  acceptInvitationToProjectFromNotification: function (data) {
    return new Promise((resolve, reject) => {
      let request = new ProjectRoleId();

      request.setUserId(data.user_id);
      request.setProjectId(data.project_id);

      this.client.acceptInvitationToProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  rejectInvitationToProjectFromNotification: function (data) {
    return new Promise((resolve, reject) => {
      let request = new RejectInvitationToProjectRequest();

      request.setUserId(data.user_id);
      request.setProjectId(data.project_id);
      request.setMessage(data.message);

      this.client.rejectInvitationToProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  updateUserRoleInProject: function (data) {
    return new Promise((resolve, reject) => {
      let request = new UpdateProjectRoleRequest();

      request.setUserId(data.userId);
      request.setProjectId(data.projectId);
      request.setRole(data.role);

      this.client.updateProjectRole(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },

  transferProjectOwnership: function (data) {
    return new Promise((resolve, reject) => {
      let request = new TransferProjectOwnershipRequest();

      request.setFromUserId(data.fromUserId);
      request.setToUserId(data.toUserId);
      request.setProjectId(data.projectId);

      this.client.transferProjectOwnership(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
        if (err) reject(responseInterceptor(err));
        resolve(responseInterceptor(response));
      });
    });
  },
};
