import { getData } from '@/services/localService';

const getUserFromLocalStorage = () => getData('user');

export const isUserLoggedIn = () => {
  const user = getUserFromLocalStorage();
  return user && user.token ? true : false;
}

export const getUserInformation = () => {
  const user = getUserFromLocalStorage();
  user ? user : null;
}