<template>
  <v-col
    cols="12"
    class="less-height d-flex justify-space-between align-center px-0"
  >
    <div>
      <div class="font-heading font-weight-bold tightly-spaced">
        <slot name="heading"></slot>
      </div>
      <small class="secondary-color font-sub-heading">
        {{ subMessage }}
      </small>
    </div>

    <app-logo />
  </v-col>
</template>

<script>
import AppLogo from "./AppLogo.vue";

export default {
  components: { AppLogo },
  props: {
    subMessage: {
      type: String,
      default: () => "Sub Message",
    },
  },
};
</script>

<style scoped>
.less-height {
  line-height: 30px;
}
</style>