import { getField, updateField } from 'vuex-map-fields';
import notificationService from '@/services/notificationService';
import { vm } from '@/main';

export default {
  namespaced: true,
  state: () => ({
    allNotifications: [],
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    }
  },
  actions: {
    async getNotifications({ state, commit, dispatch }) {
      try {
        commit('startLoading', null, { root: true });

        const response = await notificationService.getNotifications();

        if (!response.success) {
          return dispatch('showMessage', { message: response.message, messageType: 'error', messageTitle: vm.$t('errors.error') }, { root: true });
        }

        commit('setState', { key: 'allNotifications',  value: response.notificationsList })
        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },

    async deleteNotification({ state, commit, dispatch }, entity_id) {
      try {
        commit('startLoading', null, { root: true });

        const response = await notificationService.deleteNotification(entity_id);

        if (!response.success) {
          return dispatch('showMessage', { message: response.message, messageType: 'error', messageTitle: vm.$t('errors.error') }, { root: true });
        }
        
        const updated = state.allNotifications.filter((notification) => notification.entityId !== entity_id);
        
        commit('setState', { key: 'allNotifications', value: [...updated] });
        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit('stopLoading', null, { root: true });
      }
    },
  },
  getters: {
    getField
  }
}