<template>
  <v-sheet
    color="transparent"
    :class="[centered ? 'version-centered' : 'version-main']"
  >
    <small :class="['version-color']"> v{{ app_version.version }} </small>
  </v-sheet>
</template>

<script>
import version from "../../Mixins/version";

export default {
  mixins: [version],
  props: {
    centered: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style scoped>
.version-main {
  position: absolute;
  bottom: 2px;
  left: 5px;
  font-size: 12px;
}

.version-centered {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.version-color {
  color: #b7b7b7;
}
</style>