import {
 OrganizationInsertRequest,
 Empty,
 OrganizationUpdateRequest,
 OrganizationId,
 InviteToOrganizationRequest,
 ReplyToInvitationFromEmailRequest,
 ListOrganizationRoleRequest,
 OrganizationRoleId,
 UpdateOrganizationRoleRequest,
 RejectInvitationToOrganizationRequest,
} from "../protobufs/organization_pb";
import { responseInterceptor, requestInterceptor, getUserToken, getService } from "./Interceptor";

export default {
 client: getService("ORGANIZATION"),

 getOrganizations: function () {
  return new Promise((resolve, reject) => {
   let request = new Empty();

   this.client.listOrganizations(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 createOrganization: function (data) {
  return new Promise((resolve, reject) => {
   let request = new OrganizationInsertRequest();

   request.setName(data.name);

   this.client.createOrganization(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 updateOrganization: function (data) {
  return new Promise((resolve, reject) => {
   let request = new OrganizationUpdateRequest();
   request.setName(data.name);
   request.setEntityId(data.entity_id);
   this.client.updateOrganization(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 deleteOrganization: function (data) {
  return new Promise((resolve, reject) => {
   let request = new OrganizationId();
   request.setEntityId(data.entity_id);
   this.client.deleteOrganization(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 getOrganizationDetails: function (data) {
  return new Promise((resolve, reject) => {
   let request = new OrganizationId();
   request.setEntityId(data.entity_id);
   this.client.getOrganization(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    console.log(err, response);
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 inviteMember: function (data) {
  return new Promise((resolve, reject) => {
   let request = new InviteToOrganizationRequest();

   request.setRole(data.role);
   request.setOrganizationId(data.organization_id);
   request.setUserEmail(data.email);

   this.client.inviteToOrganization(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 removeMember: function (data) {
  return new Promise((resolve, reject) => {
   let request = new OrganizationRoleId();

   request.setUserId(data.userId);
   request.setOrganizationId(data.organizationId);

   this.client.removeFromOrganization(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 acceptInvitationFromEmail: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ReplyToInvitationFromEmailRequest();

   request.setToken(data.token);
   request.setUidb64(data.uidb);
   request.setMessage(data.message);

   this.client.acceptInvitationFromEmail(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 rejectInvitationFromEmail: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ReplyToInvitationFromEmailRequest();

   request.setToken(data.token);
   request.setUidb64(data.uidb);
   request.setMessage(data.message);

   this.client.rejectInvitationFromEmail(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 listOrganizationRoles: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ListOrganizationRoleRequest();

   request.setOrganizationId(data.organization_id);

   this.client.listActiveOrganizationRoles(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 updateUserRole: function (data) {
  return new Promise((resolve, reject) => {
   let request = new UpdateOrganizationRoleRequest();

   request.setRole(data.role);
   request.setUserId(data.user_id);
   request.setOrganizationId(data.organization_id);

   this.client.updateOrganizationRole(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 acceptInternalInvitation: function (data) {
  return new Promise((resolve, reject) => {
   let request = new OrganizationRoleId();

   request.setUserId(data.user_id);
   request.setOrganizationId(data.organization_id);

   this.client.acceptInvitationToOrganization(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 rejectInternalInvitation: function (data) {
  return new Promise((resolve, reject) => {
   let request = new RejectInvitationToOrganizationRequest();

   request.setUserId(data.user_id);
   request.setOrganizationId(data.organization_id);
   request.setMessage(data.message);

   this.client.rejectInvitationToOrganization(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },
};
