import {
 LoginRequest,
 RegisterRequest,
 VerifyEmailRequest,
 ResendEmailVerificationRequest,
 ForgotUserPasswordRequest,
 ResetUserPasswordRequest,
 ChangeUserPasswordRequest,
 UpdateUserProfileRequest,
 Empty,
 SignupFromInvitationRequest,
 SigninViaGoogleRequest,
} from "../protobufs/auth_pb";

import { responseInterceptor, requestInterceptor, getUserToken, getService } from "./Interceptor";

export default {
 client: getService("AUTH"),

 getApiVersion: function () {
  return new Promise((resolve, reject) => {
   let request = new Empty();
   this.client.getApiVersion(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* REGISTER A NEW USER */
 register: function (data) {
  return new Promise((resolve, reject) => {
   let request = new RegisterRequest();
   request.setEmail(data.email);
   request.setFirstName(data.first_name);
   request.setLastName(data.last_name);
   request.setPassword(data.password);
   this.client.register(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* LOGIN A USER */
 login: function (data) {
  return new Promise((resolve, reject) => {
   let request = new LoginRequest();
   request.setEmail(data.email);
   request.setPassword(data.password);
   this.client.login(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* CONFIRM EMAIL */
 confirmEmail: function (data) {
  return new Promise((resolve, reject) => {
   let request = new VerifyEmailRequest();
   request.setToken(data.token);
   request.setUidb64(data.uidb64);
   this.client.verifyEmail(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* RESEND EMAIL VERIFICATION */
 resendEmailVerfication: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ResendEmailVerificationRequest();
   request.setEmail(data.email);
   this.client.resendEmailVerification(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* FORGOT USER PASSWORD */
 forgotUserPassword: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ForgotUserPasswordRequest();
   request.setEmail(data.email);
   this.client.forgotUserPassword(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* RESET USER PASSWORD */
 resetUserPassword: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ResetUserPasswordRequest();
   request.setToken(data.token);
   request.setUidb64(data.uidb64);
   request.setPassword(data.password);
   this.client.resetUserPassword(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* CHANGE USER PASSWORD  */
 changeUserPassword: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ChangeUserPasswordRequest();
   request.setNewPassword(data.newPassword);
   request.setExistingPassword(data.existingPassword);
   this.client.changeUserPassword(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* UPDATE USER PROFILE */
 updateuserProfile: function (data) {
  return new Promise((resolve, reject) => {
   let request = new UpdateUserProfileRequest();
   request.setFirstName(data.first_name);
   request.setLastName(data.last_name);
   this.client.updateUserProfile(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 registerUsingInvitationEmail(data) {
  return new Promise((resolve, reject) => {
   let request = new SignupFromInvitationRequest();

   request.setFirstName(data.first_name);
   request.setLastName(data.last_name);
   request.setToken(data.token);
   request.setUidb64(data.uidb);
   request.setPassword(data.password);

   this.client.signupFromOrganizationInvitation(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 signupFromProjectInvitation(data) {
  return new Promise((resolve, reject) => {
   let request = new SignupFromInvitationRequest();

   request.setFirstName(data.first_name);
   request.setLastName(data.last_name);
   request.setToken(data.token);
   request.setUidb64(data.uidb);
   request.setPassword(data.password);

   this.client.signupFromProjectInvitation(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 signupFromTaskInvitation(data) {
  return new Promise((resolve, reject) => {
   let request = new SignupFromInvitationRequest();

   request.setFirstName(data.first_name);
   request.setLastName(data.last_name);
   request.setToken(data.token);
   request.setUidb64(data.uidb);
   request.setPassword(data.password);

   this.client.signupFromTaskInvitation(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 authenticateUsingGoogle(data) {
  return new Promise((resolve, reject) => {
   let request = new SigninViaGoogleRequest();

   request.setIdtoken(data.id_token);

   this.client.signinViaGoogle(requestInterceptor(request), {}, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },
};
