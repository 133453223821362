<template>
  <v-menu offset-y v-model="menu" :menu-props="{ offsetY: true, right: true }">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        depressed
        text
        small
        class="py-4"
      >
        {{
          $i18n.locale === "ur"
            ? $t("application.urCode")
            : $t("application.enCode")
        }}
        <v-icon>{{ !menu ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in langs"
        :key="index"
        dense
        @click="() => changeLanguage(item)"
        class="cursor-pointer d-flex justify-center"
      >
        <div class="font-x-small font-weight-medium">
          {{ item.label }}
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    menu: false,
  }),
  computed: {
    langs() {
      return [
        { label: this.$t("application.english"), code: "en" },
        { label: this.$t("application.urdu"), code: "ur" },
      ];
    },
  },
  methods: {
    changeLanguage(language) {
      this.$store.commit("changeLanguage", language.code);
    },
  },
};
</script>

<style scoped>
.lang:hover {
  font-weight: 500;
}
</style>