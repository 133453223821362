import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
 theme: {
  dark: true,
  options: {
   customProperties: true,
  },
  themes: {
   light: {
    primary: "#8155FF",
    primaryText: "#FFF",
    secondaryText: "#000",
    primaryLink: "#000",
    primaryInput: "#F5F6FF",
    backgroundPrimary: "#FBFAFF",
    backgroundSecondary: "#FFFFFF",
    backlog: "#000",
    inProgress: "#B69CFF",
    inReview: "#E9B80E",
    done: "#0EA842",
    primaryDialogColor: "#FFFFF",
   },

   dark: {
    primary: "#B69CFF",
    primaryText: "#000",
    secondaryText: "#FFF",
    primaryLink: "#8F8F8F",
    primaryInput: "#1A1C1E",
    backgroundPrimary: "#0E0E0E",
    backgroundSecondary: "#121212",
    backlog: "#fff",
    inProgress: "#B69CFF",
    inReview: "#E9B80E",
    done: "#0EA842",
    primaryDark: "#21212d",
    primaryDialogColor: "#16181B",
   },
  },
 },
});
