import { getField, updateField } from "vuex-map-fields";
import { removeData } from "@/services/localService";
import { vm } from "@/main";
import AuthService from "@/services/authService";

export default {
 namespaced: true,
 state: () => ({
  username: "",
  password: "",
  resendEmail: "",
 }),
 mutations: {
  updateField,
  setState(state, payload) {
   state[payload.key] = payload.value;
  },
 },
 actions: {
  //
  /* 
      This action will be called 
      when the user will login  
    */
  //
  async login({ state, commit, dispatch }, new_user = false) {
   try {
    commit("startLoading", null, { root: true });
    const response = await AuthService.login({ email: state.username, password: state.password });

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    const { user } = response;

    const userObj = {
     entity_id: user.entityId,
     first_name: user.firstName,
     last_name: user.lastName,
     email: user.email,
     token: user.accessToken,
     verified: user.verified,
    };

    /* 
          Dispatch action of main store which will 
          save the user in state as well as local storage 
        */
    dispatch("setUserState", userObj, { root: true });

    /* If it is not anew user then 
        push to home page */
    if (!new_user) {
     return vm.$router.push({ path: "/home/organisations" });
    }

    /* If it is a new user then it will return true 
        and the caller will handle further logic */
    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  //
  /* 
      This action will be called when 
      when user will click logout button 
    */
  //
  async logout({ state, commit, dispatch }) {
   try {
    removeData("user");
    removeData("loggedIn");
    commit("setState", { key: "isUserloggedIn", value: false }, { root: true });
    commit("projects/setState", { key: "createProjectDialog", value: false }, { root: true });
    commit("organisations/setState", { key: "allOrganisations", value: [] }, { root: true });
    vm.$router.push({ path: "/login" });
   } catch (e) {
    console.log(e);
   } finally {
   }
  },

  //
  /* 
      This action will be called when user will 
      click on the link sent to user in email after 
      redirection.
    */
  //
  async verifyEmail({ commit, dispatch }, payload) {
   try {
    commit("startLoading", null, { root: true });
    const response = await AuthService.confirmEmail(payload);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

    const { user } = response;

    /* Account already verified */
    if (!user) {
     return vm.$router.push({ path: "/home" });
    }

    const userObj = {
     entity_id: user.entityId,
     first_name: user.firstName,
     last_name: user.lastName,
     email: user.email,
     token: user.accessToken,
     verified: user.verified,
    };

    /* 
          Dispatch action of main store which will 
          save the user in state as well as local storage 
        */
    dispatch("setUserState", userObj, { root: true });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async authenticateUsingGoogle({ commit, dispatch }, payload) {
   try {
    commit("startLoading", null, { root: true });
    const response = await AuthService.authenticateUsingGoogle(payload);

    console.log(response);
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },
 },
 getters: {
  getField,
 },
};
