<template>
  <v-navigation-drawer permanent color="backgroundSecondary" width="200px" class="px-4" :mini-variant="!drawer">
    <template #prepend>
      <section :class="['d-flex', drawer ? 'justify-space-between' : 'justify-center', 'align-center', 'mt-7']">
        <div class="font-weight-bold" v-if="drawer">
          <span>{{ $t("application.fractal") }}</span>
          <span class="primary--text">{{ $t("application.pm") }}</span>
        </div>

        <v-icon small color="primaryLink" :class="['cursor-pointer']" @click="drawer = !drawer"> mdi-page-layout-sidebar-left </v-icon>
      </section>
    </template>

    <user-avatar class="mt-8" :compact="false" :text="userInitials" :fullName="userFullName" />

    <router-links class="mt-6" />

    <template #append>
      <div :class="['d-flex', 'align-center', 'cursor-pointer', !drawer ? 'justify-center' : '']" @click="logout">
        <v-icon small color="primaryLink">mdi-logout</v-icon>
        <small v-if="drawer" :class="['ps-1 primaryLink--text', drawer ? 'fade-in' : '']">{{ $t("common.logout") }}</small>
      </div>

      <template></template>
      <div class="d-flex justify-center my-9">
        <theme-toggler />
      </div>

      <div class="d-flex justify-center">
        <app-version centered />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import AppVersion from "../Shared/AppVersion.vue";
import ThemeToggler from "../Shared/ThemeToggler.vue";
import AppLogo from "../Shared/AppLogo.vue";
import LanguageToggler from "../Shared/LanguageToggler.vue";
import UserAvatar from "./UserAvatar.vue";
import RouterLinks from "./RouterLinks.vue";

import { mapFields } from "vuex-map-fields";

export default {
  components: { AppVersion, ThemeToggler, AppLogo, LanguageToggler, UserAvatar, RouterLinks },

  data: () => ({}),

  computed: {
    ...mapFields({
      drawer: "drawer",
      environment: "environment",
    }),

    userInformation() {
      return this.$store.getters.userInformation;
    },

    userInitials() {
      const initials = this.userInformation.first_name[0] + this.userInformation.last_name[0];
      if (initials) {
        return initials.toUpperCase();
      }
      return "XX";
    },

    userFullName() {
      const fullName = this.userInformation.first_name + " " + this.userInformation.last_name;
      return fullName;
    },
  },

  methods: {
    navigate(link) {
      this.$router.push({ path: link.to }).catch(() => console.warn("."));
    },

    toggleDrawer() {
      this.$store.commit("setState", {
        key: "drawer",
        value: !this.drawer,
      });
    },

    logout() {
      this.$store.dispatch("login/logout");
    },
  },
};
</script>
