import {
 ListTasksInProjectRequest,
 ListTaskEstimatesRequest,
 ListTasksForAssignedToUserRequest,
 EntityId,
 TaskUpdateRequest,
 TaskInsertRequest,
 TaskEstimateInsertRequest,
 TaskRecurrenceInsertRequest,
 UserTaskId,
 RejectInvitationToTaskRequest,
 TaskRecurrenceId,
 ListTasksRequest,
 Filter,
 Search,
 TaskPriorityListCreateRequest,
 TaskPriority,
 TaskManagerId,
 UpdateTaskStatusRequest,
 ChangeProjectRequest,
 DeleteTaskPriorityInTaskRequest,
 ProjectTaskSlotListInsertRequest,
 TaskProjectId,
 TaskCommentInsertRequest,
 TaskId,
 TaskCommentUpdateRequest,
 TaskCommentReplyRequest
} from "../protobufs/task_pb";

import { responseInterceptor, requestInterceptor, getUserToken, getService } from "./Interceptor";

import { AttachmentCreateRequest } from "../protobufs/attachment_pb";

import { ReplyToInvitationFromEmailRequest } from "../protobufs/organization_pb";

export default {
 client: getService("TASK"),

 getTasks: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ListTasksInProjectRequest();

   request.setProjectId(data.project_id);

   this.client.listTasksInProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 createTask: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskInsertRequest();

   request.setTitle(data.title);
   request.setDescription(data.description);
   request.setDueDate(data.dueDate);
   request.setAssignedTo(data.assignedTo);
   request.setProjectId(data.projectId);
   request.setTaskNumber(data.taskNumber);
   request.setEstimate(data.estimate);
   request.setPredecessorsList(data.predecessors);
   request.setSuccessorsList(data.successors);

   /* IF RECURRENCE IS SELECTED - THEN ADD IT TO THE REQUEST */
   if (data.recurrence && data.recurrence.type) {
    let recurrenceData = new TaskRecurrenceInsertRequest();

    if (data.recurrence.type === "every_day") {
     recurrenceData.setRepeatDay(data.recurrence.repeatDay);
     recurrenceData.setRepeatMonth(data.recurrence.repeatMonth);
     recurrenceData.setRepeatWeekday(data.recurrence.repeatWeekDay);
    }

    if (data.recurrence.type === "every_week_day") {
     recurrenceData.setRepeatDay(data.recurrence.repeatDay);
     recurrenceData.setRepeatMonth(data.recurrence.repeatMonth);
     recurrenceData.setRepeatWeekday(data.recurrence.repeatWeekDay);
    }

    if (data.recurrence.type === "every_n_days") {
     recurrenceData.setRepeatDay(data.recurrence.repeatDay);
     recurrenceData.setRepeatMonth(data.recurrence.repeatMonth);
     recurrenceData.setRepeatWeekday(data.recurrence.repeatWeekDay);
     recurrenceData.setRepeatInterval(data.recurrence.repeatInterval);
    }

    if (data.recurrence.type === "every_week_on") {
     recurrenceData.setRepeatDay(data.recurrence.repeatDay);
     recurrenceData.setRepeatMonth(data.recurrence.repeatMonth);
     recurrenceData.setRepeatWeekday(data.recurrence.repeatWeekDay);
    }

    if (data.recurrence.type === "every_month_on") {
     recurrenceData.setRepeatDay(data.recurrence.repeatDay);
     recurrenceData.setRepeatMonth(data.recurrence.repeatMonth);
     recurrenceData.setRepeatWeekday(data.recurrence.repeatWeekDay);
    }

    if (data.recurrence.type === "every_year_on") {
     recurrenceData.setRepeatDay(data.recurrence.repeatDay);
     recurrenceData.setRepeatWeekday(data.recurrence.repeatWeekDay);
     recurrenceData.setRepeatMonth(data.recurrence.repeatMonth);
    }

    recurrenceData.setRepeatText(data.recurrence.repeatText);

    request.setRecurrence(recurrenceData);
   }

   /* IF ATTACHMENTS IS SELECTED - THEN ADD IT TO THE REQUEST */
   if (data.attachments && data.attachments.length) {
    let allAttachments = [];

    data.attachments.forEach((el) => {
     let attachmentsRequest = new AttachmentCreateRequest();
     attachmentsRequest.setFilename(el.filename);
     attachmentsRequest.setUrl(el.url);
     attachmentsRequest.setUploadId(el.upload_id);
     attachmentsRequest.setMimeType(el.mime_type);
     allAttachments.push(attachmentsRequest);
    });

    request.setAttachmentsList(allAttachments);
   }

   this.client.createTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 updateTask: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskUpdateRequest();

   request.setTitle(data?.title);
   request.setDescription(data?.description);
   request.setEntityId(data.entityId);
   request.setDueDate(data?.dueDate);
   request.setStatus(data?.status);
   request.setPredecessorsList(data?.predecessorsList);
   request.setSuccessorsList(data?.successorsList);

   request.setAssignedTo(data.assignee && data.assignee.email ? data.assignee.email : null);

   this.client.updateTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 updateTaskStatus: function (data) {
  return new Promise((resolve, reject) => {
   let request = new UpdateTaskStatusRequest();

   request.setEntityId(data.entityId);
   request.setStatus(data.status);

   this.client.updateTaskStatus(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 getTaskDetails: function (data) {
  return new Promise((resolve, reject) => {
   let request = new EntityId();

   request.setEntityId(data.entityId);

   this.client.getTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 deleteTask: function (data) {
  return new Promise((resolve, reject) => {
   let request = new EntityId();

   request.setEntityId(data.entityId);

   this.client.deleteTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 getAllTasksForUser: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ListTasksForAssignedToUserRequest();

   request.setAssignedTo(data.userId);

   this.client.listTasksForAssignedToUser(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 getTaskEstimates: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ListTaskEstimatesRequest();

   request.setTaskId(data.taskId);
   request.setUserId(data.userId);

   this.client.listEstimatesInTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 addEstimateToTask: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskEstimateInsertRequest();

   request.setEstimatedTime(data.estimatedTime);
   request.setTaskId(data.taskId);
   request.setUserId(data.userId);

   this.client.addUserEstimateInTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 rejectTaskInvitationFromEmail: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ReplyToInvitationFromEmailRequest();

   request.setToken(data.token);
   request.setUidb64(data.uidb);
   request.setMessage(data.message);

   this.client.rejectTaskInvitationFromEmail(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 acceptTaskInvitationFromEmail: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ReplyToInvitationFromEmailRequest();

   request.setToken(data.token);
   request.setUidb64(data.uidb);

   this.client.acceptTaskInvitationFromEmail(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 acceptTaskInvitationFromNotification: function (data) {
  return new Promise((resolve, reject) => {
   let request = new UserTaskId();

   request.setTaskId(data.taskId);
   request.setUserId(data.userId);

   this.client.acceptInvitationToTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 rejectTaskInvitationFromNotification: function (data) {
  return new Promise((resolve, reject) => {
   let request = new RejectInvitationToTaskRequest();

   request.setTaskId(data.taskId);
   request.setUserId(data.userId);
   request.setMessage(data.message);

   this.client.rejectInvitationToTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 getRecurrenceInTask: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskRecurrenceId();

   request.setTaskId(data.taskId);

   this.client.getRecurrenceInTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 getAllTasksInBacklog: function (filters) {
  return new Promise((resolve, reject) => {
   let request = new ListTasksRequest();

   let filter = new Filter();
   filter.setProjectIdsList(filters.projectIds);
   filter.setUserIdsList(filters.userIds);
   filter.setDueDate(filters.dueDate);
   filter.setOrganizationIdsList(filters.organizationIds);
   filter.setManagedTask(filters.getManagedTasks);
   filter.setAssignedTask(filters.getAssignedTasks);

   let search = new Search();
   search.setSearchText(filters.searchText);

   request.setSearch(search);
   request.setFilter(filter);

   this.client.listTasksInBacklog(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 getAllTasksForManager: function (payload) {
  const { managerId, filters } = payload;
  return new Promise((resolve, reject) => {
   let request = new ListTasksRequest();

   request.setManagerId(managerId);

   let filter = new Filter();
   filter.setProjectIdsList(filters.projectIds);
   filter.setUserIdsList(filters.userIds);
   filter.setDueDate(filters.dueDate);
   filter.setOrganizationIdsList(filters.organizationIds);
   filter.setManagedTask(filters.getManagedTasks);
   filter.setAssignedTask(filters.getAssignedTasks);

   let search = new Search();
   search.setSearchText(filters.searchText);

   request.setSearch(search);
   request.setFilter(filter);

   this.client.listTasksInBacklogForManager(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 createPriorityList: function (taskList) {
  return new Promise((resolve, reject) => {
   let request = new TaskPriorityListCreateRequest();

   const priorityList = [];

   taskList.forEach((task) => {
    let taskPriority = new TaskPriority();

    taskPriority.setFromTaskId(task.from_task_id);
    taskPriority.setToTaskId(task.to_task_id);
    taskPriority.setTaskId(task.task_id);

    priorityList.push(taskPriority);
   });

   request.setPriorityList(priorityList);

   this.client.createTaskPriority(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 updatePriorityList: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskPriorityListCreateRequest();

   const priorityList = [];

   data.taskList.forEach((task) => {
    let taskPriority = new TaskPriority();

    taskPriority.setFromTaskId(task.from_task_id);
    taskPriority.setToTaskId(task.to_task_id);
    taskPriority.setTaskId(task.task_id);

    priorityList.push(taskPriority);
   });

   console.log("setting manager id", data.managerId);

   request.setManagerId(data.managerId);
   request.setPriorityList(priorityList);

   this.client.updateTaskPriority(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 getPriorityList: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskManagerId();

   request.setManagerId(data.managerId);

   this.client.getTaskPriorityForManager(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 changeProject: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ChangeProjectRequest();

   request.setEntityId(data.entityId);
   request.setProjectId(data.projectId);
   request.setTaskNumber(data.taskNumber);

   this.client.changeProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 deletePriorityForManager: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskManagerId();

   request.setManagerId(data.managerId);

   this.client.deleteTaskPriorityForManager(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 deleteTaskFromPriority: function (data) {
  return new Promise((resolve, reject) => {
   let request = new DeleteTaskPriorityInTaskRequest();

   request.setManagerId(data.managerId);
   request.setTaskId(data.taskId);

   this.client.deleteTaskPriorityInTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 createProjectPriorityList: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ProjectTaskSlotListInsertRequest();

   request.setProjectId(data.projectId);
   request.setSlotsList(data.tasksList);

   this.client.createProjectTaskSlotList(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 getProjectPriorityList: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskProjectId();

   request.setProjectId(data.projectId);

   this.client.getProjectTaskSlotList(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 getTaskComments: function (data) {
  return new Promise((resolve, reject) => {
    let request = new TaskId()

    request.setTaskId(data.taskId);

    this.client.getTaskComments(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
    });
  });
 },

 createTaskComment: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskCommentInsertRequest();

   request.setText(data.text);
   request.setTaskId(data.taskId);
   request.setUsersMentionedList(data.usersMentioned);

   this.client.createTaskComment(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 deleteTaskComment: function (data) {
  return new Promise((resolve, reject) => {
    let request = new EntityId()

    request.setEntityId(data.commentId);

    this.client.deleteTaskComment(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
    });
  });
 },

 updateTaskComment: function (data) {
  return new Promise((resolve, reject) => {
    let request = new TaskCommentUpdateRequest()

    request.setEntityId(data.commentId);
    request.setText(data.text);

    this.client.updateTaskComment(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
    });
  });
 },

 createCommentReply: function (data) {
  return new Promise((resolve, reject) => {
    let request = new TaskCommentReplyRequest()

    request.setTaskId(data.taskId);
    request.setParentId(data.parentId);
    request.setText(data.text);

    this.client.replyForTaskComment(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
    });
  });
 },

 archiveTask: function (data) {
  return new Promise((resolve, reject) => {
   let request = new EntityId();

   request.setEntityId(data.entityId);

   this.client.archiveTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 unArchiveTask: function (data) {
  return new Promise((resolve, reject) => {
   let request = new EntityId();

   request.setEntityId(data.entityId);

   this.client.unarchiveTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 listArchiveTasks: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ListTasksInProjectRequest();

   request.setProjectId(data.projectId);

   this.client.listArchivedTasksInProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },
};
