import {  
  ListNotificationsRequest,
  NotificationId
} from '../protobufs/notification_pb';

import { 
  responseInterceptor, 
  requestInterceptor,
  getUserToken,
  getService
} from './Interceptor';

export default {
  client: getService('NOTIFICATION'),

  getNotifications: function () {
    return new Promise((resolve, reject) => {
      let request = new ListNotificationsRequest();

      this.client.listNotifications(
        requestInterceptor(request),
        { accesstoken: getUserToken() },
        (err, response) => {
          if (err) reject(responseInterceptor(err));
          resolve(responseInterceptor(response));
        }
      )
    })
  },

  deleteNotification: function (entity_id) {
    console.log(entity_id);
    return new Promise((resolve, reject) => {
      let request = new NotificationId();
      
      request.setEntityId(entity_id);

      this.client.deleteNotification(
        requestInterceptor(request),
        { accesstoken: getUserToken() },
        (err, response) => {
          if (err) reject(responseInterceptor(err));
          resolve(responseInterceptor(response));
        }
      )
    })
  },
}