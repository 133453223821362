import {
 ProjectAttachmentsCreateRequest,
 ProjectAttachmentId,
 ListProjectAttachmentsRequest,
 AttachmentCreateRequest,
 TaskAttachmentsCreateRequest,
 ListTaskAttachmentsRequest,
 TaskAttachmentId,
} from "../protobufs/attachment_pb";

import { responseInterceptor, requestInterceptor, getUserToken, getService } from "./Interceptor";

export default {
 client: getService("ATTACHMENT"),

 /* This will add attachments for the project */
 addAttachmentToProject: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ProjectAttachmentsCreateRequest();

   let allAttachments = [];

   data.attachments.forEach((el) => {
    let attachmentsRequest = new AttachmentCreateRequest();
    attachmentsRequest.setFilename(el.filename);
    attachmentsRequest.setUrl(el.url);
    attachmentsRequest.setUploadId(el.upload_id);
    attachmentsRequest.setMimeType(el.mime_type);
    allAttachments.push(attachmentsRequest);
   });

   request.setAttachmentsList(allAttachments);
   request.setProjectId(data.project_id);

   this.client.addAttachmentsToProject(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* This will get all the attachments uploaded for the project */
 getAllProjectAttachments: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ListProjectAttachmentsRequest();

   request.setProjectId(data.project_id);

   this.client.listProjectAttachments(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* This will get one attachment uploaded for the project */
 getSingleAttachmentForProject: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ProjectAttachmentId();

   request.setEntityId(data.entity_id);
   request.setProjectId(data.project_id);

   this.client.getProjectAttachment(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* This will delete the project attachment */
 deleteProjectAttachment: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ProjectAttachmentId();

   request.setEntityId(data.entityId);
   request.setProjectId(data.projectId);

   this.client.deleteProjectAttachment(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* This will add attachments for the task */
 addAttachmentToTask: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskAttachmentsCreateRequest();

   let allAttachments = [];

   data.attachments.forEach((el) => {
    let attachmentsRequest = new AttachmentCreateRequest();
    attachmentsRequest.setFilename(el.filename);
    attachmentsRequest.setUrl(el.url);
    attachmentsRequest.setUploadId(el.upload_id);
    attachmentsRequest.setMimeType(el.mime_type);
    allAttachments.push(attachmentsRequest);
   });

   request.setAttachmentsList(allAttachments);
   request.setTaskId(data.task_id);

   this.client.addAttachmentsToTask(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 /* This will get all the attachments uploaded for the project */
 getAllTaskAttachments: function (data) {
  return new Promise((resolve, reject) => {
   let request = new ListTaskAttachmentsRequest();

   request.setTaskId(data.task_id);

   this.client.listTaskAttachments(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },

 deleteTaskAttachment: function (data) {
  return new Promise((resolve, reject) => {
   let request = new TaskAttachmentId();

   request.setEntityId(data.entityId);
   request.setTaskId(data.taskId);

   this.client.deleteTaskAttachment(requestInterceptor(request), { accesstoken: getUserToken() }, (err, response) => {
    if (err) reject(responseInterceptor(err));
    resolve(responseInterceptor(response));
   });
  });
 },
};
