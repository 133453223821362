// source: task.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var attachment_pb = require('./attachment_pb.js');
goog.object.extend(proto, attachment_pb);
var organization_pb = require('./organization_pb.js');
goog.object.extend(proto, organization_pb);
var timesheet_pb = require('./timesheet_pb.js');
goog.object.extend(proto, timesheet_pb);
goog.exportSymbol('proto.ChangeProjectRequest', null, global);
goog.exportSymbol('proto.DeleteTaskPriorityInTaskRequest', null, global);
goog.exportSymbol('proto.EntityId', null, global);
goog.exportSymbol('proto.Filter', null, global);
goog.exportSymbol('proto.GenericProjectTaskSlotListResponse', null, global);
goog.exportSymbol('proto.GenericTaskCommentResponse', null, global);
goog.exportSymbol('proto.GenericTaskEstimateResponse', null, global);
goog.exportSymbol('proto.GenericTaskPriorityListResponse', null, global);
goog.exportSymbol('proto.GenericTaskRecurrenceResponse', null, global);
goog.exportSymbol('proto.GenericTaskResponse', null, global);
goog.exportSymbol('proto.GenericTaskSlotListResponse', null, global);
goog.exportSymbol('proto.ListTaskEstimatesRequest', null, global);
goog.exportSymbol('proto.ListTaskEstimatesResponse', null, global);
goog.exportSymbol('proto.ListTasksByReporterRequest', null, global);
goog.exportSymbol('proto.ListTasksForAssignedToUserRequest', null, global);
goog.exportSymbol('proto.ListTasksInProjectRequest', null, global);
goog.exportSymbol('proto.ListTasksRequest', null, global);
goog.exportSymbol('proto.ListTasksResponse', null, global);
goog.exportSymbol('proto.ProjectTaskSlotList', null, global);
goog.exportSymbol('proto.ProjectTaskSlotListInsertRequest', null, global);
goog.exportSymbol('proto.RejectInvitationToTaskRequest', null, global);
goog.exportSymbol('proto.Search', null, global);
goog.exportSymbol('proto.Task', null, global);
goog.exportSymbol('proto.TaskComment', null, global);
goog.exportSymbol('proto.TaskCommentInsertRequest', null, global);
goog.exportSymbol('proto.TaskCommentListResponse', null, global);
goog.exportSymbol('proto.TaskCommentReplyRequest', null, global);
goog.exportSymbol('proto.TaskCommentUpdateRequest', null, global);
goog.exportSymbol('proto.TaskEstimate', null, global);
goog.exportSymbol('proto.TaskEstimateInsertRequest', null, global);
goog.exportSymbol('proto.TaskId', null, global);
goog.exportSymbol('proto.TaskInsertRequest', null, global);
goog.exportSymbol('proto.TaskManagerId', null, global);
goog.exportSymbol('proto.TaskPriority', null, global);
goog.exportSymbol('proto.TaskPriorityInsertSchema', null, global);
goog.exportSymbol('proto.TaskPriorityList', null, global);
goog.exportSymbol('proto.TaskPriorityListCreateRequest', null, global);
goog.exportSymbol('proto.TaskProjectId', null, global);
goog.exportSymbol('proto.TaskRecurrence', null, global);
goog.exportSymbol('proto.TaskRecurrenceId', null, global);
goog.exportSymbol('proto.TaskRecurrenceInsertRequest', null, global);
goog.exportSymbol('proto.TaskRecurrenceUpdateRequest', null, global);
goog.exportSymbol('proto.TaskResourceId', null, global);
goog.exportSymbol('proto.TaskSlotList', null, global);
goog.exportSymbol('proto.TaskSlotListInsertRequest', null, global);
goog.exportSymbol('proto.TaskUpdateRequest', null, global);
goog.exportSymbol('proto.UpdateAssigneeRequest', null, global);
goog.exportSymbol('proto.UpdateTaskStatusRequest', null, global);
goog.exportSymbol('proto.UserDetail', null, global);
goog.exportSymbol('proto.UserTaskId', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserDetail.displayName = 'proto.UserDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskComment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskComment.repeatedFields_, null);
};
goog.inherits(proto.TaskComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskComment.displayName = 'proto.TaskComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskId.displayName = 'proto.TaskId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskCommentInsertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskCommentInsertRequest.repeatedFields_, null);
};
goog.inherits(proto.TaskCommentInsertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskCommentInsertRequest.displayName = 'proto.TaskCommentInsertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskCommentReplyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskCommentReplyRequest.repeatedFields_, null);
};
goog.inherits(proto.TaskCommentReplyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskCommentReplyRequest.displayName = 'proto.TaskCommentReplyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskCommentUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskCommentUpdateRequest.repeatedFields_, null);
};
goog.inherits(proto.TaskCommentUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskCommentUpdateRequest.displayName = 'proto.TaskCommentUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GenericTaskCommentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GenericTaskCommentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GenericTaskCommentResponse.displayName = 'proto.GenericTaskCommentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskCommentListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskCommentListResponse.repeatedFields_, null);
};
goog.inherits(proto.TaskCommentListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskCommentListResponse.displayName = 'proto.TaskCommentListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskSlotList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskSlotList.repeatedFields_, null);
};
goog.inherits(proto.TaskSlotList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskSlotList.displayName = 'proto.TaskSlotList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskSlotListInsertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskSlotListInsertRequest.repeatedFields_, null);
};
goog.inherits(proto.TaskSlotListInsertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskSlotListInsertRequest.displayName = 'proto.TaskSlotListInsertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProjectTaskSlotListInsertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProjectTaskSlotListInsertRequest.repeatedFields_, null);
};
goog.inherits(proto.ProjectTaskSlotListInsertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProjectTaskSlotListInsertRequest.displayName = 'proto.ProjectTaskSlotListInsertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProjectTaskSlotList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProjectTaskSlotList.repeatedFields_, null);
};
goog.inherits(proto.ProjectTaskSlotList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProjectTaskSlotList.displayName = 'proto.ProjectTaskSlotList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskProjectId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskProjectId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskProjectId.displayName = 'proto.TaskProjectId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GenericProjectTaskSlotListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GenericProjectTaskSlotListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GenericProjectTaskSlotListResponse.displayName = 'proto.GenericProjectTaskSlotListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskResourceId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskResourceId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskResourceId.displayName = 'proto.TaskResourceId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GenericTaskSlotListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GenericTaskSlotListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GenericTaskSlotListResponse.displayName = 'proto.GenericTaskSlotListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskPriority = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskPriority, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskPriority.displayName = 'proto.TaskPriority';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskPriorityInsertSchema = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskPriorityInsertSchema, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskPriorityInsertSchema.displayName = 'proto.TaskPriorityInsertSchema';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskPriorityListCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskPriorityListCreateRequest.repeatedFields_, null);
};
goog.inherits(proto.TaskPriorityListCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskPriorityListCreateRequest.displayName = 'proto.TaskPriorityListCreateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskPriorityList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskPriorityList.repeatedFields_, null);
};
goog.inherits(proto.TaskPriorityList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskPriorityList.displayName = 'proto.TaskPriorityList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GenericTaskPriorityListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GenericTaskPriorityListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GenericTaskPriorityListResponse.displayName = 'proto.GenericTaskPriorityListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Task.repeatedFields_, null);
};
goog.inherits(proto.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Task.displayName = 'proto.Task';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskEstimate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskEstimate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskEstimate.displayName = 'proto.TaskEstimate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskEstimateInsertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskEstimateInsertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskEstimateInsertRequest.displayName = 'proto.TaskEstimateInsertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskInsertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskInsertRequest.repeatedFields_, null);
};
goog.inherits(proto.TaskInsertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskInsertRequest.displayName = 'proto.TaskInsertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaskUpdateRequest.repeatedFields_, null);
};
goog.inherits(proto.TaskUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskUpdateRequest.displayName = 'proto.TaskUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskRecurrence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskRecurrence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskRecurrence.displayName = 'proto.TaskRecurrence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EntityId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EntityId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EntityId.displayName = 'proto.EntityId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskManagerId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskManagerId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskManagerId.displayName = 'proto.TaskManagerId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTasksInProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListTasksInProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTasksInProjectRequest.displayName = 'proto.ListTasksInProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Filter.repeatedFields_, null);
};
goog.inherits(proto.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Filter.displayName = 'proto.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Search = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Search, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Search.displayName = 'proto.Search';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTasksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListTasksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTasksRequest.displayName = 'proto.ListTasksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTasksByReporterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListTasksByReporterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTasksByReporterRequest.displayName = 'proto.ListTasksByReporterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTasksForAssignedToUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListTasksForAssignedToUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTasksForAssignedToUserRequest.displayName = 'proto.ListTasksForAssignedToUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GenericTaskResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GenericTaskResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GenericTaskResponse.displayName = 'proto.GenericTaskResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTasksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListTasksResponse.repeatedFields_, null);
};
goog.inherits(proto.ListTasksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTasksResponse.displayName = 'proto.ListTasksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GenericTaskEstimateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GenericTaskEstimateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GenericTaskEstimateResponse.displayName = 'proto.GenericTaskEstimateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserTaskId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserTaskId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserTaskId.displayName = 'proto.UserTaskId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTaskEstimatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListTaskEstimatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTaskEstimatesRequest.displayName = 'proto.ListTaskEstimatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListTaskEstimatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListTaskEstimatesResponse.repeatedFields_, null);
};
goog.inherits(proto.ListTaskEstimatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListTaskEstimatesResponse.displayName = 'proto.ListTaskEstimatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskRecurrenceInsertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskRecurrenceInsertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskRecurrenceInsertRequest.displayName = 'proto.TaskRecurrenceInsertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskRecurrenceUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskRecurrenceUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskRecurrenceUpdateRequest.displayName = 'proto.TaskRecurrenceUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GenericTaskRecurrenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GenericTaskRecurrenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GenericTaskRecurrenceResponse.displayName = 'proto.GenericTaskRecurrenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaskRecurrenceId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaskRecurrenceId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaskRecurrenceId.displayName = 'proto.TaskRecurrenceId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateAssigneeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateAssigneeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateAssigneeRequest.displayName = 'proto.UpdateAssigneeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RejectInvitationToTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RejectInvitationToTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RejectInvitationToTaskRequest.displayName = 'proto.RejectInvitationToTaskRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateTaskStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateTaskStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateTaskStatusRequest.displayName = 'proto.UpdateTaskStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangeProjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangeProjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangeProjectRequest.displayName = 'proto.ChangeProjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteTaskPriorityInTaskRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteTaskPriorityInTaskRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteTaskPriorityInTaskRequest.displayName = 'proto.DeleteTaskPriorityInTaskRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.UserDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    verified: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserDetail}
 */
proto.UserDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserDetail;
  return proto.UserDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserDetail}
 */
proto.UserDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.UserDetail.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserDetail} returns this
 */
proto.UserDetail.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.UserDetail.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserDetail} returns this
 */
proto.UserDetail.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.UserDetail.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserDetail} returns this
 */
proto.UserDetail.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.UserDetail.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserDetail} returns this
 */
proto.UserDetail.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool verified = 5;
 * @return {boolean}
 */
proto.UserDetail.prototype.getVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserDetail} returns this
 */
proto.UserDetail.prototype.setVerified = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.UserDetail} returns this
 */
proto.UserDetail.prototype.clearVerified = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDetail.prototype.hasVerified = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskComment.repeatedFields_ = [9,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskComment.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskComment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskComment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskComment.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    taskName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    commentedOn: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedOn: jspb.Message.getFieldWithDefault(msg, 7, 0),
    parentId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    usersMentionedList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    entityId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    version: jspb.Message.getFieldWithDefault(msg, 11, ""),
    changedOn: jspb.Message.getFieldWithDefault(msg, 12, ""),
    changedById: jspb.Message.getFieldWithDefault(msg, 13, ""),
    previousVersion: jspb.Message.getFieldWithDefault(msg, 14, ""),
    repliesList: jspb.Message.toObjectList(msg.getRepliesList(),
    proto.TaskComment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskComment}
 */
proto.TaskComment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskComment;
  return proto.TaskComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskComment}
 */
proto.TaskComment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCommentedOn(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedOn(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsersMentioned(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedOn(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedById(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousVersion(value);
      break;
    case 15:
      var value = new proto.TaskComment;
      reader.readMessage(value,proto.TaskComment.deserializeBinaryFromReader);
      msg.addReplies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskComment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskComment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskComment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTaskName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCommentedOn();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedOn();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUsersMentionedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getChangedOn();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getChangedById();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPreviousVersion();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getRepliesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.TaskComment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.TaskComment.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task_id = 2;
 * @return {string}
 */
proto.TaskComment.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.TaskComment.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string task_name = 4;
 * @return {string}
 */
proto.TaskComment.prototype.getTaskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setTaskName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_name = 5;
 * @return {string}
 */
proto.TaskComment.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 commented_on = 6;
 * @return {number}
 */
proto.TaskComment.prototype.getCommentedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setCommentedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updated_on = 7;
 * @return {number}
 */
proto.TaskComment.prototype.getUpdatedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setUpdatedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string parent_id = 8;
 * @return {string}
 */
proto.TaskComment.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskComment.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string users_mentioned = 9;
 * @return {!Array<string>}
 */
proto.TaskComment.prototype.getUsersMentionedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setUsersMentionedList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.addUsersMentioned = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.clearUsersMentionedList = function() {
  return this.setUsersMentionedList([]);
};


/**
 * optional string entity_id = 10;
 * @return {string}
 */
proto.TaskComment.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string version = 11;
 * @return {string}
 */
proto.TaskComment.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string changed_on = 12;
 * @return {string}
 */
proto.TaskComment.prototype.getChangedOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setChangedOn = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string changed_by_id = 13;
 * @return {string}
 */
proto.TaskComment.prototype.getChangedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setChangedById = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string previous_version = 14;
 * @return {string}
 */
proto.TaskComment.prototype.getPreviousVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.setPreviousVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated TaskComment replies = 15;
 * @return {!Array<!proto.TaskComment>}
 */
proto.TaskComment.prototype.getRepliesList = function() {
  return /** @type{!Array<!proto.TaskComment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TaskComment, 15));
};


/**
 * @param {!Array<!proto.TaskComment>} value
 * @return {!proto.TaskComment} returns this
*/
proto.TaskComment.prototype.setRepliesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.TaskComment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TaskComment}
 */
proto.TaskComment.prototype.addReplies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.TaskComment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskComment} returns this
 */
proto.TaskComment.prototype.clearRepliesList = function() {
  return this.setRepliesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskId.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskId.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskId}
 */
proto.TaskId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskId;
  return proto.TaskId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskId}
 */
proto.TaskId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.TaskId.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskId} returns this
 */
proto.TaskId.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskCommentInsertRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskCommentInsertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskCommentInsertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskCommentInsertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskCommentInsertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usersMentionedList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskCommentInsertRequest}
 */
proto.TaskCommentInsertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskCommentInsertRequest;
  return proto.TaskCommentInsertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskCommentInsertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskCommentInsertRequest}
 */
proto.TaskCommentInsertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsersMentioned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskCommentInsertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskCommentInsertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskCommentInsertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskCommentInsertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsersMentionedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.TaskCommentInsertRequest.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskCommentInsertRequest} returns this
 */
proto.TaskCommentInsertRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.TaskCommentInsertRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskCommentInsertRequest} returns this
 */
proto.TaskCommentInsertRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string users_mentioned = 3;
 * @return {!Array<string>}
 */
proto.TaskCommentInsertRequest.prototype.getUsersMentionedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskCommentInsertRequest} returns this
 */
proto.TaskCommentInsertRequest.prototype.setUsersMentionedList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskCommentInsertRequest} returns this
 */
proto.TaskCommentInsertRequest.prototype.addUsersMentioned = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskCommentInsertRequest} returns this
 */
proto.TaskCommentInsertRequest.prototype.clearUsersMentionedList = function() {
  return this.setUsersMentionedList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskCommentReplyRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskCommentReplyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskCommentReplyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskCommentReplyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskCommentReplyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    usersMentionedList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskCommentReplyRequest}
 */
proto.TaskCommentReplyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskCommentReplyRequest;
  return proto.TaskCommentReplyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskCommentReplyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskCommentReplyRequest}
 */
proto.TaskCommentReplyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsersMentioned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskCommentReplyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskCommentReplyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskCommentReplyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskCommentReplyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsersMentionedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.TaskCommentReplyRequest.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskCommentReplyRequest} returns this
 */
proto.TaskCommentReplyRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string parent_id = 2;
 * @return {string}
 */
proto.TaskCommentReplyRequest.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskCommentReplyRequest} returns this
 */
proto.TaskCommentReplyRequest.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.TaskCommentReplyRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskCommentReplyRequest} returns this
 */
proto.TaskCommentReplyRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string users_mentioned = 4;
 * @return {!Array<string>}
 */
proto.TaskCommentReplyRequest.prototype.getUsersMentionedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskCommentReplyRequest} returns this
 */
proto.TaskCommentReplyRequest.prototype.setUsersMentionedList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskCommentReplyRequest} returns this
 */
proto.TaskCommentReplyRequest.prototype.addUsersMentioned = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskCommentReplyRequest} returns this
 */
proto.TaskCommentReplyRequest.prototype.clearUsersMentionedList = function() {
  return this.setUsersMentionedList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskCommentUpdateRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskCommentUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskCommentUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskCommentUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskCommentUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usersMentionedList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskCommentUpdateRequest}
 */
proto.TaskCommentUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskCommentUpdateRequest;
  return proto.TaskCommentUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskCommentUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskCommentUpdateRequest}
 */
proto.TaskCommentUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsersMentioned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskCommentUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskCommentUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskCommentUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskCommentUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsersMentionedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.TaskCommentUpdateRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskCommentUpdateRequest} returns this
 */
proto.TaskCommentUpdateRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.TaskCommentUpdateRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskCommentUpdateRequest} returns this
 */
proto.TaskCommentUpdateRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string users_mentioned = 3;
 * @return {!Array<string>}
 */
proto.TaskCommentUpdateRequest.prototype.getUsersMentionedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskCommentUpdateRequest} returns this
 */
proto.TaskCommentUpdateRequest.prototype.setUsersMentionedList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskCommentUpdateRequest} returns this
 */
proto.TaskCommentUpdateRequest.prototype.addUsersMentioned = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskCommentUpdateRequest} returns this
 */
proto.TaskCommentUpdateRequest.prototype.clearUsersMentionedList = function() {
  return this.setUsersMentionedList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GenericTaskCommentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GenericTaskCommentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GenericTaskCommentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskCommentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taskComment: (f = msg.getTaskComment()) && proto.TaskComment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GenericTaskCommentResponse}
 */
proto.GenericTaskCommentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GenericTaskCommentResponse;
  return proto.GenericTaskCommentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GenericTaskCommentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GenericTaskCommentResponse}
 */
proto.GenericTaskCommentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.TaskComment;
      reader.readMessage(value,proto.TaskComment.deserializeBinaryFromReader);
      msg.setTaskComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GenericTaskCommentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GenericTaskCommentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GenericTaskCommentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskCommentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaskComment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TaskComment.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.GenericTaskCommentResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GenericTaskCommentResponse} returns this
 */
proto.GenericTaskCommentResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.GenericTaskCommentResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GenericTaskCommentResponse} returns this
 */
proto.GenericTaskCommentResponse.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.GenericTaskCommentResponse} returns this
 */
proto.GenericTaskCommentResponse.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskCommentResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TaskComment task_comment = 3;
 * @return {?proto.TaskComment}
 */
proto.GenericTaskCommentResponse.prototype.getTaskComment = function() {
  return /** @type{?proto.TaskComment} */ (
    jspb.Message.getWrapperField(this, proto.TaskComment, 3));
};


/**
 * @param {?proto.TaskComment|undefined} value
 * @return {!proto.GenericTaskCommentResponse} returns this
*/
proto.GenericTaskCommentResponse.prototype.setTaskComment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GenericTaskCommentResponse} returns this
 */
proto.GenericTaskCommentResponse.prototype.clearTaskComment = function() {
  return this.setTaskComment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskCommentResponse.prototype.hasTaskComment = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskCommentListResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskCommentListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskCommentListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskCommentListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskCommentListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taskCommentsList: jspb.Message.toObjectList(msg.getTaskCommentsList(),
    proto.TaskComment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskCommentListResponse}
 */
proto.TaskCommentListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskCommentListResponse;
  return proto.TaskCommentListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskCommentListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskCommentListResponse}
 */
proto.TaskCommentListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.TaskComment;
      reader.readMessage(value,proto.TaskComment.deserializeBinaryFromReader);
      msg.addTaskComments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskCommentListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskCommentListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskCommentListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskCommentListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaskCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.TaskComment.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.TaskCommentListResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TaskCommentListResponse} returns this
 */
proto.TaskCommentListResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.TaskCommentListResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskCommentListResponse} returns this
 */
proto.TaskCommentListResponse.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskCommentListResponse} returns this
 */
proto.TaskCommentListResponse.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskCommentListResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated TaskComment task_comments = 3;
 * @return {!Array<!proto.TaskComment>}
 */
proto.TaskCommentListResponse.prototype.getTaskCommentsList = function() {
  return /** @type{!Array<!proto.TaskComment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TaskComment, 3));
};


/**
 * @param {!Array<!proto.TaskComment>} value
 * @return {!proto.TaskCommentListResponse} returns this
*/
proto.TaskCommentListResponse.prototype.setTaskCommentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.TaskComment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TaskComment}
 */
proto.TaskCommentListResponse.prototype.addTaskComments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.TaskComment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskCommentListResponse} returns this
 */
proto.TaskCommentListResponse.prototype.clearTaskCommentsList = function() {
  return this.setTaskCommentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskSlotList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskSlotList.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskSlotList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskSlotList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskSlotList.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slotsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    entityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, ""),
    changedOn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    changedById: jspb.Message.getFieldWithDefault(msg, 6, ""),
    previousVersion: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskSlotList}
 */
proto.TaskSlotList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskSlotList;
  return proto.TaskSlotList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskSlotList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskSlotList}
 */
proto.TaskSlotList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSlots(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedOn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedById(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskSlotList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskSlotList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskSlotList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskSlotList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlotsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChangedOn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChangedById();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPreviousVersion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string resource_id = 1;
 * @return {string}
 */
proto.TaskSlotList.prototype.getResourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskSlotList} returns this
 */
proto.TaskSlotList.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string slots = 2;
 * @return {!Array<string>}
 */
proto.TaskSlotList.prototype.getSlotsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskSlotList} returns this
 */
proto.TaskSlotList.prototype.setSlotsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskSlotList} returns this
 */
proto.TaskSlotList.prototype.addSlots = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskSlotList} returns this
 */
proto.TaskSlotList.prototype.clearSlotsList = function() {
  return this.setSlotsList([]);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.TaskSlotList.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskSlotList} returns this
 */
proto.TaskSlotList.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string version = 4;
 * @return {string}
 */
proto.TaskSlotList.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskSlotList} returns this
 */
proto.TaskSlotList.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string changed_on = 5;
 * @return {string}
 */
proto.TaskSlotList.prototype.getChangedOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskSlotList} returns this
 */
proto.TaskSlotList.prototype.setChangedOn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string changed_by_id = 6;
 * @return {string}
 */
proto.TaskSlotList.prototype.getChangedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskSlotList} returns this
 */
proto.TaskSlotList.prototype.setChangedById = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string previous_version = 7;
 * @return {string}
 */
proto.TaskSlotList.prototype.getPreviousVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskSlotList} returns this
 */
proto.TaskSlotList.prototype.setPreviousVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskSlotListInsertRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskSlotListInsertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskSlotListInsertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskSlotListInsertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskSlotListInsertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slotsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskSlotListInsertRequest}
 */
proto.TaskSlotListInsertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskSlotListInsertRequest;
  return proto.TaskSlotListInsertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskSlotListInsertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskSlotListInsertRequest}
 */
proto.TaskSlotListInsertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSlots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskSlotListInsertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskSlotListInsertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskSlotListInsertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskSlotListInsertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlotsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string resource_id = 1;
 * @return {string}
 */
proto.TaskSlotListInsertRequest.prototype.getResourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskSlotListInsertRequest} returns this
 */
proto.TaskSlotListInsertRequest.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string slots = 2;
 * @return {!Array<string>}
 */
proto.TaskSlotListInsertRequest.prototype.getSlotsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskSlotListInsertRequest} returns this
 */
proto.TaskSlotListInsertRequest.prototype.setSlotsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskSlotListInsertRequest} returns this
 */
proto.TaskSlotListInsertRequest.prototype.addSlots = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskSlotListInsertRequest} returns this
 */
proto.TaskSlotListInsertRequest.prototype.clearSlotsList = function() {
  return this.setSlotsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProjectTaskSlotListInsertRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProjectTaskSlotListInsertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ProjectTaskSlotListInsertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProjectTaskSlotListInsertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProjectTaskSlotListInsertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slotsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProjectTaskSlotListInsertRequest}
 */
proto.ProjectTaskSlotListInsertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProjectTaskSlotListInsertRequest;
  return proto.ProjectTaskSlotListInsertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProjectTaskSlotListInsertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProjectTaskSlotListInsertRequest}
 */
proto.ProjectTaskSlotListInsertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSlots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProjectTaskSlotListInsertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProjectTaskSlotListInsertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProjectTaskSlotListInsertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProjectTaskSlotListInsertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlotsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.ProjectTaskSlotListInsertRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProjectTaskSlotListInsertRequest} returns this
 */
proto.ProjectTaskSlotListInsertRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string slots = 2;
 * @return {!Array<string>}
 */
proto.ProjectTaskSlotListInsertRequest.prototype.getSlotsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ProjectTaskSlotListInsertRequest} returns this
 */
proto.ProjectTaskSlotListInsertRequest.prototype.setSlotsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ProjectTaskSlotListInsertRequest} returns this
 */
proto.ProjectTaskSlotListInsertRequest.prototype.addSlots = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProjectTaskSlotListInsertRequest} returns this
 */
proto.ProjectTaskSlotListInsertRequest.prototype.clearSlotsList = function() {
  return this.setSlotsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProjectTaskSlotList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProjectTaskSlotList.prototype.toObject = function(opt_includeInstance) {
  return proto.ProjectTaskSlotList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProjectTaskSlotList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProjectTaskSlotList.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slotsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    entityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, ""),
    changedOn: jspb.Message.getFieldWithDefault(msg, 5, ""),
    changedById: jspb.Message.getFieldWithDefault(msg, 6, ""),
    previousVersion: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProjectTaskSlotList}
 */
proto.ProjectTaskSlotList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProjectTaskSlotList;
  return proto.ProjectTaskSlotList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProjectTaskSlotList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProjectTaskSlotList}
 */
proto.ProjectTaskSlotList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSlots(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedOn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedById(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProjectTaskSlotList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProjectTaskSlotList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProjectTaskSlotList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProjectTaskSlotList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlotsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChangedOn();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChangedById();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPreviousVersion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.ProjectTaskSlotList.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProjectTaskSlotList} returns this
 */
proto.ProjectTaskSlotList.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string slots = 2;
 * @return {!Array<string>}
 */
proto.ProjectTaskSlotList.prototype.getSlotsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ProjectTaskSlotList} returns this
 */
proto.ProjectTaskSlotList.prototype.setSlotsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ProjectTaskSlotList} returns this
 */
proto.ProjectTaskSlotList.prototype.addSlots = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProjectTaskSlotList} returns this
 */
proto.ProjectTaskSlotList.prototype.clearSlotsList = function() {
  return this.setSlotsList([]);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.ProjectTaskSlotList.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProjectTaskSlotList} returns this
 */
proto.ProjectTaskSlotList.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string version = 4;
 * @return {string}
 */
proto.ProjectTaskSlotList.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProjectTaskSlotList} returns this
 */
proto.ProjectTaskSlotList.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string changed_on = 5;
 * @return {string}
 */
proto.ProjectTaskSlotList.prototype.getChangedOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProjectTaskSlotList} returns this
 */
proto.ProjectTaskSlotList.prototype.setChangedOn = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string changed_by_id = 6;
 * @return {string}
 */
proto.ProjectTaskSlotList.prototype.getChangedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProjectTaskSlotList} returns this
 */
proto.ProjectTaskSlotList.prototype.setChangedById = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string previous_version = 7;
 * @return {string}
 */
proto.ProjectTaskSlotList.prototype.getPreviousVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProjectTaskSlotList} returns this
 */
proto.ProjectTaskSlotList.prototype.setPreviousVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskProjectId.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskProjectId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskProjectId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskProjectId.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskProjectId}
 */
proto.TaskProjectId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskProjectId;
  return proto.TaskProjectId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskProjectId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskProjectId}
 */
proto.TaskProjectId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskProjectId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskProjectId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskProjectId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskProjectId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.TaskProjectId.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskProjectId} returns this
 */
proto.TaskProjectId.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GenericProjectTaskSlotListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GenericProjectTaskSlotListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GenericProjectTaskSlotListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericProjectTaskSlotListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    projectTaskSlotList: (f = msg.getProjectTaskSlotList()) && proto.ProjectTaskSlotList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GenericProjectTaskSlotListResponse}
 */
proto.GenericProjectTaskSlotListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GenericProjectTaskSlotListResponse;
  return proto.GenericProjectTaskSlotListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GenericProjectTaskSlotListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GenericProjectTaskSlotListResponse}
 */
proto.GenericProjectTaskSlotListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.ProjectTaskSlotList;
      reader.readMessage(value,proto.ProjectTaskSlotList.deserializeBinaryFromReader);
      msg.setProjectTaskSlotList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GenericProjectTaskSlotListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GenericProjectTaskSlotListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GenericProjectTaskSlotListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericProjectTaskSlotListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProjectTaskSlotList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ProjectTaskSlotList.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.GenericProjectTaskSlotListResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GenericProjectTaskSlotListResponse} returns this
 */
proto.GenericProjectTaskSlotListResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.GenericProjectTaskSlotListResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GenericProjectTaskSlotListResponse} returns this
 */
proto.GenericProjectTaskSlotListResponse.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.GenericProjectTaskSlotListResponse} returns this
 */
proto.GenericProjectTaskSlotListResponse.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericProjectTaskSlotListResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProjectTaskSlotList project_task_slot_list = 3;
 * @return {?proto.ProjectTaskSlotList}
 */
proto.GenericProjectTaskSlotListResponse.prototype.getProjectTaskSlotList = function() {
  return /** @type{?proto.ProjectTaskSlotList} */ (
    jspb.Message.getWrapperField(this, proto.ProjectTaskSlotList, 3));
};


/**
 * @param {?proto.ProjectTaskSlotList|undefined} value
 * @return {!proto.GenericProjectTaskSlotListResponse} returns this
*/
proto.GenericProjectTaskSlotListResponse.prototype.setProjectTaskSlotList = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GenericProjectTaskSlotListResponse} returns this
 */
proto.GenericProjectTaskSlotListResponse.prototype.clearProjectTaskSlotList = function() {
  return this.setProjectTaskSlotList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericProjectTaskSlotListResponse.prototype.hasProjectTaskSlotList = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskResourceId.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskResourceId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskResourceId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskResourceId.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskResourceId}
 */
proto.TaskResourceId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskResourceId;
  return proto.TaskResourceId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskResourceId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskResourceId}
 */
proto.TaskResourceId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskResourceId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskResourceId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskResourceId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskResourceId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string resource_id = 1;
 * @return {string}
 */
proto.TaskResourceId.prototype.getResourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskResourceId} returns this
 */
proto.TaskResourceId.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GenericTaskSlotListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GenericTaskSlotListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GenericTaskSlotListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskSlotListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taskSlotList: (f = msg.getTaskSlotList()) && proto.TaskSlotList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GenericTaskSlotListResponse}
 */
proto.GenericTaskSlotListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GenericTaskSlotListResponse;
  return proto.GenericTaskSlotListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GenericTaskSlotListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GenericTaskSlotListResponse}
 */
proto.GenericTaskSlotListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.TaskSlotList;
      reader.readMessage(value,proto.TaskSlotList.deserializeBinaryFromReader);
      msg.setTaskSlotList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GenericTaskSlotListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GenericTaskSlotListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GenericTaskSlotListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskSlotListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaskSlotList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TaskSlotList.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.GenericTaskSlotListResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GenericTaskSlotListResponse} returns this
 */
proto.GenericTaskSlotListResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.GenericTaskSlotListResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GenericTaskSlotListResponse} returns this
 */
proto.GenericTaskSlotListResponse.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.GenericTaskSlotListResponse} returns this
 */
proto.GenericTaskSlotListResponse.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskSlotListResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TaskSlotList task_slot_list = 3;
 * @return {?proto.TaskSlotList}
 */
proto.GenericTaskSlotListResponse.prototype.getTaskSlotList = function() {
  return /** @type{?proto.TaskSlotList} */ (
    jspb.Message.getWrapperField(this, proto.TaskSlotList, 3));
};


/**
 * @param {?proto.TaskSlotList|undefined} value
 * @return {!proto.GenericTaskSlotListResponse} returns this
*/
proto.GenericTaskSlotListResponse.prototype.setTaskSlotList = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GenericTaskSlotListResponse} returns this
 */
proto.GenericTaskSlotListResponse.prototype.clearTaskSlotList = function() {
  return this.setTaskSlotList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskSlotListResponse.prototype.hasTaskSlotList = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskPriority.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskPriority.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskPriority} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskPriority.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromTaskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    toTaskId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    managerId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    version: jspb.Message.getFieldWithDefault(msg, 6, ""),
    changedOn: jspb.Message.getFieldWithDefault(msg, 7, ""),
    changedById: jspb.Message.getFieldWithDefault(msg, 8, ""),
    previousVersion: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskPriority}
 */
proto.TaskPriority.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskPriority;
  return proto.TaskPriority.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskPriority} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskPriority}
 */
proto.TaskPriority.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToTaskId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedOn(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedById(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskPriority.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskPriority.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskPriority} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskPriority.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToTaskId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getManagerId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getChangedOn();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getChangedById();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPreviousVersion();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string from_task_id = 1;
 * @return {string}
 */
proto.TaskPriority.prototype.getFromTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriority} returns this
 */
proto.TaskPriority.prototype.setFromTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to_task_id = 2;
 * @return {string}
 */
proto.TaskPriority.prototype.getToTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriority} returns this
 */
proto.TaskPriority.prototype.setToTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string task_id = 3;
 * @return {string}
 */
proto.TaskPriority.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriority} returns this
 */
proto.TaskPriority.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string entity_id = 4;
 * @return {string}
 */
proto.TaskPriority.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriority} returns this
 */
proto.TaskPriority.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string manager_id = 5;
 * @return {string}
 */
proto.TaskPriority.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriority} returns this
 */
proto.TaskPriority.prototype.setManagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string version = 6;
 * @return {string}
 */
proto.TaskPriority.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriority} returns this
 */
proto.TaskPriority.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string changed_on = 7;
 * @return {string}
 */
proto.TaskPriority.prototype.getChangedOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriority} returns this
 */
proto.TaskPriority.prototype.setChangedOn = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string changed_by_id = 8;
 * @return {string}
 */
proto.TaskPriority.prototype.getChangedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriority} returns this
 */
proto.TaskPriority.prototype.setChangedById = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string previous_version = 9;
 * @return {string}
 */
proto.TaskPriority.prototype.getPreviousVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriority} returns this
 */
proto.TaskPriority.prototype.setPreviousVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskPriorityInsertSchema.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskPriorityInsertSchema.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskPriorityInsertSchema} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskPriorityInsertSchema.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromTaskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    toTaskId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskPriorityInsertSchema}
 */
proto.TaskPriorityInsertSchema.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskPriorityInsertSchema;
  return proto.TaskPriorityInsertSchema.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskPriorityInsertSchema} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskPriorityInsertSchema}
 */
proto.TaskPriorityInsertSchema.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToTaskId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskPriorityInsertSchema.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskPriorityInsertSchema.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskPriorityInsertSchema} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskPriorityInsertSchema.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToTaskId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string from_task_id = 1;
 * @return {string}
 */
proto.TaskPriorityInsertSchema.prototype.getFromTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriorityInsertSchema} returns this
 */
proto.TaskPriorityInsertSchema.prototype.setFromTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to_task_id = 2;
 * @return {string}
 */
proto.TaskPriorityInsertSchema.prototype.getToTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriorityInsertSchema} returns this
 */
proto.TaskPriorityInsertSchema.prototype.setToTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string task_id = 3;
 * @return {string}
 */
proto.TaskPriorityInsertSchema.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriorityInsertSchema} returns this
 */
proto.TaskPriorityInsertSchema.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskPriorityListCreateRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskPriorityListCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskPriorityListCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskPriorityListCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskPriorityListCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    priorityList: jspb.Message.toObjectList(msg.getPriorityList(),
    proto.TaskPriorityInsertSchema.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskPriorityListCreateRequest}
 */
proto.TaskPriorityListCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskPriorityListCreateRequest;
  return proto.TaskPriorityListCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskPriorityListCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskPriorityListCreateRequest}
 */
proto.TaskPriorityListCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    case 2:
      var value = new proto.TaskPriorityInsertSchema;
      reader.readMessage(value,proto.TaskPriorityInsertSchema.deserializeBinaryFromReader);
      msg.addPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskPriorityListCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskPriorityListCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskPriorityListCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskPriorityListCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPriorityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.TaskPriorityInsertSchema.serializeBinaryToWriter
    );
  }
};


/**
 * optional string manager_id = 1;
 * @return {string}
 */
proto.TaskPriorityListCreateRequest.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriorityListCreateRequest} returns this
 */
proto.TaskPriorityListCreateRequest.prototype.setManagerId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskPriorityListCreateRequest} returns this
 */
proto.TaskPriorityListCreateRequest.prototype.clearManagerId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskPriorityListCreateRequest.prototype.hasManagerId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated TaskPriorityInsertSchema priority = 2;
 * @return {!Array<!proto.TaskPriorityInsertSchema>}
 */
proto.TaskPriorityListCreateRequest.prototype.getPriorityList = function() {
  return /** @type{!Array<!proto.TaskPriorityInsertSchema>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TaskPriorityInsertSchema, 2));
};


/**
 * @param {!Array<!proto.TaskPriorityInsertSchema>} value
 * @return {!proto.TaskPriorityListCreateRequest} returns this
*/
proto.TaskPriorityListCreateRequest.prototype.setPriorityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.TaskPriorityInsertSchema=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TaskPriorityInsertSchema}
 */
proto.TaskPriorityListCreateRequest.prototype.addPriority = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.TaskPriorityInsertSchema, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskPriorityListCreateRequest} returns this
 */
proto.TaskPriorityListCreateRequest.prototype.clearPriorityList = function() {
  return this.setPriorityList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskPriorityList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskPriorityList.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskPriorityList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskPriorityList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskPriorityList.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    priorityList: jspb.Message.toObjectList(msg.getPriorityList(),
    proto.TaskPriority.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskPriorityList}
 */
proto.TaskPriorityList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskPriorityList;
  return proto.TaskPriorityList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskPriorityList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskPriorityList}
 */
proto.TaskPriorityList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    case 2:
      var value = new proto.TaskPriority;
      reader.readMessage(value,proto.TaskPriority.deserializeBinaryFromReader);
      msg.addPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskPriorityList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskPriorityList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskPriorityList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskPriorityList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPriorityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.TaskPriority.serializeBinaryToWriter
    );
  }
};


/**
 * optional string manager_id = 1;
 * @return {string}
 */
proto.TaskPriorityList.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskPriorityList} returns this
 */
proto.TaskPriorityList.prototype.setManagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated TaskPriority priority = 2;
 * @return {!Array<!proto.TaskPriority>}
 */
proto.TaskPriorityList.prototype.getPriorityList = function() {
  return /** @type{!Array<!proto.TaskPriority>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TaskPriority, 2));
};


/**
 * @param {!Array<!proto.TaskPriority>} value
 * @return {!proto.TaskPriorityList} returns this
*/
proto.TaskPriorityList.prototype.setPriorityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.TaskPriority=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TaskPriority}
 */
proto.TaskPriorityList.prototype.addPriority = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.TaskPriority, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskPriorityList} returns this
 */
proto.TaskPriorityList.prototype.clearPriorityList = function() {
  return this.setPriorityList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GenericTaskPriorityListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GenericTaskPriorityListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GenericTaskPriorityListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskPriorityListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taskPriorityList: (f = msg.getTaskPriorityList()) && proto.TaskPriorityList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GenericTaskPriorityListResponse}
 */
proto.GenericTaskPriorityListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GenericTaskPriorityListResponse;
  return proto.GenericTaskPriorityListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GenericTaskPriorityListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GenericTaskPriorityListResponse}
 */
proto.GenericTaskPriorityListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.TaskPriorityList;
      reader.readMessage(value,proto.TaskPriorityList.deserializeBinaryFromReader);
      msg.setTaskPriorityList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GenericTaskPriorityListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GenericTaskPriorityListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GenericTaskPriorityListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskPriorityListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaskPriorityList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TaskPriorityList.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.GenericTaskPriorityListResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GenericTaskPriorityListResponse} returns this
 */
proto.GenericTaskPriorityListResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.GenericTaskPriorityListResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GenericTaskPriorityListResponse} returns this
 */
proto.GenericTaskPriorityListResponse.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.GenericTaskPriorityListResponse} returns this
 */
proto.GenericTaskPriorityListResponse.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskPriorityListResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TaskPriorityList task_priority_list = 3;
 * @return {?proto.TaskPriorityList}
 */
proto.GenericTaskPriorityListResponse.prototype.getTaskPriorityList = function() {
  return /** @type{?proto.TaskPriorityList} */ (
    jspb.Message.getWrapperField(this, proto.TaskPriorityList, 3));
};


/**
 * @param {?proto.TaskPriorityList|undefined} value
 * @return {!proto.GenericTaskPriorityListResponse} returns this
*/
proto.GenericTaskPriorityListResponse.prototype.setTaskPriorityList = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GenericTaskPriorityListResponse} returns this
 */
proto.GenericTaskPriorityListResponse.prototype.clearTaskPriorityList = function() {
  return this.setTaskPriorityList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskPriorityListResponse.prototype.hasTaskPriorityList = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Task.repeatedFields_ = [18,19,20,25,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    reporterId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    assignedBy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    taskNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    projectName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    organizationName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    isUserReporter: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    assignee: (f = msg.getAssignee()) && proto.UserDetail.toObject(includeInstance, f),
    reporter: (f = msg.getReporter()) && proto.UserDetail.toObject(includeInstance, f),
    predecessorsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    successorsList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    estimatesList: jspb.Message.toObjectList(msg.getEstimatesList(),
    proto.TaskEstimate.toObject, includeInstance),
    version: jspb.Message.getFieldWithDefault(msg, 21, ""),
    changedOn: jspb.Message.getFieldWithDefault(msg, 22, ""),
    changedById: jspb.Message.getFieldWithDefault(msg, 23, ""),
    previousVersion: jspb.Message.getFieldWithDefault(msg, 24, ""),
    usersMentionedList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    startDate: jspb.Message.getFieldWithDefault(msg, 26, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 27, ""),
    timesheetsList: jspb.Message.toObjectList(msg.getTimesheetsList(),
    timesheet_pb.TimesheetData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Task}
 */
proto.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Task;
  return proto.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Task}
 */
proto.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReporterId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedBy(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationName(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUserReporter(value);
      break;
    case 16:
      var value = new proto.UserDetail;
      reader.readMessage(value,proto.UserDetail.deserializeBinaryFromReader);
      msg.setAssignee(value);
      break;
    case 17:
      var value = new proto.UserDetail;
      reader.readMessage(value,proto.UserDetail.deserializeBinaryFromReader);
      msg.setReporter(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addPredecessors(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addSuccessors(value);
      break;
    case 20:
      var value = new proto.TaskEstimate;
      reader.readMessage(value,proto.TaskEstimate.deserializeBinaryFromReader);
      msg.addEstimates(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedOn(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedById(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousVersion(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsersMentioned(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 28:
      var value = new timesheet_pb.TimesheetData;
      reader.readMessage(value,timesheet_pb.TimesheetData.deserializeBinaryFromReader);
      msg.addTimesheets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReporterId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAssignedBy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTaskNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOrganizationName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIsUserReporter();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getAssignee();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.UserDetail.serializeBinaryToWriter
    );
  }
  f = message.getReporter();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.UserDetail.serializeBinaryToWriter
    );
  }
  f = message.getPredecessorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getSuccessorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getEstimatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.TaskEstimate.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getChangedOn();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getChangedById();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getPreviousVersion();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getUsersMentionedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getTimesheetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      timesheet_pb.TimesheetData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.Task.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.Task.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.Task.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string due_date = 4;
 * @return {string}
 */
proto.Task.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string assigned_to = 5;
 * @return {string}
 */
proto.Task.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string project_id = 6;
 * @return {string}
 */
proto.Task.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string reporter_id = 7;
 * @return {string}
 */
proto.Task.prototype.getReporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setReporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool archived = 8;
 * @return {boolean}
 */
proto.Task.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.Task.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string assigned_by = 10;
 * @return {string}
 */
proto.Task.prototype.getAssignedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setAssignedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string task_number = 11;
 * @return {string}
 */
proto.Task.prototype.getTaskNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setTaskNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string project_name = 12;
 * @return {string}
 */
proto.Task.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string organization_id = 13;
 * @return {string}
 */
proto.Task.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string organization_name = 14;
 * @return {string}
 */
proto.Task.prototype.getOrganizationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setOrganizationName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool is_user_reporter = 15;
 * @return {boolean}
 */
proto.Task.prototype.getIsUserReporter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setIsUserReporter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional UserDetail assignee = 16;
 * @return {?proto.UserDetail}
 */
proto.Task.prototype.getAssignee = function() {
  return /** @type{?proto.UserDetail} */ (
    jspb.Message.getWrapperField(this, proto.UserDetail, 16));
};


/**
 * @param {?proto.UserDetail|undefined} value
 * @return {!proto.Task} returns this
*/
proto.Task.prototype.setAssignee = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearAssignee = function() {
  return this.setAssignee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Task.prototype.hasAssignee = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional UserDetail reporter = 17;
 * @return {?proto.UserDetail}
 */
proto.Task.prototype.getReporter = function() {
  return /** @type{?proto.UserDetail} */ (
    jspb.Message.getWrapperField(this, proto.UserDetail, 17));
};


/**
 * @param {?proto.UserDetail|undefined} value
 * @return {!proto.Task} returns this
*/
proto.Task.prototype.setReporter = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearReporter = function() {
  return this.setReporter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Task.prototype.hasReporter = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated string predecessors = 18;
 * @return {!Array<string>}
 */
proto.Task.prototype.getPredecessorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setPredecessorsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.addPredecessors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearPredecessorsList = function() {
  return this.setPredecessorsList([]);
};


/**
 * repeated string successors = 19;
 * @return {!Array<string>}
 */
proto.Task.prototype.getSuccessorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setSuccessorsList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.addSuccessors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearSuccessorsList = function() {
  return this.setSuccessorsList([]);
};


/**
 * repeated TaskEstimate estimates = 20;
 * @return {!Array<!proto.TaskEstimate>}
 */
proto.Task.prototype.getEstimatesList = function() {
  return /** @type{!Array<!proto.TaskEstimate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TaskEstimate, 20));
};


/**
 * @param {!Array<!proto.TaskEstimate>} value
 * @return {!proto.Task} returns this
*/
proto.Task.prototype.setEstimatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.TaskEstimate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TaskEstimate}
 */
proto.Task.prototype.addEstimates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.TaskEstimate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearEstimatesList = function() {
  return this.setEstimatesList([]);
};


/**
 * optional string version = 21;
 * @return {string}
 */
proto.Task.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string changed_on = 22;
 * @return {string}
 */
proto.Task.prototype.getChangedOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setChangedOn = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string changed_by_id = 23;
 * @return {string}
 */
proto.Task.prototype.getChangedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setChangedById = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string previous_version = 24;
 * @return {string}
 */
proto.Task.prototype.getPreviousVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setPreviousVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated string users_mentioned = 25;
 * @return {!Array<string>}
 */
proto.Task.prototype.getUsersMentionedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setUsersMentionedList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.addUsersMentioned = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearUsersMentionedList = function() {
  return this.setUsersMentionedList([]);
};


/**
 * optional string start_date = 26;
 * @return {string}
 */
proto.Task.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string start_time = 27;
 * @return {string}
 */
proto.Task.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * repeated TimesheetData timesheets = 28;
 * @return {!Array<!proto.TimesheetData>}
 */
proto.Task.prototype.getTimesheetsList = function() {
  return /** @type{!Array<!proto.TimesheetData>} */ (
    jspb.Message.getRepeatedWrapperField(this, timesheet_pb.TimesheetData, 28));
};


/**
 * @param {!Array<!proto.TimesheetData>} value
 * @return {!proto.Task} returns this
*/
proto.Task.prototype.setTimesheetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.TimesheetData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TimesheetData}
 */
proto.Task.prototype.addTimesheets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.TimesheetData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Task} returns this
 */
proto.Task.prototype.clearTimesheetsList = function() {
  return this.setTimesheetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskEstimate.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskEstimate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskEstimate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskEstimate.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    estimateType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    taskName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    version: jspb.Message.getFieldWithDefault(msg, 8, ""),
    changedOn: jspb.Message.getFieldWithDefault(msg, 9, ""),
    changedById: jspb.Message.getFieldWithDefault(msg, 10, ""),
    previousVersion: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskEstimate}
 */
proto.TaskEstimate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskEstimate;
  return proto.TaskEstimate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskEstimate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskEstimate}
 */
proto.TaskEstimate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimateType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedOn(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedById(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskEstimate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskEstimate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskEstimate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskEstimate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEstimateType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getChangedOn();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getChangedById();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPreviousVersion();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.TaskEstimate.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.TaskEstimate.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string estimated_time = 3;
 * @return {string}
 */
proto.TaskEstimate.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string estimate_type = 4;
 * @return {string}
 */
proto.TaskEstimate.prototype.getEstimateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setEstimateType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string task_id = 5;
 * @return {string}
 */
proto.TaskEstimate.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_name = 6;
 * @return {string}
 */
proto.TaskEstimate.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setUserName = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.clearUserName = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskEstimate.prototype.hasUserName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string task_name = 7;
 * @return {string}
 */
proto.TaskEstimate.prototype.getTaskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setTaskName = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.clearTaskName = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskEstimate.prototype.hasTaskName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string version = 8;
 * @return {string}
 */
proto.TaskEstimate.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string changed_on = 9;
 * @return {string}
 */
proto.TaskEstimate.prototype.getChangedOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setChangedOn = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string changed_by_id = 10;
 * @return {string}
 */
proto.TaskEstimate.prototype.getChangedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setChangedById = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string previous_version = 11;
 * @return {string}
 */
proto.TaskEstimate.prototype.getPreviousVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimate} returns this
 */
proto.TaskEstimate.prototype.setPreviousVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskEstimateInsertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskEstimateInsertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskEstimateInsertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskEstimateInsertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    estimatedTime: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskEstimateInsertRequest}
 */
proto.TaskEstimateInsertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskEstimateInsertRequest;
  return proto.TaskEstimateInsertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskEstimateInsertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskEstimateInsertRequest}
 */
proto.TaskEstimateInsertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskEstimateInsertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskEstimateInsertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskEstimateInsertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskEstimateInsertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEstimatedTime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string estimated_time = 1;
 * @return {string}
 */
proto.TaskEstimateInsertRequest.prototype.getEstimatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimateInsertRequest} returns this
 */
proto.TaskEstimateInsertRequest.prototype.setEstimatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task_id = 2;
 * @return {string}
 */
proto.TaskEstimateInsertRequest.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimateInsertRequest} returns this
 */
proto.TaskEstimateInsertRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.TaskEstimateInsertRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskEstimateInsertRequest} returns this
 */
proto.TaskEstimateInsertRequest.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskEstimateInsertRequest} returns this
 */
proto.TaskEstimateInsertRequest.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskEstimateInsertRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskInsertRequest.repeatedFields_ = [9,10,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskInsertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskInsertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskInsertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskInsertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    taskNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    estimate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    recurrence: (f = msg.getRecurrence()) && proto.TaskRecurrenceInsertRequest.toObject(includeInstance, f),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    attachment_pb.AttachmentCreateRequest.toObject, includeInstance),
    predecessorsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    successorsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    usersMentionedList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    startDate: jspb.Message.getFieldWithDefault(msg, 13, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskInsertRequest}
 */
proto.TaskInsertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskInsertRequest;
  return proto.TaskInsertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskInsertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskInsertRequest}
 */
proto.TaskInsertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimate(value);
      break;
    case 8:
      var value = new proto.TaskRecurrenceInsertRequest;
      reader.readMessage(value,proto.TaskRecurrenceInsertRequest.deserializeBinaryFromReader);
      msg.setRecurrence(value);
      break;
    case 9:
      var value = new attachment_pb.AttachmentCreateRequest;
      reader.readMessage(value,attachment_pb.AttachmentCreateRequest.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addPredecessors(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addSuccessors(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsersMentioned(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskInsertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskInsertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskInsertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskInsertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTaskNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEstimate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRecurrence();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.TaskRecurrenceInsertRequest.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      attachment_pb.AttachmentCreateRequest.serializeBinaryToWriter
    );
  }
  f = message.getPredecessorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getSuccessorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getUsersMentionedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.TaskInsertRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.TaskInsertRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string due_date = 3;
 * @return {string}
 */
proto.TaskInsertRequest.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string assigned_to = 4;
 * @return {string}
 */
proto.TaskInsertRequest.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string project_id = 5;
 * @return {string}
 */
proto.TaskInsertRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string task_number = 6;
 * @return {string}
 */
proto.TaskInsertRequest.prototype.getTaskNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setTaskNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string estimate = 7;
 * @return {string}
 */
proto.TaskInsertRequest.prototype.getEstimate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setEstimate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional TaskRecurrenceInsertRequest recurrence = 8;
 * @return {?proto.TaskRecurrenceInsertRequest}
 */
proto.TaskInsertRequest.prototype.getRecurrence = function() {
  return /** @type{?proto.TaskRecurrenceInsertRequest} */ (
    jspb.Message.getWrapperField(this, proto.TaskRecurrenceInsertRequest, 8));
};


/**
 * @param {?proto.TaskRecurrenceInsertRequest|undefined} value
 * @return {!proto.TaskInsertRequest} returns this
*/
proto.TaskInsertRequest.prototype.setRecurrence = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.clearRecurrence = function() {
  return this.setRecurrence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskInsertRequest.prototype.hasRecurrence = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated AttachmentCreateRequest attachments = 9;
 * @return {!Array<!proto.AttachmentCreateRequest>}
 */
proto.TaskInsertRequest.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.AttachmentCreateRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, attachment_pb.AttachmentCreateRequest, 9));
};


/**
 * @param {!Array<!proto.AttachmentCreateRequest>} value
 * @return {!proto.TaskInsertRequest} returns this
*/
proto.TaskInsertRequest.prototype.setAttachmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.AttachmentCreateRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AttachmentCreateRequest}
 */
proto.TaskInsertRequest.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.AttachmentCreateRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.clearAttachmentsList = function() {
  return this.setAttachmentsList([]);
};


/**
 * repeated string predecessors = 10;
 * @return {!Array<string>}
 */
proto.TaskInsertRequest.prototype.getPredecessorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setPredecessorsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.addPredecessors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.clearPredecessorsList = function() {
  return this.setPredecessorsList([]);
};


/**
 * repeated string successors = 11;
 * @return {!Array<string>}
 */
proto.TaskInsertRequest.prototype.getSuccessorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setSuccessorsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.addSuccessors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.clearSuccessorsList = function() {
  return this.setSuccessorsList([]);
};


/**
 * repeated string users_mentioned = 12;
 * @return {!Array<string>}
 */
proto.TaskInsertRequest.prototype.getUsersMentionedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setUsersMentionedList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.addUsersMentioned = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.clearUsersMentionedList = function() {
  return this.setUsersMentionedList([]);
};


/**
 * optional string start_date = 13;
 * @return {string}
 */
proto.TaskInsertRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string start_time = 14;
 * @return {string}
 */
proto.TaskInsertRequest.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskInsertRequest} returns this
 */
proto.TaskInsertRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaskUpdateRequest.repeatedFields_ = [9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dueDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    assignedBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    predecessorsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    successorsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    usersMentionedList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    startDate: jspb.Message.getFieldWithDefault(msg, 12, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskUpdateRequest}
 */
proto.TaskUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskUpdateRequest;
  return proto.TaskUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskUpdateRequest}
 */
proto.TaskUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedBy(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addPredecessors(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addSuccessors(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsersMentioned(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDueDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPredecessorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getSuccessorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getUsersMentionedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.TaskUpdateRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.TaskUpdateRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.TaskUpdateRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string due_date = 4;
 * @return {string}
 */
proto.TaskUpdateRequest.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setDueDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string assigned_to = 5;
 * @return {string}
 */
proto.TaskUpdateRequest.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setAssignedTo = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.clearAssignedTo = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskUpdateRequest.prototype.hasAssignedTo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool archived = 6;
 * @return {boolean}
 */
proto.TaskUpdateRequest.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setArchived = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.clearArchived = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskUpdateRequest.prototype.hasArchived = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.TaskUpdateRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskUpdateRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string assigned_by = 8;
 * @return {string}
 */
proto.TaskUpdateRequest.prototype.getAssignedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setAssignedBy = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.clearAssignedBy = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskUpdateRequest.prototype.hasAssignedBy = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string predecessors = 9;
 * @return {!Array<string>}
 */
proto.TaskUpdateRequest.prototype.getPredecessorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setPredecessorsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.addPredecessors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.clearPredecessorsList = function() {
  return this.setPredecessorsList([]);
};


/**
 * repeated string successors = 10;
 * @return {!Array<string>}
 */
proto.TaskUpdateRequest.prototype.getSuccessorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setSuccessorsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.addSuccessors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.clearSuccessorsList = function() {
  return this.setSuccessorsList([]);
};


/**
 * repeated string users_mentioned = 11;
 * @return {!Array<string>}
 */
proto.TaskUpdateRequest.prototype.getUsersMentionedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setUsersMentionedList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.addUsersMentioned = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.clearUsersMentionedList = function() {
  return this.setUsersMentionedList([]);
};


/**
 * optional string start_date = 12;
 * @return {string}
 */
proto.TaskUpdateRequest.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string start_time = 13;
 * @return {string}
 */
proto.TaskUpdateRequest.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskUpdateRequest} returns this
 */
proto.TaskUpdateRequest.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskRecurrence.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskRecurrence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskRecurrence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskRecurrence.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    repeatStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    taskId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    repeatDay: jspb.Message.getFieldWithDefault(msg, 4, ""),
    repeatMonth: jspb.Message.getFieldWithDefault(msg, 5, ""),
    repeatWeekday: jspb.Message.getFieldWithDefault(msg, 6, ""),
    repeatWeek: jspb.Message.getFieldWithDefault(msg, 7, ""),
    repeatInterval: jspb.Message.getFieldWithDefault(msg, 8, ""),
    repeatText: jspb.Message.getFieldWithDefault(msg, 9, ""),
    version: jspb.Message.getFieldWithDefault(msg, 10, ""),
    changedOn: jspb.Message.getFieldWithDefault(msg, 11, ""),
    changedById: jspb.Message.getFieldWithDefault(msg, 12, ""),
    previousVersion: jspb.Message.getFieldWithDefault(msg, 13, ""),
    repeatEnd: jspb.Message.getFieldWithDefault(msg, 14, 0),
    numberOfOccurrence: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskRecurrence}
 */
proto.TaskRecurrence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskRecurrence;
  return proto.TaskRecurrence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskRecurrence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskRecurrence}
 */
proto.TaskRecurrence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepeatStart(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatDay(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatMonth(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatWeekday(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatWeek(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatInterval(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatText(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedOn(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedById(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousVersion(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepeatEnd(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfOccurrence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskRecurrence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskRecurrence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskRecurrence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskRecurrence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRepeatStart();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRepeatDay();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRepeatMonth();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRepeatWeekday();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRepeatWeek();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRepeatInterval();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRepeatText();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getChangedOn();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getChangedById();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPreviousVersion();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRepeatEnd();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getNumberOfOccurrence();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 repeat_start = 2;
 * @return {number}
 */
proto.TaskRecurrence.prototype.getRepeatStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setRepeatStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string task_id = 3;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string repeat_day = 4;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getRepeatDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setRepeatDay = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string repeat_month = 5;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getRepeatMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setRepeatMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string repeat_weekday = 6;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getRepeatWeekday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setRepeatWeekday = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string repeat_week = 7;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getRepeatWeek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setRepeatWeek = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string repeat_interval = 8;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getRepeatInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setRepeatInterval = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string repeat_text = 9;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getRepeatText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setRepeatText = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string version = 10;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string changed_on = 11;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getChangedOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setChangedOn = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string changed_by_id = 12;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getChangedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setChangedById = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string previous_version = 13;
 * @return {string}
 */
proto.TaskRecurrence.prototype.getPreviousVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setPreviousVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 repeat_end = 14;
 * @return {number}
 */
proto.TaskRecurrence.prototype.getRepeatEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setRepeatEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 number_of_occurrence = 15;
 * @return {number}
 */
proto.TaskRecurrence.prototype.getNumberOfOccurrence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskRecurrence} returns this
 */
proto.TaskRecurrence.prototype.setNumberOfOccurrence = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EntityId.prototype.toObject = function(opt_includeInstance) {
  return proto.EntityId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EntityId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EntityId.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EntityId}
 */
proto.EntityId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EntityId;
  return proto.EntityId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EntityId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EntityId}
 */
proto.EntityId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EntityId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EntityId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EntityId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EntityId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.EntityId.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EntityId} returns this
 */
proto.EntityId.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskManagerId.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskManagerId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskManagerId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskManagerId.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskManagerId}
 */
proto.TaskManagerId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskManagerId;
  return proto.TaskManagerId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskManagerId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskManagerId}
 */
proto.TaskManagerId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskManagerId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskManagerId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskManagerId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskManagerId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string manager_id = 1;
 * @return {string}
 */
proto.TaskManagerId.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskManagerId} returns this
 */
proto.TaskManagerId.prototype.setManagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTasksInProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTasksInProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTasksInProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTasksInProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && proto.Filter.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && proto.Search.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTasksInProjectRequest}
 */
proto.ListTasksInProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTasksInProjectRequest;
  return proto.ListTasksInProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTasksInProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTasksInProjectRequest}
 */
proto.ListTasksInProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new proto.Filter;
      reader.readMessage(value,proto.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.Search;
      reader.readMessage(value,proto.Search.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTasksInProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTasksInProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTasksInProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTasksInProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Filter.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Search.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.ListTasksInProjectRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTasksInProjectRequest} returns this
 */
proto.ListTasksInProjectRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Filter filter = 2;
 * @return {?proto.Filter}
 */
proto.ListTasksInProjectRequest.prototype.getFilter = function() {
  return /** @type{?proto.Filter} */ (
    jspb.Message.getWrapperField(this, proto.Filter, 2));
};


/**
 * @param {?proto.Filter|undefined} value
 * @return {!proto.ListTasksInProjectRequest} returns this
*/
proto.ListTasksInProjectRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTasksInProjectRequest} returns this
 */
proto.ListTasksInProjectRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksInProjectRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Search search = 3;
 * @return {?proto.Search}
 */
proto.ListTasksInProjectRequest.prototype.getSearch = function() {
  return /** @type{?proto.Search} */ (
    jspb.Message.getWrapperField(this, proto.Search, 3));
};


/**
 * @param {?proto.Search|undefined} value
 * @return {!proto.ListTasksInProjectRequest} returns this
*/
proto.ListTasksInProjectRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTasksInProjectRequest} returns this
 */
proto.ListTasksInProjectRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksInProjectRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Filter.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    dueDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    managedTask: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    assignedTask: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Filter}
 */
proto.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Filter;
  return proto.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Filter}
 */
proto.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProjectIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDueDate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManagedTask(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAssignedTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * repeated string project_ids = 1;
 * @return {!Array<string>}
 */
proto.Filter.prototype.getProjectIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.setProjectIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.addProjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.clearProjectIdsList = function() {
  return this.setProjectIdsList([]);
};


/**
 * repeated string organization_ids = 2;
 * @return {!Array<string>}
 */
proto.Filter.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * repeated string user_ids = 3;
 * @return {!Array<string>}
 */
proto.Filter.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * optional string due_date = 4;
 * @return {string}
 */
proto.Filter.prototype.getDueDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.setDueDate = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.clearDueDate = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Filter.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool managed_task = 5;
 * @return {boolean}
 */
proto.Filter.prototype.getManagedTask = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.setManagedTask = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.clearManagedTask = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Filter.prototype.hasManagedTask = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool assigned_task = 6;
 * @return {boolean}
 */
proto.Filter.prototype.getAssignedTask = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.setAssignedTask = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Filter} returns this
 */
proto.Filter.prototype.clearAssignedTask = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Filter.prototype.hasAssignedTask = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Search.prototype.toObject = function(opt_includeInstance) {
  return proto.Search.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Search} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Search.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchText: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Search}
 */
proto.Search.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Search;
  return proto.Search.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Search} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Search}
 */
proto.Search.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Search.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Search.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Search} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Search.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_text = 1;
 * @return {string}
 */
proto.Search.prototype.getSearchText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Search} returns this
 */
proto.Search.prototype.setSearchText = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.Search} returns this
 */
proto.Search.prototype.clearSearchText = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Search.prototype.hasSearchText = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTasksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTasksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTasksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTasksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.Filter.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && proto.Search.toObject(includeInstance, f),
    managerId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTasksRequest}
 */
proto.ListTasksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTasksRequest;
  return proto.ListTasksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTasksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTasksRequest}
 */
proto.ListTasksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Filter;
      reader.readMessage(value,proto.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.Search;
      reader.readMessage(value,proto.Search.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTasksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTasksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTasksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTasksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Filter.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Search.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Filter filter = 1;
 * @return {?proto.Filter}
 */
proto.ListTasksRequest.prototype.getFilter = function() {
  return /** @type{?proto.Filter} */ (
    jspb.Message.getWrapperField(this, proto.Filter, 1));
};


/**
 * @param {?proto.Filter|undefined} value
 * @return {!proto.ListTasksRequest} returns this
*/
proto.ListTasksRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTasksRequest} returns this
 */
proto.ListTasksRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Search search = 2;
 * @return {?proto.Search}
 */
proto.ListTasksRequest.prototype.getSearch = function() {
  return /** @type{?proto.Search} */ (
    jspb.Message.getWrapperField(this, proto.Search, 2));
};


/**
 * @param {?proto.Search|undefined} value
 * @return {!proto.ListTasksRequest} returns this
*/
proto.ListTasksRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTasksRequest} returns this
 */
proto.ListTasksRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string manager_id = 3;
 * @return {string}
 */
proto.ListTasksRequest.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTasksRequest} returns this
 */
proto.ListTasksRequest.prototype.setManagerId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ListTasksRequest} returns this
 */
proto.ListTasksRequest.prototype.clearManagerId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksRequest.prototype.hasManagerId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTasksByReporterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTasksByReporterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTasksByReporterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTasksByReporterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reporterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && proto.Filter.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && proto.Search.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTasksByReporterRequest}
 */
proto.ListTasksByReporterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTasksByReporterRequest;
  return proto.ListTasksByReporterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTasksByReporterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTasksByReporterRequest}
 */
proto.ListTasksByReporterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReporterId(value);
      break;
    case 2:
      var value = new proto.Filter;
      reader.readMessage(value,proto.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.Search;
      reader.readMessage(value,proto.Search.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTasksByReporterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTasksByReporterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTasksByReporterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTasksByReporterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Filter.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Search.serializeBinaryToWriter
    );
  }
};


/**
 * optional string reporter_id = 1;
 * @return {string}
 */
proto.ListTasksByReporterRequest.prototype.getReporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTasksByReporterRequest} returns this
 */
proto.ListTasksByReporterRequest.prototype.setReporterId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ListTasksByReporterRequest} returns this
 */
proto.ListTasksByReporterRequest.prototype.clearReporterId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksByReporterRequest.prototype.hasReporterId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filter filter = 2;
 * @return {?proto.Filter}
 */
proto.ListTasksByReporterRequest.prototype.getFilter = function() {
  return /** @type{?proto.Filter} */ (
    jspb.Message.getWrapperField(this, proto.Filter, 2));
};


/**
 * @param {?proto.Filter|undefined} value
 * @return {!proto.ListTasksByReporterRequest} returns this
*/
proto.ListTasksByReporterRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTasksByReporterRequest} returns this
 */
proto.ListTasksByReporterRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksByReporterRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Search search = 3;
 * @return {?proto.Search}
 */
proto.ListTasksByReporterRequest.prototype.getSearch = function() {
  return /** @type{?proto.Search} */ (
    jspb.Message.getWrapperField(this, proto.Search, 3));
};


/**
 * @param {?proto.Search|undefined} value
 * @return {!proto.ListTasksByReporterRequest} returns this
*/
proto.ListTasksByReporterRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTasksByReporterRequest} returns this
 */
proto.ListTasksByReporterRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksByReporterRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTasksForAssignedToUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTasksForAssignedToUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTasksForAssignedToUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTasksForAssignedToUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assignedTo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filter: (f = msg.getFilter()) && proto.Filter.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && proto.Search.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTasksForAssignedToUserRequest}
 */
proto.ListTasksForAssignedToUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTasksForAssignedToUserRequest;
  return proto.ListTasksForAssignedToUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTasksForAssignedToUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTasksForAssignedToUserRequest}
 */
proto.ListTasksForAssignedToUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    case 2:
      var value = new proto.Filter;
      reader.readMessage(value,proto.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.Search;
      reader.readMessage(value,proto.Search.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTasksForAssignedToUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTasksForAssignedToUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTasksForAssignedToUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTasksForAssignedToUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.Filter.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Search.serializeBinaryToWriter
    );
  }
};


/**
 * optional string assigned_to = 1;
 * @return {string}
 */
proto.ListTasksForAssignedToUserRequest.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTasksForAssignedToUserRequest} returns this
 */
proto.ListTasksForAssignedToUserRequest.prototype.setAssignedTo = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ListTasksForAssignedToUserRequest} returns this
 */
proto.ListTasksForAssignedToUserRequest.prototype.clearAssignedTo = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksForAssignedToUserRequest.prototype.hasAssignedTo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filter filter = 2;
 * @return {?proto.Filter}
 */
proto.ListTasksForAssignedToUserRequest.prototype.getFilter = function() {
  return /** @type{?proto.Filter} */ (
    jspb.Message.getWrapperField(this, proto.Filter, 2));
};


/**
 * @param {?proto.Filter|undefined} value
 * @return {!proto.ListTasksForAssignedToUserRequest} returns this
*/
proto.ListTasksForAssignedToUserRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTasksForAssignedToUserRequest} returns this
 */
proto.ListTasksForAssignedToUserRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksForAssignedToUserRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Search search = 3;
 * @return {?proto.Search}
 */
proto.ListTasksForAssignedToUserRequest.prototype.getSearch = function() {
  return /** @type{?proto.Search} */ (
    jspb.Message.getWrapperField(this, proto.Search, 3));
};


/**
 * @param {?proto.Search|undefined} value
 * @return {!proto.ListTasksForAssignedToUserRequest} returns this
*/
proto.ListTasksForAssignedToUserRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ListTasksForAssignedToUserRequest} returns this
 */
proto.ListTasksForAssignedToUserRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksForAssignedToUserRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GenericTaskResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GenericTaskResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GenericTaskResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    task: (f = msg.getTask()) && proto.Task.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GenericTaskResponse}
 */
proto.GenericTaskResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GenericTaskResponse;
  return proto.GenericTaskResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GenericTaskResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GenericTaskResponse}
 */
proto.GenericTaskResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.Task;
      reader.readMessage(value,proto.Task.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GenericTaskResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GenericTaskResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GenericTaskResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Task.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.GenericTaskResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GenericTaskResponse} returns this
 */
proto.GenericTaskResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.GenericTaskResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GenericTaskResponse} returns this
 */
proto.GenericTaskResponse.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.GenericTaskResponse} returns this
 */
proto.GenericTaskResponse.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Task task = 3;
 * @return {?proto.Task}
 */
proto.GenericTaskResponse.prototype.getTask = function() {
  return /** @type{?proto.Task} */ (
    jspb.Message.getWrapperField(this, proto.Task, 3));
};


/**
 * @param {?proto.Task|undefined} value
 * @return {!proto.GenericTaskResponse} returns this
*/
proto.GenericTaskResponse.prototype.setTask = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GenericTaskResponse} returns this
 */
proto.GenericTaskResponse.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskResponse.prototype.hasTask = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListTasksResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTasksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTasksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTasksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTasksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.Task.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTasksResponse}
 */
proto.ListTasksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTasksResponse;
  return proto.ListTasksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTasksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTasksResponse}
 */
proto.ListTasksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.Task;
      reader.readMessage(value,proto.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTasksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTasksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTasksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTasksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Task.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.ListTasksResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ListTasksResponse} returns this
 */
proto.ListTasksResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ListTasksResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTasksResponse} returns this
 */
proto.ListTasksResponse.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ListTasksResponse} returns this
 */
proto.ListTasksResponse.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTasksResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Task tasks = 3;
 * @return {!Array<!proto.Task>}
 */
proto.ListTasksResponse.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Task, 3));
};


/**
 * @param {!Array<!proto.Task>} value
 * @return {!proto.ListTasksResponse} returns this
*/
proto.ListTasksResponse.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Task}
 */
proto.ListTasksResponse.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListTasksResponse} returns this
 */
proto.ListTasksResponse.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GenericTaskEstimateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GenericTaskEstimateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GenericTaskEstimateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskEstimateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimate: (f = msg.getEstimate()) && proto.TaskEstimate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GenericTaskEstimateResponse}
 */
proto.GenericTaskEstimateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GenericTaskEstimateResponse;
  return proto.GenericTaskEstimateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GenericTaskEstimateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GenericTaskEstimateResponse}
 */
proto.GenericTaskEstimateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.TaskEstimate;
      reader.readMessage(value,proto.TaskEstimate.deserializeBinaryFromReader);
      msg.setEstimate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GenericTaskEstimateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GenericTaskEstimateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GenericTaskEstimateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskEstimateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TaskEstimate.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.GenericTaskEstimateResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GenericTaskEstimateResponse} returns this
 */
proto.GenericTaskEstimateResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.GenericTaskEstimateResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GenericTaskEstimateResponse} returns this
 */
proto.GenericTaskEstimateResponse.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.GenericTaskEstimateResponse} returns this
 */
proto.GenericTaskEstimateResponse.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskEstimateResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TaskEstimate estimate = 3;
 * @return {?proto.TaskEstimate}
 */
proto.GenericTaskEstimateResponse.prototype.getEstimate = function() {
  return /** @type{?proto.TaskEstimate} */ (
    jspb.Message.getWrapperField(this, proto.TaskEstimate, 3));
};


/**
 * @param {?proto.TaskEstimate|undefined} value
 * @return {!proto.GenericTaskEstimateResponse} returns this
*/
proto.GenericTaskEstimateResponse.prototype.setEstimate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GenericTaskEstimateResponse} returns this
 */
proto.GenericTaskEstimateResponse.prototype.clearEstimate = function() {
  return this.setEstimate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskEstimateResponse.prototype.hasEstimate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserTaskId.prototype.toObject = function(opt_includeInstance) {
  return proto.UserTaskId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserTaskId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserTaskId.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserTaskId}
 */
proto.UserTaskId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserTaskId;
  return proto.UserTaskId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserTaskId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserTaskId}
 */
proto.UserTaskId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserTaskId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserTaskId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserTaskId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserTaskId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.UserTaskId.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserTaskId} returns this
 */
proto.UserTaskId.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.UserTaskId.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserTaskId} returns this
 */
proto.UserTaskId.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTaskEstimatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTaskEstimatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTaskEstimatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTaskEstimatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTaskEstimatesRequest}
 */
proto.ListTaskEstimatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTaskEstimatesRequest;
  return proto.ListTaskEstimatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTaskEstimatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTaskEstimatesRequest}
 */
proto.ListTaskEstimatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTaskEstimatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTaskEstimatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTaskEstimatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTaskEstimatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.ListTaskEstimatesRequest.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTaskEstimatesRequest} returns this
 */
proto.ListTaskEstimatesRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ListTaskEstimatesRequest} returns this
 */
proto.ListTaskEstimatesRequest.prototype.clearTaskId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTaskEstimatesRequest.prototype.hasTaskId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.ListTaskEstimatesRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTaskEstimatesRequest} returns this
 */
proto.ListTaskEstimatesRequest.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ListTaskEstimatesRequest} returns this
 */
proto.ListTaskEstimatesRequest.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTaskEstimatesRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ListTaskEstimatesResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListTaskEstimatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ListTaskEstimatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListTaskEstimatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTaskEstimatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    estimatesList: jspb.Message.toObjectList(msg.getEstimatesList(),
    proto.TaskEstimate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListTaskEstimatesResponse}
 */
proto.ListTaskEstimatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListTaskEstimatesResponse;
  return proto.ListTaskEstimatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListTaskEstimatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListTaskEstimatesResponse}
 */
proto.ListTaskEstimatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.TaskEstimate;
      reader.readMessage(value,proto.TaskEstimate.deserializeBinaryFromReader);
      msg.addEstimates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListTaskEstimatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListTaskEstimatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListTaskEstimatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListTaskEstimatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEstimatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.TaskEstimate.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.ListTaskEstimatesResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ListTaskEstimatesResponse} returns this
 */
proto.ListTaskEstimatesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ListTaskEstimatesResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListTaskEstimatesResponse} returns this
 */
proto.ListTaskEstimatesResponse.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ListTaskEstimatesResponse} returns this
 */
proto.ListTaskEstimatesResponse.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ListTaskEstimatesResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated TaskEstimate estimates = 3;
 * @return {!Array<!proto.TaskEstimate>}
 */
proto.ListTaskEstimatesResponse.prototype.getEstimatesList = function() {
  return /** @type{!Array<!proto.TaskEstimate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TaskEstimate, 3));
};


/**
 * @param {!Array<!proto.TaskEstimate>} value
 * @return {!proto.ListTaskEstimatesResponse} returns this
*/
proto.ListTaskEstimatesResponse.prototype.setEstimatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.TaskEstimate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TaskEstimate}
 */
proto.ListTaskEstimatesResponse.prototype.addEstimates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.TaskEstimate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListTaskEstimatesResponse} returns this
 */
proto.ListTaskEstimatesResponse.prototype.clearEstimatesList = function() {
  return this.setEstimatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskRecurrenceInsertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskRecurrenceInsertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskRecurrenceInsertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskRecurrenceInsertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    repeatDay: jspb.Message.getFieldWithDefault(msg, 2, ""),
    repeatMonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    repeatWeekday: jspb.Message.getFieldWithDefault(msg, 4, ""),
    repeatWeek: jspb.Message.getFieldWithDefault(msg, 5, ""),
    repeatInterval: jspb.Message.getFieldWithDefault(msg, 6, ""),
    repeatText: jspb.Message.getFieldWithDefault(msg, 7, ""),
    repeatStart: jspb.Message.getFieldWithDefault(msg, 8, 0),
    repeatEnd: jspb.Message.getFieldWithDefault(msg, 9, 0),
    numberOfOccurrence: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskRecurrenceInsertRequest}
 */
proto.TaskRecurrenceInsertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskRecurrenceInsertRequest;
  return proto.TaskRecurrenceInsertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskRecurrenceInsertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskRecurrenceInsertRequest}
 */
proto.TaskRecurrenceInsertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatDay(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatMonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatWeekday(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatWeek(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatInterval(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatText(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepeatStart(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepeatEnd(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfOccurrence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskRecurrenceInsertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskRecurrenceInsertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskRecurrenceInsertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskRecurrenceInsertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRepeatDay();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRepeatMonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRepeatWeekday();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRepeatWeek();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRepeatInterval();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRepeatText();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRepeatStart();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.TaskRecurrenceInsertRequest.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.clearTaskId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceInsertRequest.prototype.hasTaskId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string repeat_day = 2;
 * @return {string}
 */
proto.TaskRecurrenceInsertRequest.prototype.getRepeatDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.setRepeatDay = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string repeat_month = 3;
 * @return {string}
 */
proto.TaskRecurrenceInsertRequest.prototype.getRepeatMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.setRepeatMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string repeat_weekday = 4;
 * @return {string}
 */
proto.TaskRecurrenceInsertRequest.prototype.getRepeatWeekday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.setRepeatWeekday = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string repeat_week = 5;
 * @return {string}
 */
proto.TaskRecurrenceInsertRequest.prototype.getRepeatWeek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.setRepeatWeek = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string repeat_interval = 6;
 * @return {string}
 */
proto.TaskRecurrenceInsertRequest.prototype.getRepeatInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.setRepeatInterval = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string repeat_text = 7;
 * @return {string}
 */
proto.TaskRecurrenceInsertRequest.prototype.getRepeatText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.setRepeatText = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 repeat_start = 8;
 * @return {number}
 */
proto.TaskRecurrenceInsertRequest.prototype.getRepeatStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.setRepeatStart = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 repeat_end = 9;
 * @return {number}
 */
proto.TaskRecurrenceInsertRequest.prototype.getRepeatEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.setRepeatEnd = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.clearRepeatEnd = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceInsertRequest.prototype.hasRepeatEnd = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 number_of_occurrence = 10;
 * @return {number}
 */
proto.TaskRecurrenceInsertRequest.prototype.getNumberOfOccurrence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.setNumberOfOccurrence = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceInsertRequest} returns this
 */
proto.TaskRecurrenceInsertRequest.prototype.clearNumberOfOccurrence = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceInsertRequest.prototype.hasNumberOfOccurrence = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskRecurrenceUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskRecurrenceUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskRecurrenceUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskRecurrenceUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    repeatDay: jspb.Message.getFieldWithDefault(msg, 2, ""),
    repeatMonth: jspb.Message.getFieldWithDefault(msg, 3, ""),
    repeatWeekday: jspb.Message.getFieldWithDefault(msg, 4, ""),
    repeatWeek: jspb.Message.getFieldWithDefault(msg, 5, ""),
    repeatInterval: jspb.Message.getFieldWithDefault(msg, 6, ""),
    repeatText: jspb.Message.getFieldWithDefault(msg, 7, ""),
    repeatStart: jspb.Message.getFieldWithDefault(msg, 8, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    repeatEnd: jspb.Message.getFieldWithDefault(msg, 10, 0),
    numberOfOccurrence: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskRecurrenceUpdateRequest}
 */
proto.TaskRecurrenceUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskRecurrenceUpdateRequest;
  return proto.TaskRecurrenceUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskRecurrenceUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskRecurrenceUpdateRequest}
 */
proto.TaskRecurrenceUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatDay(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatMonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatWeekday(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatWeek(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatInterval(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepeatText(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepeatStart(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRepeatEnd(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfOccurrence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskRecurrenceUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskRecurrenceUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskRecurrenceUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskRecurrenceUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRepeatText();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRepeatStart();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string repeat_day = 2;
 * @return {string}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getRepeatDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setRepeatDay = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.clearRepeatDay = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceUpdateRequest.prototype.hasRepeatDay = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string repeat_month = 3;
 * @return {string}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getRepeatMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setRepeatMonth = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.clearRepeatMonth = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceUpdateRequest.prototype.hasRepeatMonth = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string repeat_weekday = 4;
 * @return {string}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getRepeatWeekday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setRepeatWeekday = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.clearRepeatWeekday = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceUpdateRequest.prototype.hasRepeatWeekday = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string repeat_week = 5;
 * @return {string}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getRepeatWeek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setRepeatWeek = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.clearRepeatWeek = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceUpdateRequest.prototype.hasRepeatWeek = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string repeat_interval = 6;
 * @return {string}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getRepeatInterval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setRepeatInterval = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.clearRepeatInterval = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceUpdateRequest.prototype.hasRepeatInterval = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string repeat_text = 7;
 * @return {string}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getRepeatText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setRepeatText = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 repeat_start = 8;
 * @return {number}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getRepeatStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setRepeatStart = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string entity_id = 9;
 * @return {string}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.clearEntityId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceUpdateRequest.prototype.hasEntityId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 repeat_end = 10;
 * @return {number}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getRepeatEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setRepeatEnd = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.clearRepeatEnd = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceUpdateRequest.prototype.hasRepeatEnd = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 number_of_occurrence = 11;
 * @return {number}
 */
proto.TaskRecurrenceUpdateRequest.prototype.getNumberOfOccurrence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.setNumberOfOccurrence = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.TaskRecurrenceUpdateRequest} returns this
 */
proto.TaskRecurrenceUpdateRequest.prototype.clearNumberOfOccurrence = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TaskRecurrenceUpdateRequest.prototype.hasNumberOfOccurrence = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GenericTaskRecurrenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GenericTaskRecurrenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GenericTaskRecurrenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskRecurrenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recurrence: (f = msg.getRecurrence()) && proto.TaskRecurrence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GenericTaskRecurrenceResponse}
 */
proto.GenericTaskRecurrenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GenericTaskRecurrenceResponse;
  return proto.GenericTaskRecurrenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GenericTaskRecurrenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GenericTaskRecurrenceResponse}
 */
proto.GenericTaskRecurrenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.TaskRecurrence;
      reader.readMessage(value,proto.TaskRecurrence.deserializeBinaryFromReader);
      msg.setRecurrence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GenericTaskRecurrenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GenericTaskRecurrenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GenericTaskRecurrenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericTaskRecurrenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecurrence();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TaskRecurrence.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.GenericTaskRecurrenceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GenericTaskRecurrenceResponse} returns this
 */
proto.GenericTaskRecurrenceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.GenericTaskRecurrenceResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GenericTaskRecurrenceResponse} returns this
 */
proto.GenericTaskRecurrenceResponse.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.GenericTaskRecurrenceResponse} returns this
 */
proto.GenericTaskRecurrenceResponse.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskRecurrenceResponse.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TaskRecurrence recurrence = 3;
 * @return {?proto.TaskRecurrence}
 */
proto.GenericTaskRecurrenceResponse.prototype.getRecurrence = function() {
  return /** @type{?proto.TaskRecurrence} */ (
    jspb.Message.getWrapperField(this, proto.TaskRecurrence, 3));
};


/**
 * @param {?proto.TaskRecurrence|undefined} value
 * @return {!proto.GenericTaskRecurrenceResponse} returns this
*/
proto.GenericTaskRecurrenceResponse.prototype.setRecurrence = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GenericTaskRecurrenceResponse} returns this
 */
proto.GenericTaskRecurrenceResponse.prototype.clearRecurrence = function() {
  return this.setRecurrence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GenericTaskRecurrenceResponse.prototype.hasRecurrence = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaskRecurrenceId.prototype.toObject = function(opt_includeInstance) {
  return proto.TaskRecurrenceId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaskRecurrenceId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskRecurrenceId.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaskRecurrenceId}
 */
proto.TaskRecurrenceId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaskRecurrenceId;
  return proto.TaskRecurrenceId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaskRecurrenceId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaskRecurrenceId}
 */
proto.TaskRecurrenceId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaskRecurrenceId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaskRecurrenceId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaskRecurrenceId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaskRecurrenceId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.TaskRecurrenceId.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaskRecurrenceId} returns this
 */
proto.TaskRecurrenceId.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateAssigneeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateAssigneeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateAssigneeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAssigneeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assignedTo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateAssigneeRequest}
 */
proto.UpdateAssigneeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateAssigneeRequest;
  return proto.UpdateAssigneeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateAssigneeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateAssigneeRequest}
 */
proto.UpdateAssigneeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateAssigneeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateAssigneeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateAssigneeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAssigneeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssignedTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.UpdateAssigneeRequest.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateAssigneeRequest} returns this
 */
proto.UpdateAssigneeRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string assigned_to = 2;
 * @return {string}
 */
proto.UpdateAssigneeRequest.prototype.getAssignedTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateAssigneeRequest} returns this
 */
proto.UpdateAssigneeRequest.prototype.setAssignedTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RejectInvitationToTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RejectInvitationToTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RejectInvitationToTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RejectInvitationToTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RejectInvitationToTaskRequest}
 */
proto.RejectInvitationToTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RejectInvitationToTaskRequest;
  return proto.RejectInvitationToTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RejectInvitationToTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RejectInvitationToTaskRequest}
 */
proto.RejectInvitationToTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RejectInvitationToTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RejectInvitationToTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RejectInvitationToTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RejectInvitationToTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.RejectInvitationToTaskRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RejectInvitationToTaskRequest} returns this
 */
proto.RejectInvitationToTaskRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task_id = 2;
 * @return {string}
 */
proto.RejectInvitationToTaskRequest.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RejectInvitationToTaskRequest} returns this
 */
proto.RejectInvitationToTaskRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.RejectInvitationToTaskRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RejectInvitationToTaskRequest} returns this
 */
proto.RejectInvitationToTaskRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateTaskStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateTaskStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateTaskStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTaskStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateTaskStatusRequest}
 */
proto.UpdateTaskStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateTaskStatusRequest;
  return proto.UpdateTaskStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateTaskStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateTaskStatusRequest}
 */
proto.UpdateTaskStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateTaskStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateTaskStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateTaskStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateTaskStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.UpdateTaskStatusRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateTaskStatusRequest} returns this
 */
proto.UpdateTaskStatusRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.UpdateTaskStatusRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateTaskStatusRequest} returns this
 */
proto.UpdateTaskStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangeProjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangeProjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangeProjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeProjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    taskNumber: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangeProjectRequest}
 */
proto.ChangeProjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangeProjectRequest;
  return proto.ChangeProjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangeProjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangeProjectRequest}
 */
proto.ChangeProjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangeProjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangeProjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangeProjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangeProjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTaskNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.ChangeProjectRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeProjectRequest} returns this
 */
proto.ChangeProjectRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.ChangeProjectRequest.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeProjectRequest} returns this
 */
proto.ChangeProjectRequest.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string task_number = 3;
 * @return {string}
 */
proto.ChangeProjectRequest.prototype.getTaskNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangeProjectRequest} returns this
 */
proto.ChangeProjectRequest.prototype.setTaskNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteTaskPriorityInTaskRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteTaskPriorityInTaskRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteTaskPriorityInTaskRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteTaskPriorityInTaskRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    managerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteTaskPriorityInTaskRequest}
 */
proto.DeleteTaskPriorityInTaskRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteTaskPriorityInTaskRequest;
  return proto.DeleteTaskPriorityInTaskRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteTaskPriorityInTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteTaskPriorityInTaskRequest}
 */
proto.DeleteTaskPriorityInTaskRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteTaskPriorityInTaskRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteTaskPriorityInTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteTaskPriorityInTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteTaskPriorityInTaskRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string manager_id = 1;
 * @return {string}
 */
proto.DeleteTaskPriorityInTaskRequest.prototype.getManagerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteTaskPriorityInTaskRequest} returns this
 */
proto.DeleteTaskPriorityInTaskRequest.prototype.setManagerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task_id = 2;
 * @return {string}
 */
proto.DeleteTaskPriorityInTaskRequest.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteTaskPriorityInTaskRequest} returns this
 */
proto.DeleteTaskPriorityInTaskRequest.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto);
