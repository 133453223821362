import Vue from "vue";
import App from "@/App.vue";
import "./registerServiceWorker";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import VueI18n from "vue-i18n";
import en from "@/assets/locale/en.json";
import ur from "@/assets/locale/ur.json";
import helpers from "@/helpers/index";
import TrendChart from "vue-trend-chart";
import Notifications from "vue-notification";
import VueShepherd from "vue-shepherd";
import { googleAuthSettings } from "./config/constants";
import { initFbsdk } from "./config/facebookAuth";
import GoogleAuth from "./config/googleAuth";

initFbsdk();

const gAuthOption = {
 ...googleAuthSettings,
};

Vue.config.productionTip = false;
Vue.prototype.$helpers = helpers;

Vue.use(GoogleAuth, gAuthOption);
Vue.use(VueI18n);
Vue.use(Notifications);
Vue.use(TrendChart);
Vue.use(VueShepherd);

const messages = { en, ur };

export const i18n = new VueI18n({
 locale: "en",
 messages,
});

const vm = new Vue({
 router,
 store,
 vuetify,
 i18n,
 render: (h) => h(App),
}).$mount("#app");

export { vm };
