<template>
  <v-app>
    <mobile-header v-if="$vuetify.breakpoint.smAndDown" />
    <v-main class="backgroundPrimary mt-0 pt-0">
      <v-container fluid fill-height>
        <v-layout>
          <v-row>
            <v-col
              v-if="$vuetify.breakpoint.mdAndUp"
              cols="12"
              md="6"
              class="
                backgroundSecondary
                pa-0
                d-flex
                justify-space-between
                flex-column
              "
            >
              <div class="ps-5 pt-5">
                <div class="d-flex">
                  <app-logo showName />
                </div>

                <div class="mt-6 secondaryText--text">
                  <h2>{{ $t("login.organize") }}</h2>
                  <h2>{{ $t("login.easyWay") }}</h2>
                  <small class="font-small">{{ getMessage.sub }}</small>
                </div>
              </div>

              <div
                :class="[
                  'd-flex',
                  'ms-0',
                  'image-container',
                  'pa-0',
                  imagePosition,
                ]"
              >
                <img
                  :src="
                    require(`../assets/${
                      $vuetify.theme.isDark ? 'Reserva' : 'Reserva-light'
                    }.svg`)
                  "
                  :class="[
                    'organization-card',
                    $vuetify.theme.isDark
                      ? 'reserva-margin-dark'
                      : 'reserva-margin-light',
                    'reserva',
                  ]"
                />

                <img
                  :src="
                    require(`../assets/${
                      $vuetify.theme.isDark ? 'login' : 'login-light'
                    }.svg`)
                  "
                  :class="[
                    $vuetify.theme.isDark
                      ? 'app-screen-dark'
                      : 'app-screen-light',
                    'app-screen',
                  ]"
                />
              </div>
            </v-col>

            <v-col
              cols="12"
              md="6"
              :class="[
                'right-section backgroundPrimary',
                'pt-0',
                'pl-0',
                'pb-0',
              ]"
            >
              <transition name="fade" mode="out-in">
                <router-view />
              </transition>

              <div class="settings-pane d-flex align-center">
                <language-toggler />
                <theme-toggler class="ms-3" />
              </div>
            </v-col>
          </v-row>
        </v-layout>
      </v-container>
    </v-main>

    <app-version class="me-2" />
  </v-app>
</template>

<script>
import MobileHeader from "@/components/Global/MobileHeader.vue";
import LoginHeader from "@/components/Shared/LoginHeader.vue";
import TermsMessage from "../components/Shared/TermsMessage.vue";
import LanguageToggler from "@/components/Shared/LanguageToggler.vue";
import ThemeToggler from "@/components/Shared/ThemeToggler.vue";
import AppVersion from "../components/Shared/AppVersion.vue";
import AppLogo from '../components/Shared/AppLogo.vue';

export default {
  components: {
    MobileHeader,
    LoginHeader,
    TermsMessage,
    LanguageToggler,
    ThemeToggler,
    AppVersion,
    AppLogo,
  },
  computed: {
    imagePosition() {
      return this.$vuetify.rtl ? 'justify-start': 'justify-end'
    },
    getMessage() {
      switch (this.$route.name) {
        case "login":
          return {
            main: this.$t("login.welcome"),
            sub: this.$t("login.submessage"),
          };

        case "signup":
          return {
            main: this.$t("signup.create") + " " + this.$t("signup.account"),
            sub: this.$t("login.submessage"),
          };

        case "forgotpassword":
          return {
            main:
              this.$t("forgot.forgot") + " " + this.$t("placeholders.password"),
            sub: this.$t("forgot.submessage"),
          };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.image-container {
  position: relative;
  margin-right: -5px;

  .app-screen {
    height: auto;
  }

  .app-screen-dark {
    width: 580px;
    height: 600px;
  }

  .app-screen-light {
    width: 590px;
    height: 600px;
  }

  .organization-card {
    height: 48px;
    position: absolute;
  }

  .reserva {
    top: 60%;
  }

  .reserva-margin-dark {
    margin-right: 470px;
  }

  .reserva-margin-light {
    margin-right: 480px;
  }

  .fit-dance {
    margin-right: 360px;
    top: 80%;
  }
}

.right-section {
  position: relative;
  .settings-pane {
    position: absolute;
    bottom: 5px;
  }
}

.bordered {
  z-index: 1;
}
</style>