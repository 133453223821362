/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var attachment_pb = require('./attachment_pb.js')

var organization_pb = require('./organization_pb.js')

var timesheet_pb = require('./timesheet_pb.js')
const proto = require('./task_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.TasksClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.TasksPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskInsertRequest,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_CreateTask = new grpc.web.MethodDescriptor(
  '/Tasks/CreateTask',
  grpc.web.MethodType.UNARY,
  proto.TaskInsertRequest,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.TaskInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.TaskInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.createTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/CreateTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_CreateTask,
      callback);
};


/**
 * @param {!proto.TaskInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.createTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/CreateTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_CreateTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.UpdateTaskStatusRequest,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_UpdateTaskStatus = new grpc.web.MethodDescriptor(
  '/Tasks/UpdateTaskStatus',
  grpc.web.MethodType.UNARY,
  proto.UpdateTaskStatusRequest,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.UpdateTaskStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.UpdateTaskStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.updateTaskStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/UpdateTaskStatus',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateTaskStatus,
      callback);
};


/**
 * @param {!proto.UpdateTaskStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.updateTaskStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/UpdateTaskStatus',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateTaskStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskUpdateRequest,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_UpdateTask = new grpc.web.MethodDescriptor(
  '/Tasks/UpdateTask',
  grpc.web.MethodType.UNARY,
  proto.TaskUpdateRequest,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.TaskUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.TaskUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.updateTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/UpdateTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateTask,
      callback);
};


/**
 * @param {!proto.TaskUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.updateTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/UpdateTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ChangeProjectRequest,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_ChangeProject = new grpc.web.MethodDescriptor(
  '/Tasks/ChangeProject',
  grpc.web.MethodType.UNARY,
  proto.ChangeProjectRequest,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.ChangeProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.ChangeProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.changeProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/ChangeProject',
      request,
      metadata || {},
      methodDescriptor_Tasks_ChangeProject,
      callback);
};


/**
 * @param {!proto.ChangeProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.changeProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/ChangeProject',
      request,
      metadata || {},
      methodDescriptor_Tasks_ChangeProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListTasksByReporterRequest,
 *   !proto.ListTasksResponse>}
 */
const methodDescriptor_Tasks_ListTasksByReporter = new grpc.web.MethodDescriptor(
  '/Tasks/ListTasksByReporter',
  grpc.web.MethodType.UNARY,
  proto.ListTasksByReporterRequest,
  proto.ListTasksResponse,
  /**
   * @param {!proto.ListTasksByReporterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListTasksResponse.deserializeBinary
);


/**
 * @param {!proto.ListTasksByReporterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.listTasksByReporter =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/ListTasksByReporter',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListTasksByReporter,
      callback);
};


/**
 * @param {!proto.ListTasksByReporterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListTasksResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.listTasksByReporter =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/ListTasksByReporter',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListTasksByReporter);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListTasksRequest,
 *   !proto.ListTasksResponse>}
 */
const methodDescriptor_Tasks_ListTasksInBacklogForManager = new grpc.web.MethodDescriptor(
  '/Tasks/ListTasksInBacklogForManager',
  grpc.web.MethodType.UNARY,
  proto.ListTasksRequest,
  proto.ListTasksResponse,
  /**
   * @param {!proto.ListTasksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListTasksResponse.deserializeBinary
);


/**
 * @param {!proto.ListTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.listTasksInBacklogForManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/ListTasksInBacklogForManager',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListTasksInBacklogForManager,
      callback);
};


/**
 * @param {!proto.ListTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListTasksResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.listTasksInBacklogForManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/ListTasksInBacklogForManager',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListTasksInBacklogForManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListTasksInProjectRequest,
 *   !proto.ListTasksResponse>}
 */
const methodDescriptor_Tasks_ListTasksInProject = new grpc.web.MethodDescriptor(
  '/Tasks/ListTasksInProject',
  grpc.web.MethodType.UNARY,
  proto.ListTasksInProjectRequest,
  proto.ListTasksResponse,
  /**
   * @param {!proto.ListTasksInProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListTasksResponse.deserializeBinary
);


/**
 * @param {!proto.ListTasksInProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.listTasksInProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/ListTasksInProject',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListTasksInProject,
      callback);
};


/**
 * @param {!proto.ListTasksInProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListTasksResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.listTasksInProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/ListTasksInProject',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListTasksInProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListTasksForAssignedToUserRequest,
 *   !proto.ListTasksResponse>}
 */
const methodDescriptor_Tasks_ListTasksForAssignedToUser = new grpc.web.MethodDescriptor(
  '/Tasks/ListTasksForAssignedToUser',
  grpc.web.MethodType.UNARY,
  proto.ListTasksForAssignedToUserRequest,
  proto.ListTasksResponse,
  /**
   * @param {!proto.ListTasksForAssignedToUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListTasksResponse.deserializeBinary
);


/**
 * @param {!proto.ListTasksForAssignedToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.listTasksForAssignedToUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/ListTasksForAssignedToUser',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListTasksForAssignedToUser,
      callback);
};


/**
 * @param {!proto.ListTasksForAssignedToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListTasksResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.listTasksForAssignedToUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/ListTasksForAssignedToUser',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListTasksForAssignedToUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.EntityId,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_GetTask = new grpc.web.MethodDescriptor(
  '/Tasks/GetTask',
  grpc.web.MethodType.UNARY,
  proto.EntityId,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.getTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/GetTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetTask,
      callback);
};


/**
 * @param {!proto.EntityId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.getTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/GetTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.EntityId,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_DeleteTask = new grpc.web.MethodDescriptor(
  '/Tasks/DeleteTask',
  grpc.web.MethodType.UNARY,
  proto.EntityId,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.deleteTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/DeleteTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTask,
      callback);
};


/**
 * @param {!proto.EntityId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.deleteTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/DeleteTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.EntityId,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_ArchiveTask = new grpc.web.MethodDescriptor(
  '/Tasks/ArchiveTask',
  grpc.web.MethodType.UNARY,
  proto.EntityId,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.archiveTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/ArchiveTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_ArchiveTask,
      callback);
};


/**
 * @param {!proto.EntityId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.archiveTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/ArchiveTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_ArchiveTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.EntityId,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_UnarchiveTask = new grpc.web.MethodDescriptor(
  '/Tasks/UnarchiveTask',
  grpc.web.MethodType.UNARY,
  proto.EntityId,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.unarchiveTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/UnarchiveTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_UnarchiveTask,
      callback);
};


/**
 * @param {!proto.EntityId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.unarchiveTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/UnarchiveTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_UnarchiveTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListTasksInProjectRequest,
 *   !proto.ListTasksResponse>}
 */
const methodDescriptor_Tasks_ListArchivedTasksInProject = new grpc.web.MethodDescriptor(
  '/Tasks/ListArchivedTasksInProject',
  grpc.web.MethodType.UNARY,
  proto.ListTasksInProjectRequest,
  proto.ListTasksResponse,
  /**
   * @param {!proto.ListTasksInProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListTasksResponse.deserializeBinary
);


/**
 * @param {!proto.ListTasksInProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.listArchivedTasksInProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/ListArchivedTasksInProject',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListArchivedTasksInProject,
      callback);
};


/**
 * @param {!proto.ListTasksInProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListTasksResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.listArchivedTasksInProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/ListArchivedTasksInProject',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListArchivedTasksInProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.UpdateAssigneeRequest,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_UpdateAssigneeInTask = new grpc.web.MethodDescriptor(
  '/Tasks/UpdateAssigneeInTask',
  grpc.web.MethodType.UNARY,
  proto.UpdateAssigneeRequest,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.UpdateAssigneeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.UpdateAssigneeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.updateAssigneeInTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/UpdateAssigneeInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateAssigneeInTask,
      callback);
};


/**
 * @param {!proto.UpdateAssigneeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.updateAssigneeInTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/UpdateAssigneeInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateAssigneeInTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskEstimateInsertRequest,
 *   !proto.GenericTaskEstimateResponse>}
 */
const methodDescriptor_Tasks_AddUserEstimateInTask = new grpc.web.MethodDescriptor(
  '/Tasks/AddUserEstimateInTask',
  grpc.web.MethodType.UNARY,
  proto.TaskEstimateInsertRequest,
  proto.GenericTaskEstimateResponse,
  /**
   * @param {!proto.TaskEstimateInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskEstimateResponse.deserializeBinary
);


/**
 * @param {!proto.TaskEstimateInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskEstimateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskEstimateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.addUserEstimateInTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/AddUserEstimateInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_AddUserEstimateInTask,
      callback);
};


/**
 * @param {!proto.TaskEstimateInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskEstimateResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.addUserEstimateInTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/AddUserEstimateInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_AddUserEstimateInTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskEstimateInsertRequest,
 *   !proto.GenericTaskEstimateResponse>}
 */
const methodDescriptor_Tasks_UpdateUserEstimateInTask = new grpc.web.MethodDescriptor(
  '/Tasks/UpdateUserEstimateInTask',
  grpc.web.MethodType.UNARY,
  proto.TaskEstimateInsertRequest,
  proto.GenericTaskEstimateResponse,
  /**
   * @param {!proto.TaskEstimateInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskEstimateResponse.deserializeBinary
);


/**
 * @param {!proto.TaskEstimateInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskEstimateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskEstimateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.updateUserEstimateInTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/UpdateUserEstimateInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateUserEstimateInTask,
      callback);
};


/**
 * @param {!proto.TaskEstimateInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskEstimateResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.updateUserEstimateInTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/UpdateUserEstimateInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateUserEstimateInTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.UserTaskId,
 *   !proto.GenericTaskEstimateResponse>}
 */
const methodDescriptor_Tasks_DeleteUserEstimateFromTask = new grpc.web.MethodDescriptor(
  '/Tasks/DeleteUserEstimateFromTask',
  grpc.web.MethodType.UNARY,
  proto.UserTaskId,
  proto.GenericTaskEstimateResponse,
  /**
   * @param {!proto.UserTaskId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskEstimateResponse.deserializeBinary
);


/**
 * @param {!proto.UserTaskId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskEstimateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskEstimateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.deleteUserEstimateFromTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/DeleteUserEstimateFromTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteUserEstimateFromTask,
      callback);
};


/**
 * @param {!proto.UserTaskId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskEstimateResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.deleteUserEstimateFromTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/DeleteUserEstimateFromTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteUserEstimateFromTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.UserTaskId,
 *   !proto.GenericTaskEstimateResponse>}
 */
const methodDescriptor_Tasks_GetUserEstimateInTask = new grpc.web.MethodDescriptor(
  '/Tasks/GetUserEstimateInTask',
  grpc.web.MethodType.UNARY,
  proto.UserTaskId,
  proto.GenericTaskEstimateResponse,
  /**
   * @param {!proto.UserTaskId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskEstimateResponse.deserializeBinary
);


/**
 * @param {!proto.UserTaskId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskEstimateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskEstimateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.getUserEstimateInTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/GetUserEstimateInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetUserEstimateInTask,
      callback);
};


/**
 * @param {!proto.UserTaskId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskEstimateResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.getUserEstimateInTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/GetUserEstimateInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetUserEstimateInTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListTaskEstimatesRequest,
 *   !proto.ListTaskEstimatesResponse>}
 */
const methodDescriptor_Tasks_ListEstimatesInTask = new grpc.web.MethodDescriptor(
  '/Tasks/ListEstimatesInTask',
  grpc.web.MethodType.UNARY,
  proto.ListTaskEstimatesRequest,
  proto.ListTaskEstimatesResponse,
  /**
   * @param {!proto.ListTaskEstimatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListTaskEstimatesResponse.deserializeBinary
);


/**
 * @param {!proto.ListTaskEstimatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListTaskEstimatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListTaskEstimatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.listEstimatesInTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/ListEstimatesInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListEstimatesInTask,
      callback);
};


/**
 * @param {!proto.ListTaskEstimatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListTaskEstimatesResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.listEstimatesInTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/ListEstimatesInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListEstimatesInTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskRecurrenceInsertRequest,
 *   !proto.GenericTaskRecurrenceResponse>}
 */
const methodDescriptor_Tasks_AddRecurrenceInTask = new grpc.web.MethodDescriptor(
  '/Tasks/AddRecurrenceInTask',
  grpc.web.MethodType.UNARY,
  proto.TaskRecurrenceInsertRequest,
  proto.GenericTaskRecurrenceResponse,
  /**
   * @param {!proto.TaskRecurrenceInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskRecurrenceResponse.deserializeBinary
);


/**
 * @param {!proto.TaskRecurrenceInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskRecurrenceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskRecurrenceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.addRecurrenceInTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/AddRecurrenceInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_AddRecurrenceInTask,
      callback);
};


/**
 * @param {!proto.TaskRecurrenceInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskRecurrenceResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.addRecurrenceInTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/AddRecurrenceInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_AddRecurrenceInTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskRecurrenceUpdateRequest,
 *   !proto.GenericTaskRecurrenceResponse>}
 */
const methodDescriptor_Tasks_UpdateRecurrenceInTask = new grpc.web.MethodDescriptor(
  '/Tasks/UpdateRecurrenceInTask',
  grpc.web.MethodType.UNARY,
  proto.TaskRecurrenceUpdateRequest,
  proto.GenericTaskRecurrenceResponse,
  /**
   * @param {!proto.TaskRecurrenceUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskRecurrenceResponse.deserializeBinary
);


/**
 * @param {!proto.TaskRecurrenceUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskRecurrenceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskRecurrenceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.updateRecurrenceInTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/UpdateRecurrenceInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateRecurrenceInTask,
      callback);
};


/**
 * @param {!proto.TaskRecurrenceUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskRecurrenceResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.updateRecurrenceInTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/UpdateRecurrenceInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateRecurrenceInTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskRecurrenceId,
 *   !proto.GenericTaskRecurrenceResponse>}
 */
const methodDescriptor_Tasks_DeleteRecurrenceFromTask = new grpc.web.MethodDescriptor(
  '/Tasks/DeleteRecurrenceFromTask',
  grpc.web.MethodType.UNARY,
  proto.TaskRecurrenceId,
  proto.GenericTaskRecurrenceResponse,
  /**
   * @param {!proto.TaskRecurrenceId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskRecurrenceResponse.deserializeBinary
);


/**
 * @param {!proto.TaskRecurrenceId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskRecurrenceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskRecurrenceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.deleteRecurrenceFromTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/DeleteRecurrenceFromTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteRecurrenceFromTask,
      callback);
};


/**
 * @param {!proto.TaskRecurrenceId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskRecurrenceResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.deleteRecurrenceFromTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/DeleteRecurrenceFromTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteRecurrenceFromTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskRecurrenceId,
 *   !proto.GenericTaskRecurrenceResponse>}
 */
const methodDescriptor_Tasks_GetRecurrenceInTask = new grpc.web.MethodDescriptor(
  '/Tasks/GetRecurrenceInTask',
  grpc.web.MethodType.UNARY,
  proto.TaskRecurrenceId,
  proto.GenericTaskRecurrenceResponse,
  /**
   * @param {!proto.TaskRecurrenceId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskRecurrenceResponse.deserializeBinary
);


/**
 * @param {!proto.TaskRecurrenceId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskRecurrenceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskRecurrenceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.getRecurrenceInTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/GetRecurrenceInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetRecurrenceInTask,
      callback);
};


/**
 * @param {!proto.TaskRecurrenceId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskRecurrenceResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.getRecurrenceInTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/GetRecurrenceInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetRecurrenceInTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ReplyToInvitationFromEmailRequest,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_AcceptTaskInvitationFromEmail = new grpc.web.MethodDescriptor(
  '/Tasks/AcceptTaskInvitationFromEmail',
  grpc.web.MethodType.UNARY,
  organization_pb.ReplyToInvitationFromEmailRequest,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.ReplyToInvitationFromEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.acceptTaskInvitationFromEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/AcceptTaskInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Tasks_AcceptTaskInvitationFromEmail,
      callback);
};


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.acceptTaskInvitationFromEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/AcceptTaskInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Tasks_AcceptTaskInvitationFromEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ReplyToInvitationFromEmailRequest,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_RejectTaskInvitationFromEmail = new grpc.web.MethodDescriptor(
  '/Tasks/RejectTaskInvitationFromEmail',
  grpc.web.MethodType.UNARY,
  organization_pb.ReplyToInvitationFromEmailRequest,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.ReplyToInvitationFromEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.rejectTaskInvitationFromEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/RejectTaskInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Tasks_RejectTaskInvitationFromEmail,
      callback);
};


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.rejectTaskInvitationFromEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/RejectTaskInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Tasks_RejectTaskInvitationFromEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.UserTaskId,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_AcceptInvitationToTask = new grpc.web.MethodDescriptor(
  '/Tasks/AcceptInvitationToTask',
  grpc.web.MethodType.UNARY,
  proto.UserTaskId,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.UserTaskId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.UserTaskId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.acceptInvitationToTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/AcceptInvitationToTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_AcceptInvitationToTask,
      callback);
};


/**
 * @param {!proto.UserTaskId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.acceptInvitationToTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/AcceptInvitationToTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_AcceptInvitationToTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RejectInvitationToTaskRequest,
 *   !proto.GenericTaskResponse>}
 */
const methodDescriptor_Tasks_RejectInvitationToTask = new grpc.web.MethodDescriptor(
  '/Tasks/RejectInvitationToTask',
  grpc.web.MethodType.UNARY,
  proto.RejectInvitationToTaskRequest,
  proto.GenericTaskResponse,
  /**
   * @param {!proto.RejectInvitationToTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskResponse.deserializeBinary
);


/**
 * @param {!proto.RejectInvitationToTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.rejectInvitationToTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/RejectInvitationToTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_RejectInvitationToTask,
      callback);
};


/**
 * @param {!proto.RejectInvitationToTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.rejectInvitationToTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/RejectInvitationToTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_RejectInvitationToTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskPriorityListCreateRequest,
 *   !proto.GenericTaskPriorityListResponse>}
 */
const methodDescriptor_Tasks_CreateTaskPriority = new grpc.web.MethodDescriptor(
  '/Tasks/CreateTaskPriority',
  grpc.web.MethodType.UNARY,
  proto.TaskPriorityListCreateRequest,
  proto.GenericTaskPriorityListResponse,
  /**
   * @param {!proto.TaskPriorityListCreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskPriorityListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskPriorityListCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskPriorityListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskPriorityListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.createTaskPriority =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/CreateTaskPriority',
      request,
      metadata || {},
      methodDescriptor_Tasks_CreateTaskPriority,
      callback);
};


/**
 * @param {!proto.TaskPriorityListCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskPriorityListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.createTaskPriority =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/CreateTaskPriority',
      request,
      metadata || {},
      methodDescriptor_Tasks_CreateTaskPriority);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskPriorityListCreateRequest,
 *   !proto.GenericTaskPriorityListResponse>}
 */
const methodDescriptor_Tasks_UpdateTaskPriority = new grpc.web.MethodDescriptor(
  '/Tasks/UpdateTaskPriority',
  grpc.web.MethodType.UNARY,
  proto.TaskPriorityListCreateRequest,
  proto.GenericTaskPriorityListResponse,
  /**
   * @param {!proto.TaskPriorityListCreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskPriorityListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskPriorityListCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskPriorityListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskPriorityListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.updateTaskPriority =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/UpdateTaskPriority',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateTaskPriority,
      callback);
};


/**
 * @param {!proto.TaskPriorityListCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskPriorityListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.updateTaskPriority =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/UpdateTaskPriority',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateTaskPriority);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskManagerId,
 *   !proto.GenericTaskPriorityListResponse>}
 */
const methodDescriptor_Tasks_InitializeTaskPriorityForManager = new grpc.web.MethodDescriptor(
  '/Tasks/InitializeTaskPriorityForManager',
  grpc.web.MethodType.UNARY,
  proto.TaskManagerId,
  proto.GenericTaskPriorityListResponse,
  /**
   * @param {!proto.TaskManagerId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskPriorityListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskManagerId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskPriorityListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskPriorityListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.initializeTaskPriorityForManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/InitializeTaskPriorityForManager',
      request,
      metadata || {},
      methodDescriptor_Tasks_InitializeTaskPriorityForManager,
      callback);
};


/**
 * @param {!proto.TaskManagerId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskPriorityListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.initializeTaskPriorityForManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/InitializeTaskPriorityForManager',
      request,
      metadata || {},
      methodDescriptor_Tasks_InitializeTaskPriorityForManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskManagerId,
 *   !proto.GenericTaskPriorityListResponse>}
 */
const methodDescriptor_Tasks_DeleteTaskPriorityForManager = new grpc.web.MethodDescriptor(
  '/Tasks/DeleteTaskPriorityForManager',
  grpc.web.MethodType.UNARY,
  proto.TaskManagerId,
  proto.GenericTaskPriorityListResponse,
  /**
   * @param {!proto.TaskManagerId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskPriorityListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskManagerId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskPriorityListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskPriorityListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.deleteTaskPriorityForManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/DeleteTaskPriorityForManager',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTaskPriorityForManager,
      callback);
};


/**
 * @param {!proto.TaskManagerId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskPriorityListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.deleteTaskPriorityForManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/DeleteTaskPriorityForManager',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTaskPriorityForManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.DeleteTaskPriorityInTaskRequest,
 *   !proto.GenericTaskPriorityListResponse>}
 */
const methodDescriptor_Tasks_DeleteTaskPriorityInTask = new grpc.web.MethodDescriptor(
  '/Tasks/DeleteTaskPriorityInTask',
  grpc.web.MethodType.UNARY,
  proto.DeleteTaskPriorityInTaskRequest,
  proto.GenericTaskPriorityListResponse,
  /**
   * @param {!proto.DeleteTaskPriorityInTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskPriorityListResponse.deserializeBinary
);


/**
 * @param {!proto.DeleteTaskPriorityInTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskPriorityListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskPriorityListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.deleteTaskPriorityInTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/DeleteTaskPriorityInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTaskPriorityInTask,
      callback);
};


/**
 * @param {!proto.DeleteTaskPriorityInTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskPriorityListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.deleteTaskPriorityInTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/DeleteTaskPriorityInTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTaskPriorityInTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskManagerId,
 *   !proto.GenericTaskPriorityListResponse>}
 */
const methodDescriptor_Tasks_GetTaskPriorityForManager = new grpc.web.MethodDescriptor(
  '/Tasks/GetTaskPriorityForManager',
  grpc.web.MethodType.UNARY,
  proto.TaskManagerId,
  proto.GenericTaskPriorityListResponse,
  /**
   * @param {!proto.TaskManagerId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskPriorityListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskManagerId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskPriorityListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskPriorityListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.getTaskPriorityForManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/GetTaskPriorityForManager',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetTaskPriorityForManager,
      callback);
};


/**
 * @param {!proto.TaskManagerId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskPriorityListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.getTaskPriorityForManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/GetTaskPriorityForManager',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetTaskPriorityForManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskSlotListInsertRequest,
 *   !proto.GenericTaskSlotListResponse>}
 */
const methodDescriptor_Tasks_CreateTaskSlotList = new grpc.web.MethodDescriptor(
  '/Tasks/CreateTaskSlotList',
  grpc.web.MethodType.UNARY,
  proto.TaskSlotListInsertRequest,
  proto.GenericTaskSlotListResponse,
  /**
   * @param {!proto.TaskSlotListInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskSlotListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskSlotListInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskSlotListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskSlotListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.createTaskSlotList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/CreateTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_CreateTaskSlotList,
      callback);
};


/**
 * @param {!proto.TaskSlotListInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskSlotListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.createTaskSlotList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/CreateTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_CreateTaskSlotList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskSlotListInsertRequest,
 *   !proto.GenericTaskSlotListResponse>}
 */
const methodDescriptor_Tasks_UpdateTaskSlotList = new grpc.web.MethodDescriptor(
  '/Tasks/UpdateTaskSlotList',
  grpc.web.MethodType.UNARY,
  proto.TaskSlotListInsertRequest,
  proto.GenericTaskSlotListResponse,
  /**
   * @param {!proto.TaskSlotListInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskSlotListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskSlotListInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskSlotListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskSlotListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.updateTaskSlotList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/UpdateTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateTaskSlotList,
      callback);
};


/**
 * @param {!proto.TaskSlotListInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskSlotListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.updateTaskSlotList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/UpdateTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateTaskSlotList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskResourceId,
 *   !proto.GenericTaskSlotListResponse>}
 */
const methodDescriptor_Tasks_DeleteTaskSlotListForResource = new grpc.web.MethodDescriptor(
  '/Tasks/DeleteTaskSlotListForResource',
  grpc.web.MethodType.UNARY,
  proto.TaskResourceId,
  proto.GenericTaskSlotListResponse,
  /**
   * @param {!proto.TaskResourceId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskSlotListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskResourceId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskSlotListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskSlotListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.deleteTaskSlotListForResource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/DeleteTaskSlotListForResource',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTaskSlotListForResource,
      callback);
};


/**
 * @param {!proto.TaskResourceId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskSlotListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.deleteTaskSlotListForResource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/DeleteTaskSlotListForResource',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTaskSlotListForResource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskResourceId,
 *   !proto.GenericTaskSlotListResponse>}
 */
const methodDescriptor_Tasks_GetTaskSlotListForResource = new grpc.web.MethodDescriptor(
  '/Tasks/GetTaskSlotListForResource',
  grpc.web.MethodType.UNARY,
  proto.TaskResourceId,
  proto.GenericTaskSlotListResponse,
  /**
   * @param {!proto.TaskResourceId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskSlotListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskResourceId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskSlotListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskSlotListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.getTaskSlotListForResource =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/GetTaskSlotListForResource',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetTaskSlotListForResource,
      callback);
};


/**
 * @param {!proto.TaskResourceId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskSlotListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.getTaskSlotListForResource =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/GetTaskSlotListForResource',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetTaskSlotListForResource);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectTaskSlotListInsertRequest,
 *   !proto.GenericProjectTaskSlotListResponse>}
 */
const methodDescriptor_Tasks_CreateProjectTaskSlotList = new grpc.web.MethodDescriptor(
  '/Tasks/CreateProjectTaskSlotList',
  grpc.web.MethodType.UNARY,
  proto.ProjectTaskSlotListInsertRequest,
  proto.GenericProjectTaskSlotListResponse,
  /**
   * @param {!proto.ProjectTaskSlotListInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectTaskSlotListResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectTaskSlotListInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectTaskSlotListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectTaskSlotListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.createProjectTaskSlotList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/CreateProjectTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_CreateProjectTaskSlotList,
      callback);
};


/**
 * @param {!proto.ProjectTaskSlotListInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectTaskSlotListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.createProjectTaskSlotList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/CreateProjectTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_CreateProjectTaskSlotList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectTaskSlotListInsertRequest,
 *   !proto.GenericProjectTaskSlotListResponse>}
 */
const methodDescriptor_Tasks_UpdateProjectTaskSlotList = new grpc.web.MethodDescriptor(
  '/Tasks/UpdateProjectTaskSlotList',
  grpc.web.MethodType.UNARY,
  proto.ProjectTaskSlotListInsertRequest,
  proto.GenericProjectTaskSlotListResponse,
  /**
   * @param {!proto.ProjectTaskSlotListInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectTaskSlotListResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectTaskSlotListInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectTaskSlotListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectTaskSlotListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.updateProjectTaskSlotList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/UpdateProjectTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateProjectTaskSlotList,
      callback);
};


/**
 * @param {!proto.ProjectTaskSlotListInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectTaskSlotListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.updateProjectTaskSlotList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/UpdateProjectTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateProjectTaskSlotList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskProjectId,
 *   !proto.GenericProjectTaskSlotListResponse>}
 */
const methodDescriptor_Tasks_DeleteProjectTaskSlotList = new grpc.web.MethodDescriptor(
  '/Tasks/DeleteProjectTaskSlotList',
  grpc.web.MethodType.UNARY,
  proto.TaskProjectId,
  proto.GenericProjectTaskSlotListResponse,
  /**
   * @param {!proto.TaskProjectId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectTaskSlotListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskProjectId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectTaskSlotListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectTaskSlotListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.deleteProjectTaskSlotList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/DeleteProjectTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteProjectTaskSlotList,
      callback);
};


/**
 * @param {!proto.TaskProjectId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectTaskSlotListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.deleteProjectTaskSlotList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/DeleteProjectTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteProjectTaskSlotList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskProjectId,
 *   !proto.GenericProjectTaskSlotListResponse>}
 */
const methodDescriptor_Tasks_GetProjectTaskSlotList = new grpc.web.MethodDescriptor(
  '/Tasks/GetProjectTaskSlotList',
  grpc.web.MethodType.UNARY,
  proto.TaskProjectId,
  proto.GenericProjectTaskSlotListResponse,
  /**
   * @param {!proto.TaskProjectId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectTaskSlotListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskProjectId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectTaskSlotListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectTaskSlotListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.getProjectTaskSlotList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/GetProjectTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetProjectTaskSlotList,
      callback);
};


/**
 * @param {!proto.TaskProjectId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectTaskSlotListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.getProjectTaskSlotList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/GetProjectTaskSlotList',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetProjectTaskSlotList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskCommentInsertRequest,
 *   !proto.GenericTaskCommentResponse>}
 */
const methodDescriptor_Tasks_CreateTaskComment = new grpc.web.MethodDescriptor(
  '/Tasks/CreateTaskComment',
  grpc.web.MethodType.UNARY,
  proto.TaskCommentInsertRequest,
  proto.GenericTaskCommentResponse,
  /**
   * @param {!proto.TaskCommentInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskCommentResponse.deserializeBinary
);


/**
 * @param {!proto.TaskCommentInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.createTaskComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/CreateTaskComment',
      request,
      metadata || {},
      methodDescriptor_Tasks_CreateTaskComment,
      callback);
};


/**
 * @param {!proto.TaskCommentInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskCommentResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.createTaskComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/CreateTaskComment',
      request,
      metadata || {},
      methodDescriptor_Tasks_CreateTaskComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskCommentReplyRequest,
 *   !proto.GenericTaskCommentResponse>}
 */
const methodDescriptor_Tasks_ReplyForTaskComment = new grpc.web.MethodDescriptor(
  '/Tasks/ReplyForTaskComment',
  grpc.web.MethodType.UNARY,
  proto.TaskCommentReplyRequest,
  proto.GenericTaskCommentResponse,
  /**
   * @param {!proto.TaskCommentReplyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskCommentResponse.deserializeBinary
);


/**
 * @param {!proto.TaskCommentReplyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.replyForTaskComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/ReplyForTaskComment',
      request,
      metadata || {},
      methodDescriptor_Tasks_ReplyForTaskComment,
      callback);
};


/**
 * @param {!proto.TaskCommentReplyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskCommentResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.replyForTaskComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/ReplyForTaskComment',
      request,
      metadata || {},
      methodDescriptor_Tasks_ReplyForTaskComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskCommentUpdateRequest,
 *   !proto.GenericTaskCommentResponse>}
 */
const methodDescriptor_Tasks_UpdateTaskComment = new grpc.web.MethodDescriptor(
  '/Tasks/UpdateTaskComment',
  grpc.web.MethodType.UNARY,
  proto.TaskCommentUpdateRequest,
  proto.GenericTaskCommentResponse,
  /**
   * @param {!proto.TaskCommentUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskCommentResponse.deserializeBinary
);


/**
 * @param {!proto.TaskCommentUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.updateTaskComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/UpdateTaskComment',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateTaskComment,
      callback);
};


/**
 * @param {!proto.TaskCommentUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskCommentResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.updateTaskComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/UpdateTaskComment',
      request,
      metadata || {},
      methodDescriptor_Tasks_UpdateTaskComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.EntityId,
 *   !proto.GenericTaskCommentResponse>}
 */
const methodDescriptor_Tasks_DeleteTaskComment = new grpc.web.MethodDescriptor(
  '/Tasks/DeleteTaskComment',
  grpc.web.MethodType.UNARY,
  proto.EntityId,
  proto.GenericTaskCommentResponse,
  /**
   * @param {!proto.EntityId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericTaskCommentResponse.deserializeBinary
);


/**
 * @param {!proto.EntityId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericTaskCommentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericTaskCommentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.deleteTaskComment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/DeleteTaskComment',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTaskComment,
      callback);
};


/**
 * @param {!proto.EntityId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericTaskCommentResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.deleteTaskComment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/DeleteTaskComment',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTaskComment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaskId,
 *   !proto.TaskCommentListResponse>}
 */
const methodDescriptor_Tasks_GetTaskComments = new grpc.web.MethodDescriptor(
  '/Tasks/GetTaskComments',
  grpc.web.MethodType.UNARY,
  proto.TaskId,
  proto.TaskCommentListResponse,
  /**
   * @param {!proto.TaskId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TaskCommentListResponse.deserializeBinary
);


/**
 * @param {!proto.TaskId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.TaskCommentListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TaskCommentListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TasksClient.prototype.getTaskComments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Tasks/GetTaskComments',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetTaskComments,
      callback);
};


/**
 * @param {!proto.TaskId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TaskCommentListResponse>}
 *     Promise that resolves to the response
 */
proto.TasksPromiseClient.prototype.getTaskComments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Tasks/GetTaskComments',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetTaskComments);
};


module.exports = proto;

