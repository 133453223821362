var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:['cursor-pointer py-md-1'],style:({
        backgroundColor: _vm.getCardColors(_vm.cardData)['bgColor'],
        borderColor: _vm.getCardColors(_vm.cardData)['borderColor'],
      }),attrs:{"outlined":""},on:{"mouseover":function($event){_vm.isCardHovered = true},"mouseout":function($event){_vm.isCardHovered = false},"click":_vm.handleClick}},[_c('v-card-text',{staticClass:"d-flex flex-column align-center justify-center"},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.smAndDown ? 35 : 55,"color":_vm.getCardColors(_vm.cardData)['iconColor']}},[_vm._v(" "+_vm._s(_vm.cardData.icon)+" ")]),_c('div',{class:[
            'mt-2',
            _vm.$vuetify.breakpoint.mdAndUp ? 'font-large' : 'font-small' ],style:({ color: _vm.getCardColors(_vm.cardData)['textColor'] })},[_vm._v(" "+_vm._s(_vm.cardData.name)+" ")])],1),_c('v-fade-transition',[(hover && _vm.cardData.id === 'facebook')?_c('v-overlay',{staticClass:"tetx-h6",attrs:{"absolute":"","color":"black"}},[_vm._v(" "+_vm._s(_vm.$t("common.comingSoon"))+" ")]):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }