import UserService from "@/services/userService";
import { getField, updateField } from "vuex-map-fields";
import { vm } from "@/main";

export default {
 namespaced: true,

 state: () => ({
  users: [],
  days: {
   1: { day: 1, startTime: null, stopTime: null },
   2: { day: 2, startTime: null, stopTime: null },
   3: { day: 3, startTime: null, stopTime: null },
   4: { day: 4, startTime: null, stopTime: null },
   5: { day: 5, startTime: null, stopTime: null },
   6: { day: 6, startTime: null, stopTime: null },
   7: { day: 7, startTime: null, stopTime: null },
  },
  workSchedules: [],
 }),

 mutations: {
  updateField,
  setState(state, payload) {
    state[payload.key] = payload.value;
  },
  SET_WORK_SCHEDULES(state, payload) {
   state.workSchedules = payload;
  },
  SET_DAYS(state, payload) {
   state.days = payload;
  },
 },

 actions: {
  async getAllUsers({ state, commit, dispatch }) {
    return UserService
      .getAllUsers()
      .then(response => {
        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        commit("setState", { key: "users", value: response.usersList });
      })
  },
  async getWorkSchedule({ state, commit, dispatch }) {
   try {
    commit("startLoading", null, { root: true });
    const response = await UserService.getWorkSchedule();

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("SET_WORK_SCHEDULES", response.schedulesList);

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async createWorkSchedule({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await UserService.createWorkSchedule(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("SET_WORK_SCHEDULES", [...state.workSchedules, ...response.schedulesList]);

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async updateWorkSchedule({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });

    const response = await UserService.updateWorkSchedule(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    console.log(response);

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },
 },

 getters: {
  getField,
 },
};
