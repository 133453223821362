/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var attachment_pb = require('./attachment_pb.js')

var organization_pb = require('./organization_pb.js')

var user_pb = require('./user_pb.js')
const proto = require('./project_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ProjectsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ProjectsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectInsertRequest,
 *   !proto.GenericProjectResponse>}
 */
const methodDescriptor_Projects_CreateProject = new grpc.web.MethodDescriptor(
  '/Projects/CreateProject',
  grpc.web.MethodType.UNARY,
  proto.ProjectInsertRequest,
  proto.GenericProjectResponse,
  /**
   * @param {!proto.ProjectInsertRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.createProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/CreateProject',
      request,
      metadata || {},
      methodDescriptor_Projects_CreateProject,
      callback);
};


/**
 * @param {!proto.ProjectInsertRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.createProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/CreateProject',
      request,
      metadata || {},
      methodDescriptor_Projects_CreateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectUpdateRequest,
 *   !proto.GenericProjectResponse>}
 */
const methodDescriptor_Projects_UpdateProject = new grpc.web.MethodDescriptor(
  '/Projects/UpdateProject',
  grpc.web.MethodType.UNARY,
  proto.ProjectUpdateRequest,
  proto.GenericProjectResponse,
  /**
   * @param {!proto.ProjectUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.updateProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/UpdateProject',
      request,
      metadata || {},
      methodDescriptor_Projects_UpdateProject,
      callback);
};


/**
 * @param {!proto.ProjectUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.updateProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/UpdateProject',
      request,
      metadata || {},
      methodDescriptor_Projects_UpdateProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListProjectRequest,
 *   !proto.ListProjectsResponse>}
 */
const methodDescriptor_Projects_ListProjects = new grpc.web.MethodDescriptor(
  '/Projects/ListProjects',
  grpc.web.MethodType.UNARY,
  proto.ListProjectRequest,
  proto.ListProjectsResponse,
  /**
   * @param {!proto.ListProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListProjectsResponse.deserializeBinary
);


/**
 * @param {!proto.ListProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListProjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListProjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.listProjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/ListProjects',
      request,
      metadata || {},
      methodDescriptor_Projects_ListProjects,
      callback);
};


/**
 * @param {!proto.ListProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListProjectsResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.listProjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/ListProjects',
      request,
      metadata || {},
      methodDescriptor_Projects_ListProjects);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectId,
 *   !proto.GenericProjectResponse>}
 */
const methodDescriptor_Projects_GetProject = new grpc.web.MethodDescriptor(
  '/Projects/GetProject',
  grpc.web.MethodType.UNARY,
  proto.ProjectId,
  proto.GenericProjectResponse,
  /**
   * @param {!proto.ProjectId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.getProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/GetProject',
      request,
      metadata || {},
      methodDescriptor_Projects_GetProject,
      callback);
};


/**
 * @param {!proto.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.getProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/GetProject',
      request,
      metadata || {},
      methodDescriptor_Projects_GetProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectFilter,
 *   !proto.ListProjectsResponse>}
 */
const methodDescriptor_Projects_GetRecentProject = new grpc.web.MethodDescriptor(
  '/Projects/GetRecentProject',
  grpc.web.MethodType.UNARY,
  proto.ProjectFilter,
  proto.ListProjectsResponse,
  /**
   * @param {!proto.ProjectFilter} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListProjectsResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectFilter} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListProjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListProjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.getRecentProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/GetRecentProject',
      request,
      metadata || {},
      methodDescriptor_Projects_GetRecentProject,
      callback);
};


/**
 * @param {!proto.ProjectFilter} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListProjectsResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.getRecentProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/GetRecentProject',
      request,
      metadata || {},
      methodDescriptor_Projects_GetRecentProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectId,
 *   !proto.GenericProjectResponse>}
 */
const methodDescriptor_Projects_DeleteProject = new grpc.web.MethodDescriptor(
  '/Projects/DeleteProject',
  grpc.web.MethodType.UNARY,
  proto.ProjectId,
  proto.GenericProjectResponse,
  /**
   * @param {!proto.ProjectId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.deleteProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/DeleteProject',
      request,
      metadata || {},
      methodDescriptor_Projects_DeleteProject,
      callback);
};


/**
 * @param {!proto.ProjectId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.deleteProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/DeleteProject',
      request,
      metadata || {},
      methodDescriptor_Projects_DeleteProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.AddToProjectRequest,
 *   !proto.GenericProjectRoleResponse>}
 */
const methodDescriptor_Projects_AddToProject = new grpc.web.MethodDescriptor(
  '/Projects/AddToProject',
  grpc.web.MethodType.UNARY,
  proto.AddToProjectRequest,
  proto.GenericProjectRoleResponse,
  /**
   * @param {!proto.AddToProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectRoleResponse.deserializeBinary
);


/**
 * @param {!proto.AddToProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.addToProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/AddToProject',
      request,
      metadata || {},
      methodDescriptor_Projects_AddToProject,
      callback);
};


/**
 * @param {!proto.AddToProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectRoleResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.addToProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/AddToProject',
      request,
      metadata || {},
      methodDescriptor_Projects_AddToProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.UpdateProjectRoleRequest,
 *   !proto.GenericProjectRoleResponse>}
 */
const methodDescriptor_Projects_UpdateProjectRole = new grpc.web.MethodDescriptor(
  '/Projects/UpdateProjectRole',
  grpc.web.MethodType.UNARY,
  proto.UpdateProjectRoleRequest,
  proto.GenericProjectRoleResponse,
  /**
   * @param {!proto.UpdateProjectRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectRoleResponse.deserializeBinary
);


/**
 * @param {!proto.UpdateProjectRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.updateProjectRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/UpdateProjectRole',
      request,
      metadata || {},
      methodDescriptor_Projects_UpdateProjectRole,
      callback);
};


/**
 * @param {!proto.UpdateProjectRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectRoleResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.updateProjectRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/UpdateProjectRole',
      request,
      metadata || {},
      methodDescriptor_Projects_UpdateProjectRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TransferProjectOwnershipRequest,
 *   !proto.GenericProjectRoleResponse>}
 */
const methodDescriptor_Projects_TransferProjectOwnership = new grpc.web.MethodDescriptor(
  '/Projects/TransferProjectOwnership',
  grpc.web.MethodType.UNARY,
  proto.TransferProjectOwnershipRequest,
  proto.GenericProjectRoleResponse,
  /**
   * @param {!proto.TransferProjectOwnershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectRoleResponse.deserializeBinary
);


/**
 * @param {!proto.TransferProjectOwnershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.transferProjectOwnership =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/TransferProjectOwnership',
      request,
      metadata || {},
      methodDescriptor_Projects_TransferProjectOwnership,
      callback);
};


/**
 * @param {!proto.TransferProjectOwnershipRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectRoleResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.transferProjectOwnership =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/TransferProjectOwnership',
      request,
      metadata || {},
      methodDescriptor_Projects_TransferProjectOwnership);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListProjectRoleRequest,
 *   !proto.ListProjectRolesResponse>}
 */
const methodDescriptor_Projects_ListAllProjectRoles = new grpc.web.MethodDescriptor(
  '/Projects/ListAllProjectRoles',
  grpc.web.MethodType.UNARY,
  proto.ListProjectRoleRequest,
  proto.ListProjectRolesResponse,
  /**
   * @param {!proto.ListProjectRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListProjectRolesResponse.deserializeBinary
);


/**
 * @param {!proto.ListProjectRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListProjectRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListProjectRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.listAllProjectRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/ListAllProjectRoles',
      request,
      metadata || {},
      methodDescriptor_Projects_ListAllProjectRoles,
      callback);
};


/**
 * @param {!proto.ListProjectRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListProjectRolesResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.listAllProjectRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/ListAllProjectRoles',
      request,
      metadata || {},
      methodDescriptor_Projects_ListAllProjectRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListProjectRoleRequest,
 *   !proto.ListProjectRolesResponse>}
 */
const methodDescriptor_Projects_ListActiveProjectRoles = new grpc.web.MethodDescriptor(
  '/Projects/ListActiveProjectRoles',
  grpc.web.MethodType.UNARY,
  proto.ListProjectRoleRequest,
  proto.ListProjectRolesResponse,
  /**
   * @param {!proto.ListProjectRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListProjectRolesResponse.deserializeBinary
);


/**
 * @param {!proto.ListProjectRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListProjectRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListProjectRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.listActiveProjectRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/ListActiveProjectRoles',
      request,
      metadata || {},
      methodDescriptor_Projects_ListActiveProjectRoles,
      callback);
};


/**
 * @param {!proto.ListProjectRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListProjectRolesResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.listActiveProjectRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/ListActiveProjectRoles',
      request,
      metadata || {},
      methodDescriptor_Projects_ListActiveProjectRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListProjectRoleRequest,
 *   !proto.ListProjectRolesResponse>}
 */
const methodDescriptor_Projects_ListInvitedProjectRoles = new grpc.web.MethodDescriptor(
  '/Projects/ListInvitedProjectRoles',
  grpc.web.MethodType.UNARY,
  proto.ListProjectRoleRequest,
  proto.ListProjectRolesResponse,
  /**
   * @param {!proto.ListProjectRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListProjectRolesResponse.deserializeBinary
);


/**
 * @param {!proto.ListProjectRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListProjectRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListProjectRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.listInvitedProjectRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/ListInvitedProjectRoles',
      request,
      metadata || {},
      methodDescriptor_Projects_ListInvitedProjectRoles,
      callback);
};


/**
 * @param {!proto.ListProjectRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListProjectRolesResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.listInvitedProjectRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/ListInvitedProjectRoles',
      request,
      metadata || {},
      methodDescriptor_Projects_ListInvitedProjectRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectRoleId,
 *   !proto.GenericProjectRoleResponse>}
 */
const methodDescriptor_Projects_GetProjectRole = new grpc.web.MethodDescriptor(
  '/Projects/GetProjectRole',
  grpc.web.MethodType.UNARY,
  proto.ProjectRoleId,
  proto.GenericProjectRoleResponse,
  /**
   * @param {!proto.ProjectRoleId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectRoleResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectRoleId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.getProjectRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/GetProjectRole',
      request,
      metadata || {},
      methodDescriptor_Projects_GetProjectRole,
      callback);
};


/**
 * @param {!proto.ProjectRoleId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectRoleResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.getProjectRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/GetProjectRole',
      request,
      metadata || {},
      methodDescriptor_Projects_GetProjectRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectRoleId,
 *   !proto.GenericProjectRoleResponse>}
 */
const methodDescriptor_Projects_RemoveFromProject = new grpc.web.MethodDescriptor(
  '/Projects/RemoveFromProject',
  grpc.web.MethodType.UNARY,
  proto.ProjectRoleId,
  proto.GenericProjectRoleResponse,
  /**
   * @param {!proto.ProjectRoleId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectRoleResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectRoleId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.removeFromProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/RemoveFromProject',
      request,
      metadata || {},
      methodDescriptor_Projects_RemoveFromProject,
      callback);
};


/**
 * @param {!proto.ProjectRoleId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectRoleResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.removeFromProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/RemoveFromProject',
      request,
      metadata || {},
      methodDescriptor_Projects_RemoveFromProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ReplyToInvitationFromEmailRequest,
 *   !proto.GenericProjectRoleResponse>}
 */
const methodDescriptor_Projects_AcceptProjectInvitationFromEmail = new grpc.web.MethodDescriptor(
  '/Projects/AcceptProjectInvitationFromEmail',
  grpc.web.MethodType.UNARY,
  organization_pb.ReplyToInvitationFromEmailRequest,
  proto.GenericProjectRoleResponse,
  /**
   * @param {!proto.ReplyToInvitationFromEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectRoleResponse.deserializeBinary
);


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.acceptProjectInvitationFromEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/AcceptProjectInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Projects_AcceptProjectInvitationFromEmail,
      callback);
};


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectRoleResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.acceptProjectInvitationFromEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/AcceptProjectInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Projects_AcceptProjectInvitationFromEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ReplyToInvitationFromEmailRequest,
 *   !proto.GenericProjectRoleResponse>}
 */
const methodDescriptor_Projects_RejectProjectInvitationFromEmail = new grpc.web.MethodDescriptor(
  '/Projects/RejectProjectInvitationFromEmail',
  grpc.web.MethodType.UNARY,
  organization_pb.ReplyToInvitationFromEmailRequest,
  proto.GenericProjectRoleResponse,
  /**
   * @param {!proto.ReplyToInvitationFromEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectRoleResponse.deserializeBinary
);


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.rejectProjectInvitationFromEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/RejectProjectInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Projects_RejectProjectInvitationFromEmail,
      callback);
};


/**
 * @param {!proto.ReplyToInvitationFromEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectRoleResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.rejectProjectInvitationFromEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/RejectProjectInvitationFromEmail',
      request,
      metadata || {},
      methodDescriptor_Projects_RejectProjectInvitationFromEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ProjectRoleId,
 *   !proto.GenericProjectRoleResponse>}
 */
const methodDescriptor_Projects_AcceptInvitationToProject = new grpc.web.MethodDescriptor(
  '/Projects/AcceptInvitationToProject',
  grpc.web.MethodType.UNARY,
  proto.ProjectRoleId,
  proto.GenericProjectRoleResponse,
  /**
   * @param {!proto.ProjectRoleId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectRoleResponse.deserializeBinary
);


/**
 * @param {!proto.ProjectRoleId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.acceptInvitationToProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/AcceptInvitationToProject',
      request,
      metadata || {},
      methodDescriptor_Projects_AcceptInvitationToProject,
      callback);
};


/**
 * @param {!proto.ProjectRoleId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectRoleResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.acceptInvitationToProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/AcceptInvitationToProject',
      request,
      metadata || {},
      methodDescriptor_Projects_AcceptInvitationToProject);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RejectInvitationToProjectRequest,
 *   !proto.GenericProjectRoleResponse>}
 */
const methodDescriptor_Projects_RejectInvitationToProject = new grpc.web.MethodDescriptor(
  '/Projects/RejectInvitationToProject',
  grpc.web.MethodType.UNARY,
  proto.RejectInvitationToProjectRequest,
  proto.GenericProjectRoleResponse,
  /**
   * @param {!proto.RejectInvitationToProjectRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericProjectRoleResponse.deserializeBinary
);


/**
 * @param {!proto.RejectInvitationToProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericProjectRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericProjectRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ProjectsClient.prototype.rejectInvitationToProject =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Projects/RejectInvitationToProject',
      request,
      metadata || {},
      methodDescriptor_Projects_RejectInvitationToProject,
      callback);
};


/**
 * @param {!proto.RejectInvitationToProjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericProjectRoleResponse>}
 *     Promise that resolves to the response
 */
proto.ProjectsPromiseClient.prototype.rejectInvitationToProject =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Projects/RejectInvitationToProject',
      request,
      metadata || {},
      methodDescriptor_Projects_RejectInvitationToProject);
};


module.exports = proto;

