import Vue from "vue";
import VueRouter from "vue-router";

/* PAGE IMPORTS */
import LoginView from "@/views/Login/LoginView.vue";
import AuthLayout from "@/layouts//AuthLayout.vue";
import NotFound from "@/views/404/404.vue";

import { isUserLoggedIn } from "./routerHelpers";

Vue.use(VueRouter);

const CHECK_AUTH = (next) => {
  if (isUserLoggedIn()) {
    return next();
  } else next("/login");
};

const routes = [
  {
    path: "*",
    name: "404",
    component: NotFound,
  },
  {
    path: "/confirm/:token/:uidb",
    name: "confirm",
    component: () => import(/* webpackChunkName: "Signup" */ "@/views/Confirm/ConfirmView.vue"),
  },
  {
    path: "/privacypolicy",
    name: "privacy policy",
    component: () => import(/* webpackChunkName: "Privacy" */ "@/views/TandC/Privacy.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import(/* webpackChunkName: "Terms" */ "@/views/TandC/Terms.vue"),
  },
  {
    path: "/organization_invitation/:type/:token/:uidb",
    name: "organization invitation",
    component: () => import(/* webpackChunkName: "Invite organization" */ "@/views/Organisations/EmailInvitation.vue"),
  },
  {
    path: "/project_invitation/:type/:token/:uidb",
    name: "project invitation",
    component: () => import(/* webpackChunkName: "Invite project" */ "@/views/Projects/ProjectEmailInvitation.vue"),
  },

  {
    path: "/task_invitation/:type/:token/:uidb",
    name: "task invitation",
    component: () => import(/* webpackChunkName: "Invite task" */ "@/views/Tasks/TaskInvitation.vue"),
  },

  {
    path: "/home",
    redirect: "/home/organisations",
  },

  {
    path: "/",
    name: "index",
    component: AuthLayout,
    redirect: "login",
    children: [
      {
        path: "login",
        name: "login",
        component: LoginView,
        beforeEnter: (to, from, next) => {
          if (isUserLoggedIn()) {
            return next("/home");
          } else next();
        },
      },
      {
        path: "signup/:type?/:flowtype?/:token?/:uidb?",
        name: "signup",
        component: () => import(/* webpackChunkName: "Signup" */ "@/views/SignUp/SignUpView.vue"),
        beforeEnter: (to, from, next) => {
          if (isUserLoggedIn()) {
            return next("/home");
          } else next();
        },
      },
      {
        path: "forgotpassword/:token?/:uidb?",
        name: "forgotpassword",
        component: () => import(/* webpackChunkName: "Forgot password" */ "@/views/Forgot/Forgot.vue"),
        beforeEnter: (to, from, next) => {
          if (isUserLoggedIn()) {
            return next("/home");
          } else next();
        },
      },
    ],
  },
  {
    path: "/home",
    name: "Home",
    component: () => import(/* webpackChunkName: "Homepage" */ "@/layouts/HomeLayout.vue"),
    beforeEnter: (to, from, next) => {
      CHECK_AUTH(next);
    },
    children: [
      {
        path: "organisations",
        name: "My Organizations",
        meta: {
          id: "organizations-page",
          subtitle: "All your organizations are listed here",
        },
        component: () => import(/* webpackChunkName: "Orgs" */ "@/views/Organisations/OrganisationsView.vue"),
      },

      {
        path: "organization/details/:organisation_id/:org_name?",
        name: "Organization details",
        meta: {
          id: "organization-details",
          subtitle: "All your organizations are listed here",
        },
        component: () => import(/* webpackChunkName: "Org Details" */ "@/views/Organisations/OrganizationDetailsView.vue"),
      },

      {
        path: "projects/:organisation_id?",
        name: "My Projects",
        meta: {
          id: "projects-page",
          subtitle: "All your prijects are listed here",
        },
        component: () => import(/* webpackChunkName: "rojects" */ "@/views/Projects/ProjectsView.vue"),
      },

      {
        path: "project/details/:project_id",
        name: "Project details",
        meta: {
          id: "project-details",
          subtitle: "All your projects details are listed here",
        },
        component: () => import(/* webpackChunkName: "Project details" */ "@/views/Projects/ProjectDetailsView.vue"),
      },

      {
        path: "project/archived/:project_id",
        name: "Archived tasks",
        meta: {
          id: "archived-tasks",
          subtitle: "Archived tasks",
        },
        component: () => import(/* webpackChunkName: "Archived tasks" */ "@/views/Tasks/ArchivedTasksView.vue"),
      },

      {
        path: "project/attachments/:projectId",
        name: "Project attachments",
        meta: {
          id: "attachments-page",
          subtitle: "Your project attachments",
        },
        component: () => import(/* webpackChunkName: "Project attachments" */ "@/views/Projects/ProjectAttachments.vue"),
      },

      {
        path: "tasks/:project_id?",
        name: "My Tasks",
        meta: {
          id: "tasks-page",
          subtitle: "All your tasks are listed here",
        },
        component: () => import(/* webpackChunkName: "Tasks" */ "@/views/Tasks/TasksView.vue"),
      },

      {
        path: "task/details/:entityId/:projectId?/:organizationId?",
        name: "Task details",
        meta: {
          id: "task-details-page",
          subtitle: "Task details",
        },
        component: () => import(/* webpackChunkName: "Task Details" */ "@/views/Tasks/TaskDetailsView.vue"),
      },

      {
        path: "kanban",
        name: "My Kanban",
        meta: {
          id: "kanban-page",
          subtitle: "Your kanban board",
        },
        component: () => import(/* webpackChunkName: "Kanban" */ "@/views/Kanban/KanbanView.vue"),
      },

      {
        path: "manage-tasks",
        name: "View Tasks",
        meta: {
          id: "view-tasks-page",
          subtitle: "View tasks page",
        },
        component: () => import(/* webpackChunkName: "View Tasks Page" */ "@/views/Tasks/AllTasks.vue"),
      },

      {
        path: "manage-tasks-test",
        name: "View Tasks",
        meta: {
          id: "view-tasks-page",
          subtitle: "View tasks page",
        },
        component: () => import(/* webpackChunkName: "View Tasks Page" */ "@/views/Tasks/Test.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
