<template>
  <div class="pt-1">
    <div class="d-flex" v-for="(link, index) in links" :key="index">
      <router-link :to="link.to" :class="['text-decoration-none cursor-pointer font-weight-medium ', drawer ? 'fade-in' : '']" exact v-slot="{ isExactActive }" :disabled="true">
        <div :class="[isExactActive && 'primaryLink--text', 'd-flex align-center justify-center']" @click="navigate(link)">
          <v-icon small :color="isExactActive ? 'primary' : 'gray'" class="my-3">
            {{ link.icon }}
          </v-icon>

          <span v-if="drawer" :class="['px-3 font-small', isExactActive ? 'primary--text' : 'primaryLink--text']">
            {{ link.name }}
          </span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
 computed: {
  links() {
   const links = [
      {
        name: this.$t("sidebar.organizations"),
        to: "/home/organisations",
        icon: "mdi-briefcase-outline",
      },
      {
        name: this.$t("sidebar.projects"),
        to: "/home/projects",
        icon: "mdi-package",
      },
      {
        name: this.$t("sidebar.tasks"),
        to: "/home/tasks",
        icon: "mdi-calendar-check",
      },
    ];

    if (this.environment !== "PRODUCTION") {
      links.push({
        name: this.$t("sidebar.manageTasks"),
        to: "/home/manage-tasks",
        icon: "mdi-view-dashboard-variant",
      });
    }

    return links;
  },

  drawer() {
    return this.$store.getters.drawer;
  },

  loading() {
    return this.$store.getters.loading;
  }
 },
 methods: {
  navigate(link) {
   if (this.loading) return;

   this.$router.push({ path: link.to }).catch(() => console.warn("."));
  },
 }
}
</script>