<template>
  <v-app id="app">
    <!-- Will only show up in small devices becausethey need it for navigation -->
    <!-- <sidebar v-if="$vuetify.breakpoint.smAndDown" /> -->

    <notifications />
    <v-main>
      <app-loader />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <!-- Feedback button -->
    <div class="feedback-container">
      <v-menu v-model="menu" :close-on-content-click="false" nudge-left="20" left offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary" class="ma-2 white--text" fab small>
            <v-icon small dark> mdi-comment-quote </v-icon>
          </v-btn>
        </template>

        <v-card height="440">
          <v-card-text class="pa-0">
            <iframe
              id="zsfeedbackFrame"
              height="250px"
              name="zsfeedbackFrame"
              scrolling="no"
              allowtransparency="false"
              frameborder="0"
              src="https://desk.zoho.com/support/fbw?formType=AdvancedWebForm&fbwId=edbsnb18202b8481df52fca42b33d3b5f23181d6db457466bbb89be56a981cb51bcc0&xnQsjsdp=edbsn7b195a837f58346e0d21802a825622aa&mode=showNewWidget&displayType=iframe"
            ></iframe>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </v-app>
</template>

<script>
import { getData } from "@/services/localService";
import AppLoader from "./components/Global/AppLoader.vue";

export default {
  name: "App",
  components: {
    AppLoader,
  },
  data: () => ({
    menu: false,
  }),

  created() {
    this.setEnvironment();
    this.setLocale();
    this.setTheme();
    this.setUser();
    this.getBackendVersion();
  },

  methods: {
    setEnvironment() {
      this.$store.commit("setEnvironment");
    },
    /* This method will set the persisted language chosen by the user */
    setLocale() {
      const localeData = getData("locale");
      if (localeData && localeData.locale) {
        this.$store.commit("changeLanguage", localeData.locale);
      }
    },

    setTheme() {
      const theme = getData("dark");
      if (theme) {
        this.$vuetify.theme.dark = theme;
        this.$store.commit("setTheme", theme);
      }
    },

    setUser() {
      const userInfo = getData("user");
      if (userInfo) {
        this.$store.dispatch("setUserState", userInfo, { root: true });
      }
    },

    getBackendVersion() {
      this.$store.dispatch("getApiVersion");
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/main.scss";
@import "./assets/styles/override.scss";
@import "./assets/styles/scrollbar.scss";
@import "~shepherd.js/dist/css/shepherd.css";

.shepherd-theme-custom {
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  width: 200px;

  .shepherd-text {
    padding: 10px;
    font-size: 12px;
    h3 {
      margin-bottom: 10px;
      color: black;
      font-weight: 600;
      font-size: 14px;
    }
  }

  .shepherd-content {
    background: #b69cff;
    border-radius: 10px;
  }

  .shepherd-arrow {
    &::before {
      background: #b69cff;
    }
  }

  .shepherd-button {
    background: #1e2e5e;
    height: 30px;
    font-size: 12px;
    padding: 0px 10px;
    color: white;
    font-weight: 500;
    &:hover {
      background: #1e2e5e;
    }
  }
}

.feedback-container {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 15px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 1000s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: gray !important;
}
</style>
