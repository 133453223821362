<template>
  <app-theme-toggle @change="setTheme" />
</template>

<script>
import AppThemeToggle from './AppThemeToggle.vue';

export default {
  components: { AppThemeToggle },
  
  props: {
    showIcons: {
      type: Boolean,
      default: () => true,
    },
  },

  methods: {
    setTheme() { 
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$store.commit("setTheme", this.$vuetify.theme.dark);
    },
  },
};
</script>