import ProjectsService from "@/services/projectsService";
import { getField, updateField } from "vuex-map-fields";
import { vm } from "@/main";

export default {
  namespaced: true,
  state: () => ({
    createProjectDialog: false,
    deleteProjectDialog: false,
    selectedProjectForDelete: null,
    selectedProjectForEdit: null,
    isProjectEditing: false,
    allProjects: [],

    /* State for the create project dialog form */
    entityId: "",
    projectName: "",
    projectCode: "",
    projectDescription: "",
    searchStr: "",
    deadlineDate: null,
    files: [],
    links: "",
    addedLinks: [],
    addedMembers: [],
    nonOrganizationMembers: [],
    freezeOrganizationDropdown: true,
    showAllOrganizations: true,
    showProjectCreatedDialog: false,

    /* State for project details page */
    projectDetailsLoading: false,
    projectManager: null,
    projectDetails: {},
    hideDetails: false,
    deadlineModal: false,
    projectDescriptionEditDialog: false,
    removeMemberProjectDialog: false,
    selectedProjectMemberForDelete: null,
    viewingAs: null,
    newUserDialog: false,
    showDoneDialog: false,

    /* State for add people dialog in project details */
    selectedRole: null,
    allPeople: [],
    memberEmail: "",
    addPeopleDialog: false,
  }),
  mutations: {
    updateField,
    setState(state, payload) {
      state[payload.key] = payload.value;
    },
    RESET_PROJECT_STATE(state) {
      state.projectName = "";
      state.projectCode = "";
      state.createProjectDialog = false;
      state.projectDescription = "";
      state.searchStr = "";
      state.deadlineDate = null;
      state.files = [];
      state.links = "";
      state.addedLinks = [];
      state.addedMembers = [];
      state.nonOrganizationMembers = [];
    },
  },
  actions: {
    async getProjects({ state, commit, dispatch }, org_id = "") {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.getProjects(org_id);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        commit("setState", { key: "allProjects", value: response.projectsList });

        return response.projectsList;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async createProject({ state, commit, dispatch }, org_id) {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.createProject({
          name: state.projectName,
          code: state.projectCode,
          organisationId: org_id,
          description: state.projectDescription,
          deadline: state.deadlineDate,
          organization_members: state.addedMembers.map((el) => el.userEmail).join(),
          non_organization_members: state.nonOrganizationMembers.map((el) => el.userEmail).join(),
          attachments: state.files,
        });

        console.log(response);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

        commit("setState", { key: "allProjects", value: [...state.allProjects, response.project] });

        commit("RESET_PROJECT_STATE");

        return response.project;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async deleteProject({ state, commit, dispatch }) {
      try {
        commit("startLoading", null, { root: true });
        const response = await ProjectsService.deleteProject({
          entity_id: state.selectedProjectForDelete.entityId,
        });

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

        const updatedProjects = state.allProjects;
        updatedProjects.splice(state.selectedProjectForDelete.index, 1);

        commit("setState", { key: "allProjects", value: [...updatedProjects] });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async removeMemberFromProject({ state, commit, dispatch }) {
      try {
        commit("startLoading", null, { root: true });

        const payload = {
          user_id: state.selectedProjectMemberForDelete.userId,
          project_id: state.selectedProjectMemberForDelete.projectId,
        };

        const response = await ProjectsService.removeMemberFromProject(payload);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

        const updatedMembers = state.allPeople.filter((member) => member.userEmail !== state.selectedProjectMemberForDelete.userEmail);

        commit("setState", { key: "allPeople", value: [...updatedMembers] });
        commit("setState", { key: "removeMemberProjectDialog", value: false });
        commit("setState", { key: "selectedProjectMemberForDelete", value: null });
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async updateProject({ state, commit, dispatch }) {
      try {
        commit("startLoading", null, { root: true });

        console.log(state);

        const response = await ProjectsService.updateProject({
          name: state.projectName,
          links: state.addedLinks.join(","),
          description: state.projectDescription,
          deadline: state.deadlineDate,
          entity_id: state.entityId,
        });

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

        if (state.selectedProjectForEdit) {
          let updatedProjects = state.allProjects;
          updatedProjects[state.selectedProjectForEdit.index] = response.project;

          commit("setState", { key: "allProjects", value: [...updatedProjects] });
        }

        commit("RESET_PROJECT_STATE");
        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getProjectDetails({ state, commit, dispatch }, project_id) {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.getProjectDetails({
          entity_id: project_id,
        });

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        commit("setState", { key: "projectDetails", value: response.project });

        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async getProjectMembers({ state, commit, dispatch }, projectId) {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.getProjectMembers({
          project_id: projectId,
        });

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        const manager = response.projectRolesList.find((el) => el.role === "manager");
        commit("setState", { key: "projectManager", value: manager });

        commit("setState", { key: "allPeople", value: response.projectRolesList });

        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async addMemberToProject({ state, commit, dispatch }, projectId) {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.addMemberToProject({
          role: state.selectedRole.id,
          project_id: projectId,
          userEmail: state.memberEmail.userEmail,
        });

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

        commit("setState", { key: "allPeople", value: [...state.allPeople, response.projectRole] });

        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async acceptProjectInvitationFromEmail({ state, commit, dispatch }, data) {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.acceptInvitationToProjectFromEmail(data);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async rejectProjectInvitationFromEmail({ state, commit, dispatch }, data) {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.rejectInvitationToProjectFromEmail(data);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async acceptProjectInvitationFromNotification({ state, commit, dispatch }, data) {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.acceptInvitationToProjectFromNotification(data);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async rejectProjectInvitationFromNotification({ state, commit, dispatch }, data) {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.rejectInvitationToProjectFromNotification(data);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async updateUserRoleInProject({ state, commit, dispatch }, data) {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.updateUserRoleInProject(data);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });

        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },

    async transferProjectOwnership({ state, commit, dispatch }, data) {
      try {
        commit("startLoading", null, { root: true });

        const response = await ProjectsService.transferProjectOwnership(data);

        if (!response.success) {
          return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
        }

        dispatch("showMessage", { message: response.message, messageType: "success", messageTitle: vm.$t("success.success") }, { root: true });
        return true;
      } catch (e) {
        console.log(e);
      } finally {
        commit("stopLoading", null, { root: true });
      }
    },
  },
  getters: {
    getField,
    projects: (state) => state.allProjects,
  },
};
