import ProjectsMixin from "../Mixins/ProjectMixin";

export default {
 mixins: [ProjectsMixin],
 computed: {
  date() {
   return new Date();
  },

  createButtonConfig() {
   switch (this.$route.meta.id) {
    case "dashboard-page":
     return {
      showBackButton: false,
      show: true,
      text: this.$t("buttons.create"),
      buttonClick: () => {
       console.log("this will open joint dialog");
      },
     };

    case "projects-page":
     return {
      showBackButton: false,
      show: this.allProjects.length ? true : false,
      text: this.$t("buttons.createProject"),
      buttonClick: () => {
       this.handleCreateProject();
      },
     };

    case "organizations-page":
     return {
      showBackButton: false,
      show: this.allOrganisations.length ? true : false,
      text: this.$t("buttons.createOrganization"),
      buttonClick: () => {
       this.createDialog = true;
      },
     };

    case "organization-details":
     return {
      showBackButton: true,
      show: false,
      text: this.$t("buttons.createOrganization"),
      buttonClick: () => {
       this.createDialog = true;
      },
     };

    case "project-details":
     return {
      showBackButton: true,
      show: true,
      text: this.$t("buttons.createTask"),
      buttonClick: () => {
       if (this.$route.name === "Project details") {
        this.$router.replace({ path: `${this.$route.path}?source=projectDetailsPage` }).catch((e) => console.warn("."));
       }
       this.createTaskDialog = true;
      },
     };

    case "tasks-page":
     return {
      showBackButton: false,
      show: this.myTasks.length ? true : false,
      text: this.$t("buttons.createTask"),
      buttonClick: () => {
       if (this.$route.name === "My Tasks") {
        this.$router.replace({ path: `${this.$route.path}?source=myTasksPage` }).catch((e) => console.warn("."));
       }
       this.createTaskDialog = true;
      },
     };

    case "task-details-page":
     return {
      showBackButton: true,
      show: false,
      text: this.$t("buttons.createTask"),
      buttonClick: () => {
       console.log("project", this.date);
      },
     };

    default:
     return {
      showBackButton: false,
      show: true,
      text: this.$t("buttons.create"),
      buttonClick: () => {
       console.log("create", this.date);
      },
     };
   }
  },
  getTranslation() {
   return (name) => {
    switch (name) {
     case "My Organizations":
      return {
       main: this.$t("organizations.myOrganizations"),
       subtitle: this.$t("organizations.all"),
      };

     case "Organization details":
      let org_name = this.$route.params.org_name || this.$route.query.org_name;

      return {
       main: org_name,
       subtitle: this.$t("organizations.organizationDetailsSub", { org_name }),
      };

     case "Project details":
      return {
       main: this.projectDetails.name,
       subtitle: this.projectDetails.name && `${this.$t("projects.projectDetailsSubMessage")} ${this.$t("common.of")} ${this.projectDetails.name}`,
      };

     case "My Tasks":
      return {
       main: this.$t("tasks.myTasks"),
       subtitle: this.$t("tasks.all"),
      };

     case "Task details":
      return {
       main: this.chosenTaskDetails.title,
       subtitle: this.chosenTaskDetails.projectName && this.chosenTaskDetails.projectName.slice(0, 3).toUpperCase() + "-" + this.chosenTaskDetails.taskNumber,
      };

     case "My Projects":
      return {
       main: this.$t("projects.myProjects"),
       subtitle: this.$t("projects.all"),
      };

     case "My Kanban":
      return {
       main: this.$t("kanban.myKanban"),
       subtitle: this.$t("kanban.all"),
      };
    }
   };
  },
 },
 methods: {
  handleClick() {
   this.createButtonConfig["buttonClick"]();
  },
 },
};
