<template>
  <div class="d-flex">
    <v-avatar :size="drawer ? 40 : 23" color="primary" tile :class="[drawer ? 'rounded-lg' : 'rounded']">
      <span :class="['primaryText--text', drawer ? 'text-h6' : 'font-small']">{{ text }}</span>
    </v-avatar>

    <div v-if="drawer" :class="['d-flex flex-column justify-center ps-2', drawer ? 'fade-in' : '']">
      <small class="secondaryText--text font-x-small text-capitalize">{{ fullName }}</small>
      <app-global-dropdown-list v-if="showDropdown" @profile-clicked="profileClicked" :label="$t('common.viewProfile')" :items="items" :renderIcon="false" />
    </div>
  </div>
</template>

<script>
const AppGlobalDropdownList = () => import("./AppDropdown.vue") 

export default {
  components: { AppGlobalDropdownList },

  props: {
    text: {
      type: String,
      default: () => "XX",
    },

    showDropdown: {
      type: Boolean,
      default: () => true
    },

    fullName: {
      type: String,
      default: () => ''
    }
  },

  computed: {
    drawer() {
      return this.$store.getters.drawer;
    },
  },

  data() {
    return {
      items: [
        {
          icon: "mdi-account",
          title: this.$t("common.editProfile"),
          id: "profile",
        },
      ]
    }
  },

  methods: {
    profileClicked() {
      this.$store.commit("setState", { key: "profileModal", value: true });
    }
  }
};
</script>