export default {
  computed: {
    organisationId() {
      return this.$route.params.organisation_id;
    },

    loading() {
      return this.$store.getters.loading;
    },

    currentUser() {
      return this.$store.getters.userInformation;
    },
  },

  methods: {
    handleCreateProject() {
      this.createProjectDialog = true;
    },

    setProjectData(data) {
      this.entityId = data.entityId;
      this.projectName = data.name;
      this.projectDescription = data.description;
      this.deadlineDate = data.deadline;
    },

    editProject(index, data) {
      this.isProjectEditing = true;
      this.freezeOrganizationDropdown = true;
      this.selectedProjectForEdit = { ...data, index };
      this.setProjectData(data);
      this.createProjectDialog = true;
    },

    openProjectDeleteDialog(index, data) {
      this.selectedProjectForDelete = { ...data, index };
      this.deleteProjectDialog = true;
    },

    deleteProject() {
      this.$store.dispatch("projects/deleteProject");
      this.$store.dispatch("tasks/deletePriorityList", { managerId: this.currentUser.entity_id });
      this.deleteProjectDialog = false;
    },
  },
};
