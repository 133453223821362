/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = require('./notification_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.NotificationsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.NotificationsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.NotificationUpdateRequest,
 *   !proto.GenericNotificationResponse>}
 */
const methodDescriptor_Notifications_UpdateNotification = new grpc.web.MethodDescriptor(
  '/Notifications/UpdateNotification',
  grpc.web.MethodType.UNARY,
  proto.NotificationUpdateRequest,
  proto.GenericNotificationResponse,
  /**
   * @param {!proto.NotificationUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.NotificationUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NotificationsClient.prototype.updateNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Notifications/UpdateNotification',
      request,
      metadata || {},
      methodDescriptor_Notifications_UpdateNotification,
      callback);
};


/**
 * @param {!proto.NotificationUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.NotificationsPromiseClient.prototype.updateNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Notifications/UpdateNotification',
      request,
      metadata || {},
      methodDescriptor_Notifications_UpdateNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ListNotificationsRequest,
 *   !proto.ListNotificationsResponse>}
 */
const methodDescriptor_Notifications_ListNotifications = new grpc.web.MethodDescriptor(
  '/Notifications/ListNotifications',
  grpc.web.MethodType.UNARY,
  proto.ListNotificationsRequest,
  proto.ListNotificationsResponse,
  /**
   * @param {!proto.ListNotificationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ListNotificationsResponse.deserializeBinary
);


/**
 * @param {!proto.ListNotificationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ListNotificationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ListNotificationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NotificationsClient.prototype.listNotifications =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Notifications/ListNotifications',
      request,
      metadata || {},
      methodDescriptor_Notifications_ListNotifications,
      callback);
};


/**
 * @param {!proto.ListNotificationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ListNotificationsResponse>}
 *     Promise that resolves to the response
 */
proto.NotificationsPromiseClient.prototype.listNotifications =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Notifications/ListNotifications',
      request,
      metadata || {},
      methodDescriptor_Notifications_ListNotifications);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.NotificationId,
 *   !proto.GenericNotificationResponse>}
 */
const methodDescriptor_Notifications_GetNotification = new grpc.web.MethodDescriptor(
  '/Notifications/GetNotification',
  grpc.web.MethodType.UNARY,
  proto.NotificationId,
  proto.GenericNotificationResponse,
  /**
   * @param {!proto.NotificationId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.NotificationId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NotificationsClient.prototype.getNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Notifications/GetNotification',
      request,
      metadata || {},
      methodDescriptor_Notifications_GetNotification,
      callback);
};


/**
 * @param {!proto.NotificationId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.NotificationsPromiseClient.prototype.getNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Notifications/GetNotification',
      request,
      metadata || {},
      methodDescriptor_Notifications_GetNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.NotificationId,
 *   !proto.GenericNotificationResponse>}
 */
const methodDescriptor_Notifications_DeleteNotification = new grpc.web.MethodDescriptor(
  '/Notifications/DeleteNotification',
  grpc.web.MethodType.UNARY,
  proto.NotificationId,
  proto.GenericNotificationResponse,
  /**
   * @param {!proto.NotificationId} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.NotificationId} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NotificationsClient.prototype.deleteNotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Notifications/DeleteNotification',
      request,
      metadata || {},
      methodDescriptor_Notifications_DeleteNotification,
      callback);
};


/**
 * @param {!proto.NotificationId} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.NotificationsPromiseClient.prototype.deleteNotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Notifications/DeleteNotification',
      request,
      metadata || {},
      methodDescriptor_Notifications_DeleteNotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.EmptyRequestArgument,
 *   !proto.GenericNotificationResponse>}
 */
const methodDescriptor_Notifications_DeleteAllNotificationsForUser = new grpc.web.MethodDescriptor(
  '/Notifications/DeleteAllNotificationsForUser',
  grpc.web.MethodType.UNARY,
  proto.EmptyRequestArgument,
  proto.GenericNotificationResponse,
  /**
   * @param {!proto.EmptyRequestArgument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GenericNotificationResponse.deserializeBinary
);


/**
 * @param {!proto.EmptyRequestArgument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.GenericNotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GenericNotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.NotificationsClient.prototype.deleteAllNotificationsForUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Notifications/DeleteAllNotificationsForUser',
      request,
      metadata || {},
      methodDescriptor_Notifications_DeleteAllNotificationsForUser,
      callback);
};


/**
 * @param {!proto.EmptyRequestArgument} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GenericNotificationResponse>}
 *     Promise that resolves to the response
 */
proto.NotificationsPromiseClient.prototype.deleteAllNotificationsForUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Notifications/DeleteAllNotificationsForUser',
      request,
      metadata || {},
      methodDescriptor_Notifications_DeleteAllNotificationsForUser);
};


module.exports = proto;

