import AttachmentService from "@/services/attachmentService";
import { getField, updateField } from "vuex-map-fields";
import { vm } from "@/main";

export default {
 namespaced: true,
 state: () => ({
  currentProjectAttachments: [],
  currentTaskAttachments: [],
 }),
 mutations: {
  updateField,
  setState(state, payload) {
   state[payload.key] = payload.value;
  },
 },
 actions: {
  async getAttachmentsForProject({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });
    const response = await AttachmentService.getAllProjectAttachments(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("setState", { key: "currentProjectAttachments", value: response.attachmentsList });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async uploadAttachmentsForProject({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });
    const response = await AttachmentService.addAttachmentToProject(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    let updatedAttachments = state.currentProjectAttachments;
    updatedAttachments = [...updatedAttachments, ...response.attachmentsList];

    commit("setState", { key: "currentProjectAttachments", value: [...updatedAttachments] });
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async deleteAttachmentsForProject({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });
    const response = await AttachmentService.deleteProjectAttachment(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    let current = state.currentProjectAttachments;
    current = current.filter((el) => el.entityId !== data.entityId);

    commit("setState", { key: "currentProjectAttachments", value: [...current] });
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async getAttachmentsForTask({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });
    const response = await AttachmentService.getAllTaskAttachments(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    commit("setState", { key: "currentTaskAttachments", value: response.attachmentsList });

    return true;
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async uploadAttachmentsForTask({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });
    const response = await AttachmentService.addAttachmentToTask(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    let updatedAttachments = state.currentTaskAttachments;
    updatedAttachments = [...updatedAttachments, ...response.attachmentsList];

    commit("setState", { key: "currentTaskAttachments", value: [...updatedAttachments] });
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },

  async deleteAttachmentsForTask({ state, commit, dispatch }, data) {
   try {
    commit("startLoading", null, { root: true });
    const response = await AttachmentService.deleteTaskAttachment(data);

    if (!response.success) {
     return dispatch("showMessage", { message: response.message, messageType: "error", messageTitle: vm.$t("errors.error") }, { root: true });
    }

    let current = state.currentTaskAttachments;
    current = current.filter((el) => el.entityId !== data.entityId);

    commit("setState", { key: "currentTaskAttachments", value: [...current] });
   } catch (e) {
    console.log(e);
   } finally {
    commit("stopLoading", null, { root: true });
   }
  },
 },
 getters: {
  getField,
 },
};
